import React from 'react'

import { IndividualIconProps } from '../IconIndividualProps'

const Article = (props: IndividualIconProps): JSX.Element => {
  return (
    <svg viewBox="0 0 25 27" width={props.size} height={props.size}>
      <g fill="none" fillRule="evenodd">
        <g stroke={props.color}>
          <path
            d="M5 .85h18a1.5 1.5 0 011.5 1.5v22a1.5 1.5 0 01-1.5 1.5H3.5V2.35A1.5 1.5 0 015 .85z"
            fillOpacity={props.fillOpacity}
            fill={props.color}
          />
          <path d="M3.5 5.85h-3v18.5a1.5 1.5 0 001.5 1.5h1.5v-20z" strokeLinejoin="round" />
          <path strokeLinecap="round" d="M8.5 5.85h11M8.5 10.85h11M8.5 15.85h11M8.5 20.85h11" />
        </g>
      </g>
    </svg>
  )
}

export default Article
