import { AnyAction, Dispatch, Middleware, MiddlewareAPI } from 'redux'
import axios, { CancelTokenSource } from 'axios'
import { normalize, Schema } from 'normalizr'
import { toast } from 'react-toastify'

import { AppDispatch } from 'shared/redux/types'
import { snakeize, camelize } from 'shared/lib/utils'
import { AnyObject } from 'shared/lib/interfaces'

const API_ACTION = 'API_ACTION'

export type ApiActionInterface = {
  type: string
  url: string
  method?: 'GET' | 'POST' | 'PATCH' | 'PUT' | 'DELETE'
  data?: AnyObject
  /** context is any data that you want passed straight through to the reducer without any transformation */
  context?: AnyObject
  schema?: Schema
  transformCase?: boolean
  cancelToken?: CancelTokenSource
}
type ApiActionReturn = {
  type: string
  payload?: AnyObject
  context?: AnyObject
}

export type ApiAction = (dispatch: AppDispatch) => Promise<ApiActionReturn>

export const apiAction = (action: ApiActionInterface): ApiAction => {
  const actionDefaults = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
    },
    transformCase: true,
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return async (dispatch: AppDispatch): Promise<ApiActionReturn> =>
    dispatch({
      type: API_ACTION,
      [API_ACTION]: { ...actionDefaults, ...action },
    })
}

// This is a small redux middleware that tries to make two things easier:
// 1. Fetching from an API
// 2. Creating triplet request/success/failure actions for loading and error handling
const apiMiddleware: Middleware<Dispatch> =
  ({ dispatch }: MiddlewareAPI) =>
  (next) =>
  async (action: AnyAction): Promise<AnyAction> => {
    // Return early for non API actions
    if (!action[API_ACTION]) return next(action)

    const request = action[API_ACTION]
    const transformRequest = (): AnyObject => {
      if (request.data instanceof FormData) return request.data
      else if (request.transformCase) return snakeize(request.data)
      else return request.data
    }
    const transformResponse = (data): AnyObject => (request.transformCase ? camelize(data) : data)

    dispatch({ type: request.type + '_REQUEST' })

    try {
      const response = await axios({
        url: request.url,
        method: request.method,
        data: transformRequest(),
        headers: {
          Accept: 'application/json',
        },
        cancelToken: request.cancelToken?.token,
      })

      const data = transformResponse(response.data)
      const normalized = request.schema ? normalize(data, request.schema) : null

      return next({
        type: request.type + successType(response.status),
        payload: normalized || data,
        ...(request.context ? { context: request.context } : {}),
      })
    } catch (error) {
      if (axios.isCancel(error)) {
        return next({ type: request.type + '_CANCELED' })
      }

      // Axios doesn't return a status when there was a network error, for instance, if the server is down
      const data = error.response?.status
        ? transformResponse(error.response.data)
        : { errors: { base: 'There was a network error' } }

      // Some application-wide Wunder errors, such as 403s, are returned in the shape { error: '' }
      // Display these as toast notifications
      if (error.response?.data?.error) toast(error.response.data.error)

      return next({
        type: request.type + '_FAILURE',
        payload: data,
        ...(request.context ? { context: request.context } : {}),
      })
    }
  }
const successType = (code: number): string => {
  switch (code) {
    case 202:
      return '_ACCEPTED'
    default:
      return '_SUCCESS'
  }
}

export default apiMiddleware
