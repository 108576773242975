import React from 'react'

import departmentDescriptions from './departmentDescriptions'

import { JobInterface } from 'marketing/redux/careers/jobs/interfaces'
import { DepartmentInterface } from 'marketing/redux/careers/departments/interfaces'
import Container from 'marketing/components/Container/Container'
import TableList from 'marketing/components/TableList/TableList'
import TableListItem from 'marketing/components/TableList/TableListItem'
import { jobPath } from 'marketing/helpers/routes'
import LoadingBlock from 'shared/components/Loading/LoadingBlock'
import Section from 'marketing/components/Section/Section'

interface Props {
  jobs?: { [id: string]: JobInterface }
  departments?: DepartmentInterface[]
  isLoading?: boolean
}

const DepartmentList = (props: Props): JSX.Element => {
  if (!props.departments && props.isLoading) return <LoadingBlock />
  if (!props.departments?.[0] && !props.isLoading)
    return <Section h2="It looks like we had an issue loading our open roles. Please refresh or try again later." />

  return (
    <Container paddingTop paddingBottom>
      {props.departments.map((department) => (
        <TableList
          title={department.name}
          description={departmentDescriptions[department.id]}
          key={department.id}
          data-section={`department-${department.name}`}
        >
          {department.jobs.length === 0 && (
            <p>There are currently no open roles in {department.name}. Please check back later!</p>
          )}
          {department.jobs.map((jobId) => (
            <TableListItem
              to={jobPath(jobId)}
              title={props.jobs[jobId]?.location.name}
              description={props.jobs[jobId]?.title}
              key={jobId}
            />
          ))}
        </TableList>
      ))}
    </Container>
  )
}

export default DepartmentList
