import React from 'react'

import { IndividualIconProps } from '../IconIndividualProps'

const Car = (props: IndividualIconProps): JSX.Element => {
  return (
    <svg viewBox="0 0 30 27" width={props.size} height={props.size}>
      <g transform="translate(-16 -14)" fill="none" fillRule="evenodd">
        <rect strokeOpacity=".1" stroke="#000" x="-.5" y="-.5" width="170" height="55" rx="2" />
        <g transform="translate(17 15)">
          <path
            d="M4 24a1 1 0 01-1 1H1a1 1 0 01-1-1V12a4 4 0 014-4h20a4 4 0 014 4v12a1 1 0 01-1 1h-2a1 1 0 01-1-1v-4H4v4zM6.449 0h15.102a1 1 0 01.903.57L26 8h0H2L5.546.57A1 1 0 016.45 0z"
            stroke={props.color}
            strokeWidth={props.strokeWidth}
          />
          <circle fill={props.color} cx="4.5" cy="12.5" r="1.5" />
          <circle fill={props.color} cx="23.5" cy="12.5" r="1.5" />
        </g>
      </g>
    </svg>
  )
}

export default Car
