import React, { useEffect, useState } from 'react'
import { PopupWidget } from 'react-calendly'
import styled from 'styled-components'

import theme from 'marketing/components/Global/theme'

const CalendlyPopupWidgetStyles = styled.div({
  '.calendly-badge-content': {
    borderRadius: '2px !important',
    fontSize: '1.125rem !important',
    fontWeight: '400 !important',
  },
})

const CalendlyPopupWidget = (): JSX.Element => {
  const [mounted, setMounted] = useState(false)
  useEffect(() => {
    setMounted(true)
  }, [])

  return (
    mounted && (
      <CalendlyPopupWidgetStyles>
        <PopupWidget
          url="https://calendly.com/danewestwick/20min-1"
          rootElement={document.getElementById('calendly-pop-container')}
          text="Schedule a Demo"
          textColor={theme.colors.offWhite}
          color={theme.colors.blue}
        />
      </CalendlyPopupWidgetStyles>
    )
  )
}

export default CalendlyPopupWidget
