// Map department descriptions to department ids from Greenhouse
const departmentDescriptions = {
  // Project Finance
  '4005969002':
    'We are responsible for the risk assessment, risk mitigation, financial modeling, and project structure for renewable energy development projects across the country.',

  // Sales
  '4005970002':
    'We establish long-term relationships with commercial and industrial real estate owners to accelerate the proliferation of renewable energy across the US.',

  // Engineering
  '4005971002':
    'We build software to power the renewable energy company of the future. Our goal is to leverage technology to rapidly grow the renewable industry and fight climate change.',

  // Marketing
  '4005972002': 'We get the word out about Wunder’s growing list of climate-change-fighting capabilities.',

  // Capital Formation
  '4005973002':
    'We forge valuable partnerships with some of the world’s leading financial institutions to bring innovative capital solutions to the commercial solar market.',

  // Operations
  '4005974002':
    'We support Wunder’s growing portfolio of solar assets by mitigating risk, overseeing transactions, coordinating with internal and external stakeholders.',

  // People Operations
  '4038870002':
    'We hire each and every team member into Wunder and we build an inspiring and inclusive employee experience for all. Our goal is to make Wunder the highlight of your career.',

  // Finance
  '4044035002':
    'We serve as the connective tissue between Wunder’s operations, growing asset base, and investment partners. Our goal is to ensure Wunder grows both rapidly and responsibly.',

  // Legal
  '4066360002':
    'We mitigate risk for all things at Wunder, from managing contracts with clients to handling internal legal matters, we are a one-stop team handling all things legal.',

  // Project Development
  '4050887002': 'We manage all of the steps involved in taking solar projects from conception to installation.',

  // Product & Design
  '4050888002':
    'We rethink what it means to be a renewable energy company. Our goal is to identify, prioritize, spec, design, and roll out software to maximize Wunder’s impact on the world.',
}

export default departmentDescriptions
