import React from 'react'

import { IndividualIconProps } from '../IconIndividualProps'

const TwoTriangles = (props: IndividualIconProps): JSX.Element => {
  return (
    <svg viewBox="0 0 62 64" width={props.size} height={props.size}>
      <g id="Company-Pages" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Careers-@2x-Copy-7" transform="translate(-417.000000, -5617.000000)">
          <g id="Group-Copy-7" transform="translate(404.000000, 5607.000000)" stroke={props.color}>
            <path
              d="M43.4602343,11.4285714 C57.3363393,11.4285714 77.7638524,47.2658076 71.2124443,58.3401227 C64.6610362,69.4144377 23.0327212,70.7215833 15.7080243,58.3401227 C8.38332735,45.9586621 29.5841293,11.4285714 43.4602343,11.4285714 Z"
              id="Triangle-Copy-23"
            ></path>
            <path
              d="M40.6566406,12.2916038 C54.3828218,12.3370217 74.7079391,48.1538608 68.2638761,59.1798097 C61.819813,70.2057586 20.645585,71.3736049 13.3591515,58.9983177 C6.072718,46.6230306 26.9304595,12.2462757 40.6566406,12.2916038 Z"
              id="Triangle-Copy-25"
              fillOpacity={props.fillOpacity}
              fill={props.color}
              transform="translate(40.674936, 40.080012) rotate(-40.000000) translate(-40.674936, -40.080012) "
            ></path>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default TwoTriangles
