import { rgba } from 'polished'
import styled from 'styled-components'

import Anchor from 'marketing/components/Anchor/Anchor'
import Icon from 'marketing/components/Icon/Icon'

export const DropdownButtonStyled = styled.div({
  display: 'inline-block',
  position: 'relative',
})

export const IconStyled = styled
  .span({
    margin: '2px 0 0 5px',
  })
  .withComponent(Icon)

export const Popout = styled.div((props) => ({
  backgroundColor: 'white',
  border: `1px solid ${props.theme.colors.middleGray}`,
  borderRadius: props.theme.borderRadius,
  boxShadow: props.theme.boxShadow,
  display: 'block',
  maxHeight: 290,
  minWidth: '100%',
  overflowY: 'scroll',
  position: 'absolute',

  // OSX hides scrollbars, but users might not realize they can scroll this component.
  // Always show the scrollbar so users know they can scroll
  '::-webkit-scrollbar': {
    '-webkit-appearance': 'none',
    width: 7,
  },
  '::-webkit-scrollbar-thumb': {
    borderRadius: 4,
    backgroundColor: 'rgba(0, 0, 0, .5)',
    '-webkit-box-shadow': '0 0 1px rgba(255, 255, 255, .5)',
  },
}))

export const AnchorStyled = styled
  .a((props) => ({
    display: 'block',
    padding: props.theme.spacing.small,

    ':hover': {
      background: rgba(props.theme.currentColor, 0.2),
      color: props.theme.colors.offBlack,
      textDecoration: 'none',
    },
  }))
  .withComponent(Anchor)
