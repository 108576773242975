import theme from './theme'

export default {
  html: {
    fontSize: 14,

    '@media (min-width: 768px)': {
      fontSize: 16,
    },
  },

  body: {
    color: theme.colors.offBlack,
    fontFamily: '"Lato", sans-serif',
    fontWeight: 300,

    '-webkit-font-smoothing': 'antialiased',
    '-moz-osx-font-smoothing': 'grayscale',
  },

  'h1, h2, h3': {
    fontFamily: '"Lora", Serif',
    fontWeight: 300,
    margin: 0,
  },

  'h4, h5': {
    textTransform: 'uppercase',
    fontWeight: 400,
    margin: 0,

    'h3 + &': {
      marginTop: theme.spacing.medium,
    },

    '& + p': {
      marginTop: 0,
    },
  },

  h1: {
    fontSize: '3.125rem',
    lineHeight: '4.375rem',
  },

  h2: {
    fontSize: '2.5rem',
  },

  h3: {
    fontSize: '1.375rem',
    lineHeight: '2rem',
  },

  h4: {
    fontSize: '1rem',
    lineHeight: '1.5rem',
  },

  h5: {
    fontSize: '.875rem',
    lineHeight: '1.5rem',
  },

  p: {
    fontSize: '1.125rem',
    lineHeight: '1.875rem',
  },

  a: {
    color: theme.colors.offBlack,
    textDecoration: 'none',
  },

  'a:hover': {
    textDecoration: 'none',
  },

  // Utilities
  '.underline': {
    textDecoration: 'underline',
  },
}
