/* eslint-disable max-lines */
/**********************************************************************************************************************
 *** API
 *********************************************************************************************************************/

// Payment Schedules
export const apiLoanPaymentSchedulePath = (loanId: string): string => {
  return `/api/borrower/loans/${loanId}/payment_schedule`
}

// Projects
export const apiDocumentPath = (documentId: string): string => {
  return `/api/documents/${documentId}`
}

export const apiEntityDocumentsPath = (entityRef: string): string => {
  return `/api/borrower/entities/${entityRef}/documents`
}

export const apiSignDocumentPath = (id: string, signerRef: string): string => {
  return `/api/documents/${id}/sign/as/${signerRef}`
}

export const apiDealLoansPaymentsPath = (organizationId: number, dealId: number): string => {
  return `/api/borrower/organizations/${organizationId}/deals/${dealId}/payments`
}

export const apiDealLoansStatementsPath = (organizationId: number, dealId: number): string => {
  return `/api/borrower/organizations/${organizationId}/deals/${dealId}/statements`
}

export const apiDealLoansDisbursementsPath = (organizationId: number, dealId: number): string => {
  return `/api/borrower/organizations/${organizationId}/deals/${dealId}/disbursements`
}

export const apiDealLockboxPath = (organizationId: number, dealId: number): string => {
  return `/api/borrower/organizations/${organizationId}/deals/${dealId}/lockbox`
}

export const apiDealLockboxTransactionsPath = (organizationId: number, dealId: number): string => {
  return `/api/borrower/organizations/${organizationId}/deals/${dealId}/lockbox/transactions`
}

export const apiGenerateDealLockboxTransactionsReportPath = (organizationId: number, dealId: number): string => {
  return `/api/borrower/organizations/${organizationId}/deals/${dealId}/lockbox/transactions/generate_report`
}

export const apiDealLockboxAccountInfoPath = (organizationId: number, dealId: number): string => {
  return `/api/borrower/organizations/${organizationId}/deals/${dealId}/lockbox/account_info`
}

// Loans
export const apiDealLoansPath = (organizationId: number, dealId: number): string => {
  return `/api/borrower/organizations/${organizationId}/deals/${dealId}/loans`
}

export const apiLoanPayoffDetailsPath = (loanId: number): string => {
  return `/api/borrower/loans/${loanId}/payoff_details`
}

export const apiLoanPrepaymentPenaltiesPath = (loanId: number): string => {
  return `/api/borrower/loans/${loanId}/prepayment_penalties`
}

export const apiLoanManualCreditPayment = (loanId: number): string => {
  return `/api/borrower/loans/${loanId}/manual_credit_payment`
}

export const apiLoanManualPrincipalPayment = (loanId: number): string => {
  return `/api/borrower/loans/${loanId}/manual_principal_payment`
}

export const apiLoanManualPayoffPayment = (loanId: number): string => {
  return `/api/borrower/loans/${loanId}/manual_payoff_payment`
}

export const apiLoanContractedPrepaymentPayment = (loanId: number, contractedPrepaymentId: number): string => {
  return `/api/borrower/loans/${loanId}/contracted_prepayments/${contractedPrepaymentId}/manual_payments`
}

export const apiLoanPath = (loanId: number): string => {
  return `/api/borrower/loans/${loanId}`
}

// Disbursements
export const apiDisbursementsPath = (disbursementId: number): string => {
  return `/api/borrower/disbursements/${disbursementId}`
}

// Organizations
export const apiOrganizationPath = (organizationId: number): string => {
  return `/api/borrower/organizations/${organizationId}`
}

export const apiOrganizationsPath = (): string => {
  return '/api/borrower/organizations'
}

export const apiAffiliationsPath = (organizationId: number): string => {
  return `/api/borrower/organizations/${organizationId}/relationships`
}

export const apiOrganizationCreatePersonPath = (organizationId: number): string => {
  return `/api/borrower/organizations/${organizationId}/relationships/create_person`
}

export const apiOrganizationCreateOwnersPath = (organizationId: number): string => {
  return `/api/borrower/organizations/${organizationId}/relationships/create_owners`
}

export const apiAddOrganizationMembershipPath = (organizationId: number): string => {
  return `/api/borrower/organizations/${organizationId}/memberships`
}

export const apiOrganizationMembershipPath = (organizationId: number, membershipId: number): string => {
  return `/api/borrower/organizations/${organizationId}/memberships/${membershipId}`
}

// Users
export const apiUserPath = (): string => {
  return `/api/user`
}

export const resendEmailConfirmationPath = (): string => {
  return `/api/user/email_confirmations`
}

export const apiPhoneConfirmationPath = (): string => {
  return `/api/user/phone_confirmations`
}

export const apiDisableMfaPath = (): string => {
  return `/api/user/disable_mfa`
}

export const apiOtpDevicePath = (): string => {
  return `/api/user/otp_device_confirmation`
}

// Waterfalls
export const apiDealWaterfallConfigurationPath = (organizationId: number, dealId: number): string => {
  return `/api/borrower/organizations/${organizationId}/deals/${dealId}/lockbox/configuration`
}

export const apiDealWaterfallRecipientPath = (organizationId: number, dealId: number, recipientId: string): string => {
  return `/api/borrower/organizations/${organizationId}/deals/${dealId}/lockbox/recipients/${recipientId}`
}

// Banking
export const apiVerifyBankAccountPath = (bankAccountId: number): string => {
  return `/api/bank/accounts/${bankAccountId}/verify`
}

export const apiOrganizationBankingPath = (organizationId: number): string => {
  return `/api/borrower/organizations/${organizationId}/bank_accounts`
}

export const apiOrganizationPlaidLinkTokensPath = (organizationId: number): string => {
  return `/api/borrower/organizations/${organizationId}/plaid_link_tokens`
}

export const apiVerifyOrganizationPlaidCredentialsPath = (
  organizationId: number,
  plaidCredentialsId: number,
): string => {
  return `/api/borrower/organizations/${organizationId}/plaid_credentials/${plaidCredentialsId}/verify`
}

export const apiOrganizationPlaidCredentialsPath = (organizationId: number): string => {
  return `/api/borrower/organizations/${organizationId}/plaid_credentials`
}

export const apiOrganizationPlaidCredentialsTokenPath = (
  organizationId: number,
  plaidCredentialsId: number,
): string => {
  return `/api/borrower/organizations/${organizationId}/plaid_credentials/${plaidCredentialsId}/create_link_token`
}

// Deals
export const apiDealsPath = (): string => {
  return `/api/borrower/deals`
}

export const apiDealPath = (organizationId: number, dealId: number): string => {
  return `/api/borrower/organizations/${organizationId}/deals/${dealId}`
}

// Tasks
export const apiGoalsPath = (entityRef: string): string => {
  return `/api/borrower/entities/${entityRef}/goals`
}

export const apiTasksAddDocumentsPath = (taskId: number): string => {
  return `/api/borrower/tasks/${taskId}/add_documents`
}

/**********************************************************************************************************************
 *** App
 *********************************************************************************************************************/
// Root
export const borrowerPath = (): string => {
  return '/borrower'
}

// Shared
export const successPath = (path: string): string => {
  return `${path}/success`
}

export const confirmEmailPath = (): string => {
  return `/email-verification-required`
}

export const settingsPath = (): string => {
  return `/borrower/settings`
}

export const editEmailPath = (): string => {
  return '/borrower/settings/edit-email'
}

export const editPasswordPath = (): string => {
  return '/borrower/settings/edit-password'
}

export const enableMfaPath = (): string => {
  return '/borrower/settings/mfa-enable'
}

export const disableMfaPath = (): string => {
  return '/borrower/settings/mfa-disable'
}

export const verifyMfaPath = (): string => {
  return '/borrower/settings/mfa-verify'
}

export const enableMfaDevicePath = (): string => {
  return '/borrower/settings/mfa-device-enable'
}

export const settingsSuccessPath = (): string => {
  return '/borrower/settings/success'
}

export const settingsEmailVerificationPath = (): string => {
  return '/borrower/settings/email-verification-required'
}

export const parsedPdfPath = (documentId: string): string => {
  return `/pdf/${documentId}`
}

// Deals
export const dealsPath = (): string => {
  return `/borrower/deals`
}

export const dealPath = (organizationId: number, dealId: number): string => {
  return `/borrower/organizations/${organizationId}/deals/${dealId}/overview`
}

export const dealProjectPath = (organizationId: number, dealId: number, projectId: number): string => {
  return `/borrower/organizations/${organizationId}/deals/${dealId}/overview/projects/${projectId}`
}

// Projects
export const projectsPath = (): string => {
  return `/borrower/projects`
}

export const projectAbstractPath = (organizationId: number, projectId: number): string => {
  return `/borrower/organizations/${organizationId}/projects/${projectId}`
}

export const dealFinancingPath = (organizationId: number, dealId: number): string => {
  return `/borrower/organizations/${organizationId}/deals/${dealId}/financing`
}

export const dealDocumentsPath = (organizationId: number, dealId: number): string => {
  return `/borrower/organizations/${organizationId}/deals/${dealId}/documents`
}

export const dealLoansActivityPath = (organizationId: number, dealId: number): string => {
  return `/borrower/organizations/${organizationId}/deals/${dealId}/activity`
}

export const disbursementDenyPath = (loanId: number, disbursementId: number): string => {
  return `loans/${loanId}/disbursements/${disbursementId}/deny`
}

// Documents
export const signDocumentPath = (documentId: string): string => {
  return `sign/${documentId}`
}

// Submissions
export const applyPath = (): string => {
  return `/borrower/apply`
}

// Lockbox
export const dealLockboxPath = (organizationId: number, dealId: number): string => {
  return `/borrower/organizations/${organizationId}/deals/${dealId}/lockbox/overview`
}

export const dealLockboxConfigurationPath = (organizationId: number, dealId: number): string => {
  return `/borrower/organizations/${organizationId}/deals/${dealId}/lockbox/configuration`
}

export const dealLockboxAccountInfoPath = (organizationId: number, dealId: number): string => {
  return `/borrower/organizations/${organizationId}/deals/${dealId}/lockbox/configuration/account-info`
}

export const dealLockboxConfigurationRecipientPath = (
  organizationId: number,
  dealId: number,
  recipientId: string,
): string => {
  return `${dealLockboxConfigurationPath(organizationId, dealId)}/recipients/${recipientId}`
}

// Tasks
export const dealTasksPath = (organizationId: number, dealId: number): string => {
  return `/borrower/organizations/${organizationId}/deals/${dealId}/to-dos`
}

// Loans
export const paymentSchedulePath = (loanId: number): string => {
  return `/borrower/loans/${loanId}/payment-schedule`
}

export const loanAttachBankAccountDealPath = (organizationId: number, dealId: number, loanId: number): string => {
  return `/borrower/organizations/${organizationId}/deals/${dealId}/financing/attach-account/loans/${loanId}`
}

export const loanPaymentNewPath = (loanId: number): string => {
  return `/borrower/loans/${loanId}/payments/new`
}

export const loanPayContractedPrepaymentPath = (loanId: number, contractedPrepaymentId: number): string => {
  return `/borrower/loans/${loanId}/contracted-prepayments/${contractedPrepaymentId}/pay`
}

export const loanPaymentSuccessPath = (loanId: number): string => {
  return `/borrower/loans/${loanId}/payments/success`
}

// Organizations
export const organizationsPath = (): string => {
  return '/borrower/organizations'
}

export const organizationPath = (organizationId: number): string => {
  return `/borrower/organizations/${organizationId}/overview`
}

export const acceptTOSPath = (organizationId: number): string => {
  return `/borrower/organizations/${organizationId}/overview/accept-tos`
}

export const organizationNewBankAccountPath = (organizationId: number): string => {
  return `/borrower/organizations/${organizationId}/overview/new-account`
}

export const organizationSuccessPath = (organizationId: number): string => {
  return `/borrower/organizations/${organizationId}/overview/success`
}

export const organizationProfileSwitchboardPath = (organizationId: number): string => {
  return `/borrower/organizations/${organizationId}/profile/switchboard`
}

export const organizationProfilePath = (organizationId: number, step: 'contact' | 'controller' | 'owners'): string => {
  return `/borrower/organizations/${organizationId}/profile/${step}`
}

export const organizationMembersPath = (organizationId: number): string => {
  return `/borrower/organizations/${organizationId}/members`
}

export const addOrganizationMembershipPath = (organizationId: number): string => {
  return `/borrower/organizations/${organizationId}/members/add`
}

export const editOrganizationMembershipPath = (organizationId: number, membershipId: number): string => {
  return `/borrower/organizations/${organizationId}/members/${membershipId}`
}

export const organizationMembershipSuccessPath = (organizationId: number): string => {
  return `/borrower/organizations/${organizationId}/members/success`
}

export const organizationBankingPath = (organizationId: number): string => {
  return `/borrower/organizations/${organizationId}/banking`
}

export const organizationBankingSuccessPath = (organizationId: number): string => {
  return `${organizationBankingPath(organizationId)}/success`
}

export const bankingAssociatedDealsPath = (organizationId: number, bankAccountId: number): string => {
  return `/borrower/organizations/${organizationId}/banking/${bankAccountId}/associated-deals`
}

// Banking
export const newBankAccountPath = (organizationId: number): string => {
  return `/borrower/organizations/${organizationId}/banking/new-account`
}

export const verifyPlaidCredentialsPath = (organizationId: number, plaidCredentialsId: number): string => {
  return `/borrower/organizations/${organizationId}/banking/plaid-credentials/${plaidCredentialsId}/verify`
}

export const verifyBankAccountPath = (bankAccountId: number, organizationId: number): string => {
  return `/borrower/organizations/${organizationId}/banking/${bankAccountId}/verify`
}

/**********************************************************************************************************************
 *** Other Rails routes
 *********************************************************************************************************************/

// Documents
export const documentPath = (id: string, inline = true): string => {
  if (!id) return null
  return `/documents/${id}${inline ? '?inline=true' : ''}`
}
