import React from 'react'

import { IndividualIconProps } from '../IconIndividualProps'

const Lock = (props: IndividualIconProps): JSX.Element => {
  return (
    <svg viewBox="0 0 25 28" width={props.size} height={props.size}>
      <g fill="none" fillRule="evenodd">
        <g stroke={props.color}>
          <path d="M12.5.85a4 4 0 014 4v4h-8v-4a4 4 0 014-4z" />
          <path
            d="M23 8.85a1.5 1.5 0 011.5 1.5v15a1.5 1.5 0 01-1.5 1.5H2a1.5 1.5 0 01-1.5-1.5v-15A1.5 1.5 0 012 8.85h21zm-10.5 5a3 3 0 100 6 3 3 0 000-6z"
            fillOpacity={props.fillOpacity}
            fill={props.color}
          />
          <path strokeLinecap="round" d="M12.5 19.85v3" />
        </g>
      </g>
    </svg>
  )
}

export default Lock
