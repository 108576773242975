import React from 'react'

import FeaturedPress from './FeaturedPress'

import Swirl from 'marketing/components/Swirl/Swirl'
import Section from 'marketing/components/Section/Section'
import Highlight from 'marketing/components/Highlight/Highlight'
import Anchor from 'marketing/components/Anchor/Anchor'

const Hero = (): JSX.Element => {
  return (
    <Swirl direction="to bottom">
      <Section
        h1="Wunder in the News"
        text={
          <>
            We’re always honored when people write about us. We want to get the word out about renewable energy, what
            we’re building, and how we’re fighting climate change.{' '}
            <Highlight>
              You can contact us at{' '}
              <Anchor href="mailto:press@wunderpower.com" underline>
                <strong>Press@WunderPower.com</strong>
              </Anchor>
              .
            </Highlight>
          </>
        }
        data-section="press-hero"
      >
        <FeaturedPress />
      </Section>
    </Swirl>
  )
}

export default Hero
