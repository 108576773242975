/* eslint-disable max-lines */
import React from 'react'
import { RouteComponentProps } from '@reach/router'
import { ThemeProvider } from 'styled-components'

import {
  dwollaTermsPath,
  dwollaPrivacyPath,
  privacyPath,
  synapseTermsPath,
  synapsePrivacyPath,
  evolvePrivacyPath,
} from '../../helpers/routes'

import Header from 'marketing/components/Header/Header'
import theme from 'marketing/components/Global/theme'
import Footer from 'marketing/components/Footer/Footer'
import Section from 'marketing/components/Section/Section'
import Swirl from 'marketing/components/Swirl/Swirl'
import Container from 'marketing/components/Container/Container'
import PullUp from 'marketing/components/PullUp/PullUp'
import Content from 'marketing/components/Content/Content'
import Anchor from 'marketing/components/Anchor/Anchor'
import useDocTitle from 'shared/lib/useDocTitle'

const Terms: React.FC<RouteComponentProps> = (): JSX.Element => {
  useDocTitle('Terms - Wunder')

  return (
    <ThemeProvider theme={{ ...theme, currentColor: theme.colors.blue }}>
      <Header />
      <Section
        h1="Terms of Service"
        text="The following document outlines the terms of use of the Wunder website. Before using any of the Wunder services, you are required to read, understand, and agree to these terms."
      />
      <Swirl>
        <PullUp>
          <Container>
            <Content data-section="terms-content">
              <p>
                Notice: By using this Website, you agree to be bound by these Terms of Service, Wunder Capital&#39;s{' '}
                <Anchor to={privacyPath()} isAlwaysColored underline>
                  Privacy Policy
                </Anchor>{' '}
                (the &quot;Privacy Policy&quot;) and all disclaimers and terms and conditions that appear elsewhere on
                this Website or these Terms of Service.
              </p>
              <p>
                IF YOU DO NOT AGREE TO THESE TERMS OF SERVICE, YOU AGREE THAT YOUR SOLE AND EXCLUSIVE REMEDY IS TO
                DISCONTINUE USING THIS WEBSITE.
              </p>
              <p>
                <strong>The Terms of Service were last modified June 11, 2018.</strong>
              </p>
              <hr />
              <h3>About the Terms of Service</h3>
              <p>
                The following Terms of Service (the &quot;ToS&quot; or &quot;Terms of Service&quot;) govern your access
                to and use of this website, www.wunderpower.com (together with any user, investor, or borrower accounts
                on the website, the &quot;Website&quot;). All use of the Website and the Content, including your account
                on the Website (your &quot;Wunder Account&quot;), is subject to the ToS.
              </p>
              <hr />
              <h3>In the Terms of Service:</h3>
              <ul>
                <li>
                  <p>
                    Users of this Website, including owners, employees, agents and affiliates of any business,
                    corporation, partnership or other entity that is, or is considering becoming, a user, borrower, or
                    investor, are referred to as &quot;you&quot; or &quot;your.&quot;
                  </p>
                </li>
                <li>
                  <p>
                    The Wunder Company, a Delaware corporation, together with all applicable subsidiaries, affiliates,
                    assignees, successors and its and their respective officers, directors, employees and agents are
                    referred to as &quot;Wunder Capital,&quot; &quot;Wunder,&quot; &quot;we,&quot; &quot;us&quot; or
                    &quot;our.&quot; Together, you and we may be collectively referred to as &quot;Parties.&quot;
                  </p>
                </li>
              </ul>
              <p>
                The ToS constitute a binding legal contract between you and Wunder, and are in addition to any other
                agreements between you and Wunder, including any agreements that govern your use of products, services,
                content, tools and information available on the Website, including the investment services provided on
                the Website. Your continued use of the Website serves as your agreement to be bound by the ToS in full.
              </p>
              <hr />
              <h3>Modifications to the Terms of Service and the Website</h3>
              <p>
                By using or accessing the Website, you agree that we may modify the Terms of Service or the Website at
                any time without prior notice. We will have no further obligation to notify you of any modifications.
                Wunder reserves the right to make changes to the Terms of Service at any time and from time to time.
                Your continued use of the Website after any modification of the Terms of Service will conclusively
                indicate, and be deemed to indicate, your acceptance of the relevant modification and the Terms of
                Service as so modified.
              </p>
              <p>
                It is your responsibility to review the ToS from time to time to see if modifications have been made.
                Any modification is effective immediately from and after Wunder&#39;s posting of such modification on
                the Website, or other update of the Website or the ToS. You will know if these Terms of Service have
                been modified since your last visit to the Website by referring to the &quot;Last Modified&quot; date at
                the end of this page. Wunder reserves the right, at any time or from time to time, to modify, add to,
                delete or discontinue, temporarily or permanently, the Website (or any part thereof) without notice to
                you. Notwithstanding the above, we will seek your consent to future modifications to the extent we are
                required to do so by applicable law.
              </p>
              <hr />
              <h3>Privacy Policy</h3>
              <p>
                Your privacy is very important to us. We encourage you to read Wunder&#39;s{' '}
                <Anchor to={privacyPath()} underline>
                  Privacy Policy
                </Anchor>
                , which explains how we treat your personal information and protect your privacy when you are using the
                Website. By using the Website, you agree that Wunder may use your information as set forth in the
                Privacy Policy. Wunder&#39;s Privacy Policy is hereby incorporated in the ToS by reference in its
                entirety.
              </p>
              <p>
                Although we strive to maintain adequate safeguards designed to protect personal information, please be
                aware that no security measures are completely secure or impenetrable. We cannot guarantee that only
                authorized persons will view your information or that information you share on the Website or via e-mail
                or other electronic communication with Wunder will not become publicly available or intercepted by third
                parties. By your continued use of the Website, you acknowledge and agree that Wunder and its service
                providers will not be liable for any harm or damage you may experience by transmitting or submitting
                personal or confidential information to Wunder by e-mail or other electronic communications or via the
                Website.
              </p>
              <p>
                The Website uses cookies and web beacons. By using the Website and agreeing to the ToS, you consent to
                our use of cookies and web beacons in accordance with the terms of the Privacy Policy.
              </p>
              <hr />
              <h3>Acceptable Use of the Website; Conditions of Your Use</h3>
              <p>
                The Website is for your own personal use only. The Website is designed for individuals and entities
                contemplating investment in solar energy systems and any person interested in learning more about
                Wunder, its products and services. You may only use the Website for one of these purposes.
              </p>
              <p>
                Without limiting the generality of the preceding paragraph, you are expressly prohibited from: (a) any
                resale or commercial use of the Website; (b) any collection and use of any listings, descriptions,
                prices or any other information posted on the Website for any purpose other than your own personal use
                or as otherwise permitted in an agreement between Wunder and yourself (&quot;Permitted Use&quot;),
                including any purpose competitive to Wunder or any commercial purpose, including marketing; (c) any
                downloading or copying of any materials contained in the Website for any reason other than for a
                Permitted Use, or any use of data mining, spiders, or other tools or software or other automated or
                systematic data gathering and extraction tools or methods; (d) using the Website to access or collect
                any personally identifiable information, including account information, user names, passwords, e-mail
                addresses or other personal or contact information with respect to other Wunder users for any purpose,
                including a commercial purpose; or (e) otherwise interfering with the ordinary operation or mission of
                the Website or Wunder&#39;s products or services.
              </p>
              <p>Your use of the Website is conditioned upon the following statements being true:</p>
              <ul>
                <li>
                  <p>You are 18 years of age or older;</p>
                </li>
                <li>
                  <p>You are able to form a binding contract with us;</p>
                </li>
                <li>
                  <p>
                    You are not prohibited by law from accessing the Website or have not previously been banned,
                    terminated or otherwise denied access to the Website; and
                  </p>
                </li>
                <li>
                  <p>
                    You are not acting on behalf of a person whose access to the Website has been previously terminated
                    or otherwise denied by us.
                  </p>
                </li>
              </ul>
              <p>
                Except as explicitly permitted by the ToS, and without limiting anything else in the ToS, you agree not
                to sell, copy, publish, store, distribute, redistribute, disseminate, transmit, transfer, modify,
                display, reproduce, repackage and/or create any derivative works from, the Website and the Content (as
                defined below), in whole or in part, for any purpose, in any form or manner or by any means whatsoever.
                You also may not redeliver any of the pages, text, images or other Content or the Website using
                &quot;framing&quot; or similar technology or for any purpose, including any attempt to impersonate
                Wunder or any employee, representative or agent of Wunder or otherwise create the appearance or
                suggestion that you have a relationship to Wunder or that we have endorsed you for any purpose. Any
                unauthorized use of the Website or the Content or any other intellectual property contained therein is
                strictly prohibited.
              </p>
              <p>
                As conditions of your use of the Website, you agree to comply with the ToS and all applicable laws and
                regulations in connection with your use of the Website. You will not violate, attempt to violate or
                knowingly facilitate the violation of the security (including access control or authentication systems)
                or integrity of the Website. Without limiting the above or anything else in the ToS, you agree not to
              </p>
              <ul>
                <li>
                  <p>
                    Attempt to or enable others to attempt to gain unauthorized access to any other accounts, computer
                    systems or networks connected to any Wunder server or data contained therein (the
                    &quot;Materials&quot;), including through password theft or any other means;
                  </p>
                </li>
                <li>
                  <p>
                    Impersonate or spoof the identity of another user of the Website, any employee, agent or
                    representative of Wunder or any other person, otherwise mislead Wunder as to your identity or
                    financial status or condition, or attempt to do the same;
                  </p>
                </li>
                <li>
                  <p>
                    Attempt or enable others to attempt to obtain any Materials or Content through any means that Wunder
                    has not intentionally made available on the Website including using any automatic or manual process,
                    including data mining, robots or similar data gathering and extraction tools, to search or harvest
                    information from the Website or attempt to access or search the Website, Materials or Content with
                    any engine, software, tool, agent, device, or mechanism other than the software or search agents
                    provided by Wunder or other generally available third party web browsers;
                  </p>
                </li>
                <li>
                  <p>
                    Use the Website or the Materials in any manner (or any other conduct) that could damage, disable
                    overburden or impair the Website or any Wunder server or the network(s) connected to any Wunder
                    server or the availability or accessibility thereof, or that might interfere with any other
                    person&#39;s access to or use or enjoyment of any Materials;
                  </p>
                </li>
                <li>
                  <p>
                    Use any Materials, data or Content or distribute any Content in competition with Wunder or to its
                    detriment in any way;
                  </p>
                </li>
                <li>
                  <p>
                    Access, tamper with, or use non-public areas of the Website, Wunder&#39;s servers, computer systems,
                    or the technical delivery systems of Wunder&#39;s service or hosting providers or lessors;
                  </p>
                </li>
                <li>
                  <p>
                    Attempt to decipher, decompile, disassemble, or reverse engineer, reverse compile or reverse
                    assemble any of the software used to provide the Website or Content;
                  </p>
                </li>
                <li>
                  <p>
                    Use, save, embed, transmit or send (whether on, to or through the Website), directly or indirectly,
                    any material or file containing, or linked to, any virus, worm or any other computer, software or
                    technological component, code, routine, script, malware, spyware, files, keystroke logger, Trojan
                    horse, rootkit, application or program designed to intercept, interrupt, destroy, or limit the
                    functionality of any computer software, network or hardware or telecommunications equipment; or
                  </p>
                </li>
                <li>
                  <p>
                    Interfere with, disrupt access or adversely affect the Website or the Content in any way, or attempt
                    to do the same, including by overloading, flooding, spamming or mail-bombing the Website or engaging
                    in a denial of service attack or any conduct or technique similar to the above or participating in
                    any such behavior.
                  </p>
                </li>
              </ul>
              <p>
                Wunder reserves the right to edit, restrict or remove any content you provide for any reason at any
                time. The information and materials made available through the Website may contain typographical errors
                or inaccuracies. In addition, Wunder does not control the information provided by other users that is
                made available through the Website. Wunder reserves the right to refuse service, terminate
                relationships, and/or cancel orders or transactions in its discretion. You agree that you will have no
                claim against Wunder or any Released Party (as defined below), for any actual or alleged infringement of
                any proprietary rights, rights of privacy or publicity, moral rights or rights of attribution in
                connection with our use of any content you provide. Notwithstanding Wunder&#39;s rights under the ToS,
                Wunder does not undertake, and shall not be obligated, to monitor the submission of any content to, or
                the publication of any content on, the Website at any time.
              </p>
              <p>
                As a condition of your use of the Website, you are authorized to access the Website, your Wunder Account
                and all Wunder computer, network, software and cloud systems and applications, or other electronic
                transmissions, only by your Wunder Account through the human-readable functionality available to users
                of the Website generally. Access to the underlying systems is not authorized. You may only access the
                portions of the Website related to your Wunder Account, as available to you through your dedicated user
                name and password. Access to portions of the Website related to another user (including such user&#39;s
                account or accounts) with which you are not affiliated under any circumstances is strictly prohibited.
                You will at all times comply with all reasonable procedures and requirements established by Wunder for
                verifying the authenticity of users, restricting each user&#39;s access and use to their permitted
                account and resources, protecting the Website from infection or attack by virus, worm, malware, spyware,
                Trojan horse or other malicious code, script or routine, or by robots or unauthorized users, and
                otherwise maintaining the security and stability of the Website.
              </p>
              <hr />
              <h3>Wunder&#39;s Intellectual Property, Copyrights and Identifying Marks</h3>
              <p>
                All rights, title, and interest in and to the Website and the Content (as defined below) and any all
                intellectual property contained therein or relating thereto, including any copyright, patent or
                trademark, are and will remain the exclusive property of Wunder or its licensors, as applicable (except
                where another person or entity is expressly credited as the provider of such content or data). Such
                intellectual property is protected by federal and state law and international treaties. You acknowledge
                and agree that no proprietary rights are being transferred to you in such materials or information.
              </p>
              <p>
                You shall use the Website solely for your own use and shall not allow others to use the Website under or
                through your account, except as otherwise set forth herein. Subject to the terms and conditions of the
                ToS, we grant you a limited, non-transferable, non-sublicenseable, non-exclusive, revocable license to
                use the Website and the Content for personal use and Permitted Uses only, until such time as the ToS
                terminate or expire or your right to use or access the Website is terminated in accordance with the
                Terms. Without Wunder&#39;s prior written consent you may not sell, resell, rent, copy, reproduce (in
                any form or by any means, including electronic, mechanical, photocopying, recording or otherwise),
                duplicate, edit, revise, update, enhance, modify, fix, correct, adapt, rearrange, imitate, mirror,
                distribute, host, license, sublicense, create derivative works from, transfer, transmit, publish,
                republish, display, perform or otherwise exploit for a commercial purpose the Content or software on the
                Website, or of any products or services sold or offered by Wunder. You agree that you will not and will
                not allow others to decompile, disassemble, reverse engineer, reverse compile, reverse assemble, merge,
                disclose, convert or otherwise extract the underlying script or code of Wunder&#39;s technology,
                including the Content and the Website, or delete, remove, obscure or alter authorship attribution or
                copyright notices or information. Any commercial use or public dissemination of any information and data
                gathered from Wunder is strictly prohibited, including the Content and the Website, unless specifically
                authorized in writing. You may copy information from the Website or Content only as may be strictly
                necessary for your own use to view, save, print, or transmit it. Where content is specifically made
                available for redistribution by the express agreement of Wunder, it may only be redistributed internally
                within your organization. You acknowledge and agree that any violation of the above may subject you to
                compensatory and punitive damages, and shall specifically also entitle Wunder to equitable relief
                (including an injunction), in addition to (and not in substitution or replacement for) any other
                available remedies at law or in equity, without the need for the posting of a bond or any other
                requirement.
              </p>
              <p>
                Nothing in the Website or elsewhere shall be construed as granting any license or right to use, implied
                or otherwise, any logo, trademark, service mark, trade dress or other identifying mark owned or licensed
                by Wunder or displayed on the Website without the written permission of Wunder (or the third party owner
                of the mark, if applicable). You agree that you may not use such identifying marks in any way that would
                disparage or damage the reputation of Wunder or any of its affiliates, directors, officers or employees.
              </p>
              <p>
                For purposes of the ToS, the &quot;Content&quot; shall include all contents of the Website, including
                any logos, identifying marks, images, illustrations, designs, icons, photographs, videos, text and other
                written and multimedia materials, all of Wunder&#39;s investment information (including rate
                information) and requirements, products, services, advertising materials or collateral, log-in or
                registration criteria and instructions, help guidelines, user documentation and customer and technical
                support documents, and the Website&#39;s likeness, look and feel, format, layout, software, code,
                scripts, platforms and applications, as well as any data, files, archives, folders or downloads
                available on the Website.
              </p>
              <hr />
              <h3>Information and Feedback You Provide to Wunder</h3>
              <p>
                You may from time to time provide suggestions, comments for enhancements or functionality or other
                feedback (&quot;Feedback&quot;) to us with respect to the Content or the Website. You and we acknowledge
                and agree that any such Feedback is given voluntarily. We will endeavor to consider, and may discuss
                with or respond to you regarding Feedback you provide, but we shall have full discretion to determine
                whether or not to proceed with the development of the suggested or requested enhancements, new features
                or functionality. Should we determine that we wish to proceed with such enhancements, features or
                functionality, we may elect, in our discretion, to integrate the new enhancement, feature and/or
                functionality into the Website, the Content or Wunder&#39;s investments, products and services. You
                hereby grant us a royalty-free, fully paid up, worldwide, transferable, sublicenseable, irrevocable,
                perpetual license to (a) copy, distribute, transmit, display, perform, and create derivative works of
                Feedback, and (b) use Feedback and/or any subject matter thereof, including the right to develop,
                manufacture, have manufactured, market, promote, sell, have sold, offer for sale, have offered for sale,
                import, have imported, rent, provide and/or lease products or services which practice or embody, or are
                configured for use in practicing, the Feedback and/or any subject matter of the Feedback.
              </p>
              <hr />
              <h3>DISCLAIMER OF WARRANTIES; LIMITATION OF LIABILITY</h3>
              <p>
                The Website is provided to you on an &quot;as is&quot; and &quot;as available&quot; basis. Your use of
                the Website and any material you may access, download or otherwise obtain from or through the Website is
                at your own discretion and risk, and you will be solely responsible for any damage to your computer
                system or loss of data that results therefrom.
              </p>
              <p>
                WUNDER DOES NOT WARRANT AS TO, OR GUARANTEE, THE ADEQUACY, SUFFICIENCY, ACCURACY, COMPLETENESS OR
                AVAILABILITY OF THE CONTENT OR THE WEBSITE AND WUNDER SHALL HAVE NO LIABILITY FOR ANY ERRORS, OMISSIONS,
                MISLEADING STATEMENTS OR INTERRUPTIONS THEREIN.
              </p>
              <p>
                WUNDER MAKES NO EXPRESS OR IMPLIED REPRESENTATIONS OR WARRANTIES AND EXPRESSLY DISCLAIMS ALL EXPRESS,
                IMPLIED AND STATUTORY WARRANTIES TO YOU OR ANY THIRD PARTY, AND ANY WARRANTIES OR CONDITIONS ARISING
                THROUGH COURSE OF DEALING, AS TO MERCHANTABILITY, SUITABILITY, INVESTABILITY, FITNESS FOR A PARTICULAR
                PURPOSE OR USE, NON-INFRINGEMENT OF THIRD PARTY RIGHTS, FREEDOM FROM COMPUTER VIRUS OR THE ACCURACY,
                ADEQUACY, TIMELINESS, COMPLETENESS OR SUFFICIENCY OF INFORMATION WITH RESPECT TO THE WEBSITE OR THE
                CONTENT. WUNDER AND ITS VENDORS, CONTENT PROVIDERS OR SERVICE PROVIDERS ASSUME NO RESPONSIBILITY FOR THE
                CONSEQUENCES OF ANY ERRORS OR OMISSIONS IN OR DELAY OR INABILITY TO ACCESS, THE WEBSITE OR CONTENT. NO
                ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED FROM WUNDER OR THROUGH THE WEBSITE OR THE
                CONTENT WILL CREATE ANY WARRANTY NOT EXPRESSLY MADE IN THESE TERMS OF SERVICE.
              </p>
              <p>
                IN NO EVENT SHALL WUNDER HAVE ANY LIABILITY FOR ANY DAMAGES, INCLUDING ANY SPECIAL, INCIDENTAL,
                PUNITIVE, DIRECT, INDIRECT OR CONSEQUENTIAL DAMAGES (INCLUDING LOSS OF PROFITS, REVENUE, EARNINGS,
                CONTRACTS OR BUSINESS OPPORTUNITIES OR RELATIONSHIPS, WORK STOPPAGE OR BUSINESS INTERRUPTION, COMPUTER
                INTERRUPTION OR MALFUNCTION, LOSS OF ANTICIPATED SAVINGS, LOSS OF REPUTATION OR GOODWILL, OR LOSS OR
                CORRUPTION OF INFORMATION OR DATA OR OTHER INTANGIBLE LOSS OR DAMAGE), LOSSES OR EXPENSES RESULTING FROM
                OR ARISING IN CONNECTION WITH THE USE OF THE WEBSITE OR THE CONTENT, OR THE USE THEREOF OR INABILITY TO
                USE THE FOREGOING BY ANY PARTY, OR IN CONNECTION WITH ANY FAILURE, PERFORMANCE, ERROR, OMISSION,
                INTERRUPTION, DEFECT, DELAY IN OPERATION OR TRANSMISSION, COMPUTER VIRUS OR MALICIOUS CODE OR ANY LINE,
                CONNECTION OR SYSTEM FAILURE (INCLUDING INTERNET CONNECTION OR SERVICE), EVEN IF WUNDER (OR ITS
                REPRESENTATIVES) HAS BEEN NOTIFIED OF THE POSSIBILITY OF SUCH DAMAGES.
              </p>
              <p>
                YOU HEREBY AGREE THAT WUNDER&#39;S TOTAL LIABILITY TO YOU, AND YOUR SOLE REMEDY AGAINST WUNDER, ARISING
                OUT OF OR RELATING TO YOUR USE OF THE WEBSITE SHALL NOT EXCEED THE TOTAL AMOUNT YOU PAID TO WUNDER FOR
                THE USE OF THE WEBSITE DURING THE TERM OF THIS AGREEMENT.
              </p>
              <p>
                You acknowledge that Internet software, technology, servers, hardware or transmission or network
                performance, defects or problems may produce inaccurate or incomplete copies of information and
                materials that may be downloaded and displayed on your computer or electronic device from the Website or
                Wunder or any other source. Without limiting the generality of the preceding paragraphs, Wunder does not
                warrant that the Website, any website or resource to which the Website may be linked, and the Content or
                other information, files or material downloaded from or available on the Website, or any linked sites,
                will be uninterrupted, error-free, or free of viruses, worms, malware, spyware, Trojan horses, defects
                or other harmful components, scripts, or software. Wunder is not liable for any damages, changes or
                omissions that occur during the transmission of the Content, data or other information, files and
                materials.
              </p>
              <p>
                You acknowledge that Wunder has an interest in limiting the personal liability of its officers,
                directors, employees, agents, representatives and independent contractors. You agree that you will not
                bring any claim personally against any affiliate or subsidiary of Wunder or any of their respective
                successors, assigns, shareholders, partners, members, officers, directors, managers, licensors,
                independent contractors, sub-contractors, consultants, employees (temporary or full-time), agents,
                representatives or advisors in their individual and representative capacities (collectively, the
                &quot;Released Parties&quot;) in respect of any losses, expenses or damage you suffer in connection with
                the Website. Without prejudice to the above, you agree that the limitations of warranties and liability
                in the ToS will protect the Released Parties as well as Wunder.
              </p>
              <p>
                Nothing in the ToS shall exclude or limit any warranty implied or imposed by law that would be unlawful
                to exclude or limit, including, to the extent applicable, liability for death or personal injury or
                fraud. By using the Website, you agree that the exclusions and limitations of liability set out in the
                Website disclaimer are reasonable. If you do not think they are reasonable, you agree not to use the
                Website.
              </p>
              <hr />
              <h3>No Solicitation or Offering; No Advice</h3>
              <p>
                Except as otherwise expressly noted, the Content and the Website do not constitute an offer to buy or
                sell or a solicitation of an offer to buy or sell investments, loans, securities, partnership interests,
                commodities or any other financial instruments; the Content and the Website also do not constitute, and
                may not be used for or in connection with, an offer or solicitation by anyone in any state or
                jurisdiction in which such an offer or solicitation is not authorized or permitted, or to any person to
                whom it is unlawful to make such offer or solicitation.
              </p>
              <p>
                Wunder makes no representation or warranty, express or implied, regarding the advisability of investing
                in any fund offered through the Website. The past performance of any investment is not a guide to future
                performance.
              </p>
              <p>
                WITHOUT LIMITING ANYTHING IN THE TERMS OF SERVICE, WUNDER MAKES NO WARRANTIES AND BEARS NO LIABILITY
                WITH RESPECT TO ANY FUND, ANY INVESTMENTS, SECURITIES, PARTNERSHIP INTERESTS, LOANS OR THE PERFORMANCE
                THEREOF.
              </p>
              <p>
                The Content and the views expressed in the Content do not necessarily reflect the views of Wunder as a
                whole, its directors, officers, employees, shareholders or any part or member thereof or of any third
                party. No Content or information on the Website constitutes, or should be construed as, investment, tax,
                legal, financial or any other advice.
              </p>
              <hr />
              <h3>Forward-Looking Statements</h3>
              <p>
                Certain statements in the Website and the Content may constitute &quot;forward-looking&quot; statements
                that involve known and unknown risks, uncertainties and other factors that may cause actual returns of
                investment to be materially different from any future returns or values expressed or implied by such
                forward-looking statements. Forward-looking statements typically include words such as &quot;may,&quot;
                &quot;will,&quot; &quot;expect,&quot; &quot;believe,&quot; &quot;plan,&quot; &quot;expect,&quot;
                &quot;anticipate,&quot; &quot;intend&quot; and other similar terminology. These statements reflect
                current expectations regarding future events and speak only as of the date of being posted to the
                Website. Forward-looking statements involve significant risks and uncertainties, should not be read as
                guarantees of future performance or returns, and will not necessarily be accurate indications of whether
                or not such returns will be achieved. Given these uncertainties and risks, users of the Website,
                including any person who may or has invested in any offering made by or on behalf of Wunder or its
                subsidiaries or affiliates, are cautioned not to place undue reliance on such forward-looking
                statements.
              </p>
              <p>
                A variety of factors could cause the actual results and developments of any investment to differ
                significantly from the results and developments forecasted and implied. Although forward-looking
                statements contained in the Website, if any, are based upon what Wunder and its advisors believe are
                reasonable assumptions, Wunder cannot assure you that actual results, returns or events will be
                consistent with these forward-looking statements. Forward-looking statements are made as of the date of
                being posted to the Website, and Wunder and its subsidiaries and affiliates assume no obligation, and
                expressly disclaim any obligation, to update or revise forward-looking statements contained in or
                incorporated by reference into the Website or the Content or any information supplemental thereto to
                reflect new information, future events or circumstances or otherwise.
              </p>
              <hr />
              <h3>Registration; Security and Password</h3>
              <p>
                To use the Website, you must complete the registration process. You agree that the registration
                information you provide is accurate, complete and current. You further agree to promptly update that
                information to keep it accurate, complete and current. If you register on behalf of a business entity or
                other organization, you represent and warrant that you have the authority to provide the information
                required and to bind the organization to the ToS. You acknowledge and agree that Wunder, in its sole
                discretion, may suspend or discontinue your, and refuse any and all current and future, access to or use
                any portion of this Website at any time without notice to you.
              </p>
              <p>
                Once you have registered, you will receive or will be able to create or input a user ID and password.
                You are responsible for maintaining the confidentiality of your user ID and password. You are solely
                responsible for all actions or activities that occur on the Website or any Wunder server, computer,
                system, hardware, network, cloud or software connected to or through the Website performed on or through
                your Wunder Account during any time period in which your user ID is logged in to the Website, whether or
                not expressly authorized by you. You agree not to disclose your password to any third party. You and we
                acknowledge and agree that Wunder shall have no liability to you or any other person for acts or
                omissions made or committed by any agent, representative or third-party service provider while such
                person is using your account.
              </p>
              <p>
                Wunder disclaims all liability, and you acknowledge and agree that we will not be liable, for or in
                connection with any harm or damages to you or to any party resulting from the theft or unauthorized use
                of your user ID or password. You agree to notify us immediately of any such unauthorized use or any
                other actual or suspected breach of security. Access to and use of the Website is not authorized by any
                other person or entity using your user ID and you are responsible for preventing such unauthorized use.
                Individuals and entities whose privilege to use the Website has previously been terminated by Wunder may
                not register for the Website.
              </p>
              <p>
                If you become aware of any unauthorized use of, loss or theft of your username, password, PIN, or other
                account information or credentials, you should immediately inform us at support@wunderpower.com.
              </p>
              <p>
                Data transmitted via the Website as well as data on some pages of the Website may be encrypted by Secure
                Sockets Layer (SSL) protocol. To the extent such technology is utilized on a given page, you may be
                unable to use certain account or customization features of the Website unless your web browser software
                supports such encryption. Please note that no technology can be considered completely secure or
                impenetrable and Internet protocols and other public and/or proprietary technology used or accessed by
                the Website may be vulnerable to exploitation or compromise by persons engaged in hacking or criminal
                conduct. We and you each acknowledge and agree that we shall have no liability to you for any such
                exploitation or criminal conduct by third parties.
              </p>
              <hr />
              <h3>Electronic Communication</h3>
              <p>
                You acknowledge and agree that the communications between you and Wunder are electronic. You consent to
                receive communications from Wunder in an electronic form, including e-mail. You agree that all terms and
                conditions, agreements, notices, disclosures and other communications that we provide to you
                electronically satisfy any legal requirement that such communications would satisfy if they were in
                writing. Wunder will use reasonable efforts to honor any request you may have to opt out from receiving
                e-mails. You hereby confirm and agree that your sole remedy in connection with any e-mail sent by Wunder
                to you shall be to stop your use of the Website.
              </p>
              <hr />
              <h3>Improper Use of E-mail</h3>
              <p>
                You may not send unsolicited e-mails or e-mail that includes false or misleading information in the
                return address or in the subject line to anyone whose e-mail address includes the domain name
                &quot;wunderpower.com&quot;. You may not use &quot;wunderpower.com&quot; or any other domain name or
                e-mail address owned, leased (as lessee) or licensed (as licensee) by Wunder as a pseudonymous return
                e-mail address for any communications that you transmit from another location or through another
                service. You may not pretend to be someone else -- or spoof their identity -- when using the Website.
              </p>
              <hr />
              <h3>Payment Functionality</h3>
              <p>
                In order to use the payment functionality of the Website, you may be required to open a &quot;White
                Label&quot; account provided by either Dwolla, Inc. (&quot;Dwolla&quot;), or Synapse Financial
                Technologies, Inc. (&quot;Synapse&quot;). You must accept the ​Dwolla{' '}
                <Anchor href={dwollaTermsPath()} targetBlank isAlwaysColored underline>
                  Terms of Service
                </Anchor>{' '}
                ​ and{' '}
                <Anchor href={dwollaPrivacyPath()} targetBlank isAlwaysColored underline>
                  Privacy Policy
                </Anchor>{' '}
                as well as the Synapse{' '}
                <Anchor href={synapseTermsPath()} targetBlank isAlwaysColored underline>
                  Terms of Service
                </Anchor>{' '}
                ​,{' '}
                <Anchor href={synapsePrivacyPath()} targetBlank isAlwaysColored underline>
                  Privacy Policy
                </Anchor>
                ​, and{' '}
                <Anchor href={evolvePrivacyPath()} targetBlank isAlwaysColored underline>
                  Financial Institution Partner Privacy Policy
                </Anchor>
                . Any funds held in Dwolla accounts are held by Dwolla&#39;s financial institution partners as set out
                in the ​Dwolla Terms of Service​. Any funds held in Synapse accounts are held by Synapse&#39;s financial
                institution partners as set out in the ​Synapse Terms of Service​. You authorize Wunder to share your
                identity and account data with Dwolla and Synapse for the purposes of opening and supporting your
                &quot;White Label&quot; accounts, and you are responsible for the accuracy and completeness of that
                data. You understand that you will access and manage any Dwolla or Synapse accounts through the Website,
                and Dwolla and Synapse account notifications will be sent by Wunder, not Dwolla or Synapse. Wunder will
                provide customer support for your &quot;White Label&quot; account activity, and can be reached at
                support@wunderpower.com.
              </p>
              <hr />
              <h3>Third-Party Websites and Content</h3>
              <p>
                The Website may contain links to third party websites. Any such link is provided only as a convenience.
                Wunder has no control over any such other websites, the contents thereof or the products and/or services
                offered. The existence of any link does not imply an affiliation, sponsorship, endorsement, approval,
                investigation, representation, warranty, verification or monitoring by Wunder of any third party
                website, any content or information contained therein or the products and/or services offered. You
                should also be aware that the terms and conditions of such website and the website&#39;s privacy policy
                may be different from those applicable to your use of the Wunder web-based Website. You should read such
                terms and conditions and privacy policies carefully before using any such third party website. Your
                access to and use of such linked websites is governed by the Terms of Service and privacy policies of
                those sites, and shall be at your own risk.
              </p>
              <p>
                Wunder disclaims all responsibility for the privacy policies and customer information practices of
                third-party Internet websites hyperlinked from the Website. You acknowledge and agree that Wunder is not
                responsible or liable for the quality, availability, continuity or non-interruption, accuracy,
                completeness, truth, sufficiency, nature, reliability or content of such websites or resources, any
                third party content or information nor the accuracy, timeliness or suitability of the information that
                may be linked to the Website or to a site that is linked to the Website, whether the link is provided by
                Wunder or by a third party, or your use of or inability to use such website. YOU HEREBY IRREVOCABLY
                WAIVE ALL RIGHTS RELATED TO, AND RELEASE THE RELEASED PARTIES FROM AND AGAINST, ANY LIABILITIES ARISING
                FROM OR RELATED TO THE CONTENTS OF ANY THIRD PARTY WEBSITE.
              </p>
              <p>
                Certain portions of the Website, including information on businesses, companies or entities, may contain
                unedited or third party content. All postings, messages, text, images, links to third-party websites or
                other materials published or otherwise made available through this section are the sole responsibility
                of the person(s) who originated such Third-Party Content and Wunder does not control such content, does
                not investigate or validate such Third-Party Content and is under no obligation to monitor such
                Third-Party Content; provided that Wunder reserves the right at all times (but will not have an
                obligation) to remove any Third-Party Content in its sole discretion. By using this Third-Party Content,
                you understand that you may be exposed to content that is inaccurate, inappropriate, misleading,
                unlawful, offensive or otherwise objectionable, and that Wunder makes no representations or warranties
                regarding the Third-Party Content and is not responsible or liable in any manner for the Third-Party
                Content or the conduct, whether online or offline, of any user. The Third-Party Content does not
                constitute legal or financial advice and must not be used in the place of legal counsel or financial due
                diligence. You should independently evaluate and verify all Third-Party Content.
              </p>
              <hr />
              <h3>Termination; Survival of Provisions</h3>
              <p>
                Automatic Termination for Non-Compliance: If you are not in full compliance with all of these ToS, the
                permissions granted to you under these ToS will automatically terminate, and you agree that in such
                circumstance you will no longer use or access, or be entitled to use or access, the Website, the Content
                or any account on the Website.
              </p>
              <p>
                Wunder&#39;s Right to Terminate Your Use: Wunder may terminate your right to use the Website, or block
                you from future use, at any time in its sole discretion, with or without cause, and without notice to
                you. Some circumstances in which Wunder may exercise this right to terminate your right to use the
                Website include: (a) you have breached any provision of the ToS; (b) you have engaged in conduct which
                Wunder, in its sole discretion, considers to be unacceptable; (c) Wunder is required by law to do so; or
                (d) Wunder no longer provides the Website. The above are only examples of circumstances in which Wunder
                may terminate your right to use the Website and Wunder may terminate your right to use the Website for
                any other reason in its sole discretion. You agree that we shall not be liable to you due to or by
                reason of our termination of your right to use the Website or the automatic termination of your right to
                use the Website for non-compliance set forth above.
              </p>
              <p>
                Voluntary Termination by You: If you want to terminate your legal agreement with Wunder under these
                Terms of Service and deactivate your account, you may do so by notifying Wunder at
                support@wunderpower.com.
              </p>
              <p>
                Survival of Provisions: Your obligations and the disclaimers and provisions relating to (i) Our
                Intellectual Property, Copyrights and Identifying Marks, (ii) Disclaimer of Warranties; Limitation of
                Liability, (iii) Indemnification, (iv) Use and Access Outside the United States and (v) General will
                survive any termination or expiration of the ToS for any reason.
              </p>
              <p>
                Survival of Other Obligations: You acknowledge that any legal obligations you may have under any other
                agreement with Wunder (including agreement governing investing in a solar project through Wunder or its
                affiliates) will not be affected in any way by the termination of the ToS and any such other agreement
                between you and Wunder will continue to be in effect in accordance with its terms.
              </p>
              <hr />
              <h3>Wunder&#39;s Remedies</h3>
              <p>
                You acknowledge and agree that misappropriation or misuse of any content or data contained on the
                Website or your failure, following termination of your rights to access or use the Website, to cease
                accessing or using the Website or any content or data contained on the Website will result in immediate
                and irreparable harm to Wunder for which there is no adequate monetary remedy. If you violate the ToS,
                Wunder will be entitled, at any time, to bring an action or proceeding for specific performance,
                injunctive relief or other equitable relief in addition to (and not instead of or in substitution for)
                any other remedies at law or in equity, without the need for the posting of a bond or any other
                requirement.
              </p>
              <p>
                Without prejudice to Wunder&#39;s other rights under the ToS, if you breach the ToS in any way, Wunder
                may take such action as Wunder deems appropriate to deal with the breach, including suspending your
                access to the Website, prohibiting you from accessing the Website, blocking computers using your IP
                address from accessing the Website, contacting your Internet service or other telecommunications
                provider to request that it block your access to the Website and/or bringing court proceedings or taking
                other legal action against you.
              </p>
              <hr />
              <h3>Indemnification</h3>
              <p>
                You agree to indemnify and hold Wunder and the Released Parties from and against any suit, action,
                claim, demand, penalty or loss, including reasonable attorneys&#39; fees and expenses and any amount
                paid in settlement to a third party, made by or resulting from any third party (including any government
                agency or body) due to, in connection with or arising out of (i) your use of the Website, the Content,
                your Wunder Account, any breach or alleged or claimed breach of the ToS or the materials it incorporates
                by reference, including the Privacy Policy, or your violation of any law, regulation, order or other
                legal mandate, or the rights of a third party, or (ii) any act or omission by your agent, representative
                or third-party service provider while using your Wunder Account, regardless of whether the specific use
                was expressly authorized by you.
              </p>
              <hr />
              <h3>Your Use and Access Outside of the United States</h3>
              <p>
                We make no claims that the Website or the Content are appropriate for or may be downloaded or accessed
                outside of the United States. If you access the Website from outside the United States, you do so at
                your own risk and are responsible for compliance with the applicable laws of the country or jurisdiction
                where you may be located. You may not use or export any content of the Website in violation of U.S.
                export laws and regulations or any other U.S. or foreign federal, state or local statute, rule or
                regulation. The Content is not intended for distribution to, or use by, any person or entity in any
                jurisdiction or country where such distribution or use would be contrary to local law or regulation.
                Wunder makes no representations that the Content is appropriate for use in all locations, or that the
                transactions, products, investments, financial instruments or services indicated or discussed on the
                Website are available or appropriate for sale or use in all jurisdictions, or countries or by all
                investors or counterparties.
              </p>
              <hr />
              <h3>Fair Practices</h3>
              <p>
                You agree not to: (i) make any representations, warranties or guarantees on Wunder&#39;s behalf or with
                respect to the Website or any content or data contained on the Website; (ii) make any false or
                misleading representations with regard to Wunder or the Website or any content or data contained on the
                Website; or (iii) participate or engage in any illegal, deceptive, misleading, fraudulent, unethical or
                improper practices on, through, by means of, or with respect to the Website.
              </p>
              <hr />
              <h3>Assignment</h3>
              <p>
                You acknowledge and agree that you may not assign, delegate, sub-contract or otherwise transfer your
                rights and/or obligations under the ToS. Wunder may transfer, assign, delegate, sub-contract or
                otherwise transfer its rights and/or obligations under the ToS without notifying you or obtaining your
                consent.
              </p>
              <hr />
              <h3>Choice of Law; Forum for Disputes</h3>
              <p>
                The ToS and all disputes, claims or controversies (whether in tort, contract or otherwise) arising out
                of or relating in any way to the ToS, the Website or the Content, the negotiation, interpretation,
                validity or performance of the ToS, the rights and obligations of you and us hereunder or any
                transaction contemplated by the Website shall be governed by and construed in accordance with the laws
                of the State of Delaware without regard to the rules or principles of conflict of laws of such State or
                any other jurisdiction that would permit or require the application of the laws of any other
                jurisdiction.
              </p>
              <p>
                We and you each hereby irrevocably and unconditionally consent to submit to the sole and exclusive
                jurisdiction of the courts of the State of Delaware and of the United States of America located in the
                State of Delaware, City of Wilmington (the &quot;Delaware Courts&quot;) for any litigation, lawsuit or
                proceeding between you and us arising out of or relating in any way to the ToS (including any
                non-contractual claims), the Website or the Content, the negotiation, interpretation, validity or
                performance of the ToS, the rights and obligations of you and us hereunder or any transaction
                contemplated by the Website. You and we each waive any objection to the laying of venue of any such
                litigation in the Delaware Courts and agree not to plead or claim in any Delaware Court that such
                litigation brought therein has been brought in an inconvenient forum or that there are indispensable
                parties to such litigation that are not subject to the jurisdiction of the Delaware Courts. You and we
                each hereby irrevocably waive any and all rights which you or we, respectively, may have, or may have
                had, to bring such litigation in or before any other court or tribunal (whether domestic or foreign), or
                before any similar domestic or foreign authority or body, and agree not to claim or plead any such
                rights.
              </p>
              <hr />
              <h3>WAIVER OF JURY TRIAL</h3>
              <p>
                WE AND YOU EACH ACKNOWLEDGE AND AGREE THAT ANY CONTROVERSY THAT MAY ARISE UNDER THESE TERMS IS LIKELY TO
                INVOLVE COMPLICATED AND DIFFICULT ISSUES AND, THEREFORE, EACH PARTY IRREVOCABLY AND UNCONDITIONALLY
                WAIVES ANY RIGHT IT MAY HAVE TO A TRIAL BY JURY IN RESPECT OF ANY LEGAL ACTION OR PROCEEDING ARISING OUT
                OF OR RELATING TO THESE TERMS OF SERVICE OR THE WEBSITE AND FOR ANY COUNTERCLAIM THEREIN. EACH PARTY
                CERTIFIES AND ACKNOWLEDGES THAT (I) NO REPRESENTATIVE OF THE OTHER PARTY HAS REPRESENTED, EXPRESSLY OR
                OTHERWISE, THAT SUCH OTHER PARTY WOULD NOT SEEK TO ENFORCE THE FOREGOING WAIVER IN THE EVENT OF A LEGAL
                ACTION, (II) SUCH PARTY HAS CONSIDERED THE IMPLICATIONS OF THIS WAIVER, (III) SUCH PARTY MAKES THIS
                WAIVER VOLUNTARILY, AND (IV) SUCH PARTY HAS BEEN INDUCED TO ENTER INTO THIS AGREEMENT BY, AMONG OTHER
                THINGS, THE MUTUAL WAIVERS AND CERTIFICATIONS IN THIS PROVISION.
              </p>
              <hr />
              <h3>CLASS ACTION WAIVER</h3>
              <p>
                YOU AND WE AGREE THAT THERE WILL BE NO RIGHT OR AUTHORITY FOR ANY DISPUTE TO BE BROUGHT, HEARD, OR
                ARBITRATED AS A CLASS ACTION (INCLUDING WITHOUT LIMITATION OPT OUT CLASS ACTIONS OR OPT IN COLLECTIVE
                CLASS ACTIONS), OR IN A REPRESENTATIVE OR PRIVATE ATTORNEY GENERAL CAPACITY ON BEHALF OF A CLASS OF
                PERSONS OR THE GENERAL PUBLIC. ALL DISPUTES SHALL BE RESOLVED ON AN INDIVIDUAL BASIS ONLY.
              </p>
              <hr />
              <h3>General</h3>
              <p>
                You acknowledge and agree that all rights not otherwise expressly granted to you by the ToS are reserved
                to Wunder. The ToS, together with the Privacy Policy, constitute the sole and entire agreement between
                you and us with respect to the subject matter hereof and supersedes all other prior or contemporaneous
                negotiations, discussions, agreements, understandings, representations and warranties, both written and
                oral, between you and us with respect to such subject matter. You agree that no joint venture,
                partnership, employment, or agency relationship exists between you and Wunder as a result of the ToS or
                any use of the Website. The failure of Wunder to exercise or enforce any right or provision of the ToS
                shall not constitute a waiver of such right or provision. If any provision of the ToS is found by a
                court of competent jurisdiction to be invalid, void, unlawful or unenforceable, the Parties agree that
                the court should give effect to the Parties&#39; intentions as reflected in such provision, and the
                other provisions of the ToS shall remain in full force and effect. The section titles in the ToS are for
                convenience only and have no legal or contractual effect. Neither the course of conduct between you and
                Wunder, nor trade practice, shall serve to modify any provision of the ToS. For purposes of the ToS, the
                terms &quot;include,&quot; &quot;includes,&quot; &quot;including,&quot; &quot;such as&quot; and
                &quot;for example,&quot; shall be construed as if each term were followed by the words, &quot;without
                limitation.&quot; Except where context requires otherwise, use of the singular form of any noun includes
                the plural, and use of the plural includes the singular.
              </p>
              <hr />
              <h3>Questions or Comments -- Our Contact Information</h3>
              <p>
                We welcome questions and comments about the Terms of Service. Questions or comments should be directed
                to support@wunderpower.com.
              </p>
            </Content>
          </Container>
        </PullUp>
      </Swirl>
      <Footer />
    </ThemeProvider>
  )
}

export default Terms
