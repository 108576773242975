import React from 'react'

import { IndividualIconProps } from '../IconIndividualProps'

const Weight = (props: IndividualIconProps): JSX.Element => {
  return (
    <svg viewBox="0 0 28 28" width={props.size} height={props.size}>
      <g transform="translate(-13 -13)" fill="none" fillRule="evenodd">
        <rect strokeOpacity=".1" stroke="#000" x="-.5" y="-.5" width="144" height="55" rx="2" />
        <g transform="translate(14 14)" stroke={props.color} strokeWidth={props.strokeWidth || 2}>
          <path d="M5.64 6h14.72a2 2 0 011.962 1.608l3.2 16A2 2 0 0123.56 26H2.44a2 2 0 01-1.962-2.392l3.2-16A2 2 0 015.64 6z" />
          <circle cx="13" cy="3" r="3" />
        </g>
      </g>
    </svg>
  )
}

export default Weight
