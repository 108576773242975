import React from 'react'
import styled from 'styled-components'

interface Props {
  icon?: React.ReactNode
  title?: string
  text?: React.ReactNode
  verticallyCentered?: boolean
  className?: string
  children?: React.ReactNode
}

const BoxStyled = styled.div<{ verticallyCentered?: boolean }>((props) => ({
  alignItems: 'flex-start',
  alignContent: props.verticallyCentered ? 'center' : 'flex-start',
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  minHeight: 120,
  padding: `${props.theme.spacing.large}px ${props.theme.spacing.medium}px`,
  textAlign: 'center',

  '@media (min-width: 768px)': {
    '& + &': {
      borderLeft: `1px solid ${props.theme.colors.middleGray}`,
    },
  },
}))

const IconTitle = styled.h3((props) => ({
  alignItems: 'center',
  border: `1px solid ${props.theme.colors.middleGray}`,
  display: 'inline-flex',
  fontWeight: 300,
  fontSize: 30,
  justifyContent: 'center',
  padding: '9px 15px',

  '& > span': {
    marginRight: 7,
  },
}))

const Title = styled.h3({
  flexBasis: '100%',
  fontWeight: 300,
  letterSpacing: 1.05,
  lineHeight: 1.4,
})

const Text = styled.p({
  flexBasis: '100%',
  margin: '6px 0 0',
})

/**
 * A `Box` is always inside a `<Boxes>` parent
 */
const Box = (props: Props): JSX.Element => {
  return (
    <BoxStyled verticallyCentered={props.verticallyCentered ?? true} className={props.className}>
      {props.icon && (
        <IconTitle>
          {props.icon}
          {props.title}
        </IconTitle>
      )}
      {!props.icon && props.title && <Title>{props.title}</Title>}
      {props.text && <Text>{props.text}</Text>}
      {props.children && <>{props.children}</>}
    </BoxStyled>
  )
}

export default Box
