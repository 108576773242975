import React from 'react'
import styled from 'styled-components'

import useGetErrors from './useGetErrors'

import { red, fontSizeSmall, spacingSm } from 'shared/lib/variables'

interface Props {
  name: string
}

const ErrorsStyled = styled.div({
  color: red,
  fontSize: fontSizeSmall,
  lineHeight: 1.2,
  marginTop: spacingSm,
  textTransform: 'uppercase',
})

/**
 * Errors is used by other Form components. You shouldn't need to use this component directly when building a form.
 */
const Errors = (props: Props): JSX.Element => {
  const errors = useGetErrors(props.name)
  if (!errors) return null
  if (Array.isArray(errors) && errors.filter(Boolean).length === 0) return null

  return (
    <ErrorsStyled>
      {Array.isArray(errors) ? errors.map((error) => <div key={error}>{error}</div>) : errors}
    </ErrorsStyled>
  )
}

export default Errors
