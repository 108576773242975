import { useEffect } from 'react'
import { globalHistory } from '@reach/router'
import ReactGA from 'react-ga'

// Initialize GA (default value is just to suppress the console warning in dev if no ID is set)
const useGoogleAnalytics = (id = 'default'): void => {
  useEffect((): void => {
    ReactGA.initialize(id)

    // Send the first page pageview
    if (typeof window !== 'undefined') ReactGA.pageview(window.location.pathname + window.location.search)

    // Listen to the history and send a pageview when it changes
    globalHistory.listen(({ location }) => {
      ReactGA.pageview(location.pathname + location.search)
    })
  }, [])
}

export default useGoogleAnalytics
