interface Response {
  totalMw: string
  wunderers: string
  lbsDisplaced: string
  lbsDisplacedPerWunderer: string
  totalKwh: string
  vehiclesDisplaced: string
  gallonsDisplaced: string
  coalDisplaced: string
  homesDisplaced: string
}

export const calculateMarketingStats = (): Response => {
  const mw = 515.2

  // A rough ratio pulled from https://www.epa.gov/energy/greenhouse-gas-equivalencies-calculator
  // mw * 1000 * 1400 = kwh
  // Then use the calculator to get rough ratios for lbs displaced and other statistics
  const totalMw = `${mw.toFixed(0)}MW`

  const wunderersNum = 40
  const wunderers = wunderersNum.toString()

  const lbsDisplacedNum = mw * 2.2
  const lbsDisplaced = `${lbsDisplacedNum.toFixed(1)}M lbs`

  const lbsDisplacedPerWundererNum = lbsDisplacedNum / wunderersNum
  const lbsDisplacedPerWunderer = `${lbsDisplacedPerWundererNum.toFixed(1)}M`

  const totalKwhNum = mw * 1000
  const totalKwh = totalKwhNum.toLocaleString('en-US')

  const vehiclesDisplacedNum = totalKwhNum / 4.75
  const vehiclesDisplaced = (+vehiclesDisplacedNum.toFixed(0)).toLocaleString('en-US')

  const gallonsDisplacedNum = totalKwhNum * 111.4
  const gallonsDisplaced = `${(gallonsDisplacedNum / 1000000).toFixed(1)}M`

  const coalDisplacedNum = totalKwhNum * 1090
  const coalDisplaced = `${(coalDisplacedNum / 1000000).toFixed(0)}M`

  const homesDisplacedNum = totalKwhNum * 0.115
  const homesDisplaced = (+homesDisplacedNum.toFixed(0)).toLocaleString('en-US')

  return {
    totalMw,
    wunderers,
    lbsDisplaced,
    lbsDisplacedPerWunderer,
    totalKwh,
    vehiclesDisplaced,
    gallonsDisplaced,
    coalDisplaced,
    homesDisplaced,
  }
}
