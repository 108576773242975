export const baseUrl = (): string => 'https://www.wunderpower.com'

/**********************************************************************************************************************
 *** API
 *********************************************************************************************************************/
export const apiPressArticlesPath = (): string => '/api/marketing/press'

// Authentication
export const apiLoginPath = (): string => '/api/login'
export const apiPasswordPath = (): string => '/user/password'
export const apiMfaPath = (): string => '/api/mfa'

// Sign Up
export const apiUserInvitationPath = (): string => '/user/invitation'

/**********************************************************************************************************************
 *** App
 *********************************************************************************************************************/
export const homePath = (): string => '/'
export const afterSignInPath = (): string => '/after-sign-in'

export const careersPath = (): string => '/careers'
export const openRolesPath = (department?: number): string => {
  return `/careers/open-roles${department ? '/department/' + department : ''}`
}
export const jobPath = (jobId: number): string => {
  return `/careers/apply/${jobId}`
}

export const aboutPath = (): string => '/about'
export const pressPath = (): string => '/press'
export const contactPath = (): string => '/contact'
export const supportPath = (): string => '/support'
export const sustainabilityPath = (): string => '/sustainability'

export const signupPath = (): string => '/signup'
export const loginPath = (): string => '/login'
export const mfaPath = (): string => '/mfa'
export const resetPasswordPath = (): string => '/reset-password'
export const resetPasswordSuccessPath = (): string => '/reset-password/success'
export const changePasswordPath = (): string => '/change-password'

export const privacyPath = (): string => '/legal/privacy'
export const termsPath = (): string => '/legal/terms'
export const dwollaPrivacyPath = (): string => 'https://www.dwolla.com/legal/privacy'
export const dwollaTermsPath = (): string => 'https://www.dwolla.com/legal/tos'
export const synapsePrivacyPath = (): string => 'https://synapsefi.com/privacy'
export const synapseTermsPath = (): string => 'https://synapsefi.com/tos'
export const evolvePrivacyPath = (): string => 'https://synapsefi.com/evolve-privacy'

// Portals
export const investorPath = (): string => '/investor'
export const borrowerConfirmEmailPath = (): string => '/email-verification-required'
export const borrowerProjectsPath = (): string => '/borrower/projects'
