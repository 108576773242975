import React from 'react'
import styled from 'styled-components'

import Anchor from 'marketing/components/Anchor/Anchor'

interface Props {
  icon?: React.ReactNode
  title?: React.ReactNode
  subtitle?: React.ReactNode
  text?: React.ReactNode
  to?: string
  href?: string
  linkText?: string
  targetBlank?: boolean
  emphasis?: boolean
  className?: string
  children?: React.ReactNode
}

const CardStyled = styled.div<Props>((props) => ({
  backgroundColor: 'white',
  border: `1px solid ${props.theme.colors.middleGray}`,
  borderRadius: props.theme.borderRadius,
  color: props.theme.colors.offBlack,
  textAlign: 'center',

  ':hover': {
    border: `1px solid ${props.to || props.href ? props.theme.currentColor : props.theme.colors.middleGray}`,
  },

  ':hover a': {
    color: props.theme.colors.offBlack,
    textDecoration: 'none',
  },
}))

const InnerCard = styled.div((props) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  justifyContent: 'space-between',
  padding: props.theme.spacing.large,
}))

const IconWrapper = styled.div((props) => ({
  marginBottom: props.theme.spacing.small,
}))

const Subtitle = styled.h5<{ emphasis: boolean }>((props) => ({
  color: props.emphasis ? props.theme.currentColor : props.theme.colors.darkGray,
  margin: `${props.theme.spacing.small}px 0 0`,
}))

const TextStyled = styled.p({
  margin: '10px 0 0',
})

const LinkText = styled.p((props) => ({
  borderTop: `1px solid ${props.theme.colors.middleGray}`,
  color: props.theme.currentColor,
  fontWeight: 400,
  margin: `${props.theme.spacing.large}px -${props.theme.spacing.large}px -${props.theme.spacing.large}px`,
  padding: props.theme.spacing.small,
}))

const Card = (props: Props): JSX.Element => {
  return (
    <CardStyled className={props.className} to={props.to} href={props.href}>
      <Anchor to={props.to} href={props.href} targetBlank={props.targetBlank}>
        <InnerCard>
          <div>
            {props.icon && <IconWrapper>{props.icon}</IconWrapper>}
            {props.title && <h3>{props.title}</h3>}
            {props.subtitle && <Subtitle emphasis={props.emphasis}>{props.subtitle}</Subtitle>}
            {props.text && <TextStyled>{props.text}</TextStyled>}
            {props.children}
          </div>
          {props.linkText && <LinkText>{props.linkText}</LinkText>}
        </InnerCard>
      </Anchor>
    </CardStyled>
  )
}

export default Card
