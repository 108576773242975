import React from 'react'
import styled from 'styled-components'

import { calculateMarketingStats } from './impactStats'

import Boxes from 'marketing/components/Box/Boxes'
import Box from 'marketing/components/Box/Box'
import Section from 'marketing/components/Section/Section'
import Highlight from 'marketing/components/Highlight/Highlight'
import Icon from 'marketing/components/Icon/Icon'
import theme from 'marketing/components/Global/theme'
import Button from 'marketing/components/Button/Button'
import { sustainabilityPath } from 'marketing/helpers/routes'

interface Props {
  divider?: boolean
}

const ImpactBoxesStyled = styled.div((props) => ({
  margin: `15px 0 ${props.theme.spacing.medium}px`,
  width: '100%',
}))

const ImpactSection = ({ divider }: Props): JSX.Element => {
  const impactStats = calculateMarketingStats()

  return (
    <Section
      h2="An impact that matters"
      text={
        <>
          Many companies — and individuals — are recognizing that climate change will result in catastrophic human,
          economic, and environmental fallout. And while some may finally be taking small steps to try and mitigate
          their impact, very few have made it their express purpose and mission to fight climate change.
          <br />
          <Highlight>Since 2013, we’ve been doing just that.</Highlight>
        </>
      }
      divider={divider}
    >
      <ImpactBoxesStyled>
        <Boxes columns={3} open>
          <Box
            icon={<Icon name="sun" fill={false} color={theme.colors.offBlack} strokeWidth={2} />}
            title={impactStats.totalKwh}
            text="How many kilowatts of commercial-scale solar power that we’ve helped develop to date"
          />
          <Box
            icon={<Icon name="car" fill={false} color={theme.colors.offBlack} strokeWidth={2} size={25} />}
            title={impactStats.vehiclesDisplaced}
            text="How many vehicles on the road we displace each year, offsetting their carbon pollution"
          />
          <Box
            icon={<Icon name="weight" fill={false} color={theme.colors.offBlack} strokeWidth={2} size={25} />}
            title={impactStats.lbsDisplacedPerWunderer}
            text="How many pounds of greenhouse gas emissions each Wunder-er will individually offset this year"
          />
        </Boxes>
      </ImpactBoxesStyled>

      <Button to={sustainabilityPath()}>Learn More</Button>
    </Section>
  )
}

export default ImpactSection
