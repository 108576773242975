import React from 'react'

import { IndividualIconProps } from '../IconIndividualProps'

const Shield = (props: IndividualIconProps): JSX.Element => {
  return (
    <svg viewBox="0 0 25 25" width={props.size} height={props.size}>
      <g fill="none" fillRule="evenodd">
        <g stroke={props.color}>
          <path
            d="M24.412 1.307c.059.677.088 1.359.088 2.044 0 8.428-4.48 16.12-11.737 20.603l-.263.162-.262-.162C4.98 19.473.5 11.779.5 3.35c0-.685.03-1.367.088-2.044L.628.85 2.348.85l.144.135a6.968 6.968 0 004.78 1.865A6.968 6.968 0 0012.05.987l.144-.134.608-.003.145.136a6.968 6.968 0 004.78 1.865A6.968 6.968 0 0022.506.986l.144-.135L24.373.85l.04.457z"
            fillOpacity={props.fillOpacity}
            fill={props.color}
            strokeLinejoin="round"
          />
          <path strokeLinecap="round" d="M12.5 6.851v10" />
        </g>
      </g>
    </svg>
  )
}

export default Shield
