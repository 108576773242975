import React, { useEffect, useState } from 'react'
import { RouteComponentProps } from '@reach/router'
import { ThemeProvider } from 'styled-components'
import { connect } from 'react-redux'

import JobsList from './JobsList'

import Header from 'marketing/components/Header/Header'
import theme from 'marketing/components/Global/theme'
import Swirl from 'marketing/components/Swirl/Swirl'
import Section from 'marketing/components/Section/Section'
import Anchor from 'marketing/components/Anchor/Anchor'
import Button from 'marketing/components/Button/Button'
import Footer from 'marketing/components/Footer/Footer'
import { careersPath, openRolesPath } from 'marketing/helpers/routes'
import { RootState } from 'marketing/redux/store'
import { getDepartments } from 'marketing/redux/careers/departments/effects'
import { getJobs } from 'marketing/redux/careers/jobs/effects'
import { JobInterface } from 'marketing/redux/careers/jobs/interfaces'
import { DepartmentInterface } from 'marketing/redux/careers/departments/interfaces'
import { selectAllDepartments } from 'marketing/redux/careers/departments/selectors'
import { selectJobs } from 'marketing/redux/careers/jobs/selectors'
import DropdownButton from 'marketing/components/DropdownButton/DropdownButton'
import useDocTitle from 'shared/lib/useDocTitle'
import { selectIsAnyLoading } from 'shared/redux/loading'
import { AppDispatch } from 'shared/redux/types'

interface Props {
  jobs?: { [id: string]: JobInterface }
  departments?: DepartmentInterface[]
  departmentId?: string
  isLoading?: boolean
  dispatch?: AppDispatch
}

const DepartmentDropdown = ({
  text,
  departments,
}: {
  text: string
  departments: DepartmentInterface[]
}): JSX.Element => {
  const options = [
    { to: openRolesPath(), text: 'All Departments' },
    ...departments.map((d) => ({ to: openRolesPath(d.id), text: d.name })),
  ]

  return (
    <DropdownButton options={options} data-action="department-dropdown">
      {text}
    </DropdownButton>
  )
}

const OpenRoles = (props: Props & RouteComponentProps): JSX.Element => {
  useDocTitle('Open Roles - Wunder')
  const [departments, setDepartments] = useState(null)

  useEffect((): void => {
    props.dispatch(getDepartments())
    props.dispatch(getJobs())
  }, [])

  useEffect((): void => {
    // If someone visits open-roles without specifying a department, only show them departments with jobs.
    // However, if they specify a department, show that regardless of whether or not it has any jobs.
    setDepartments(
      props.departmentId
        ? props.departments.filter((d) => d.id.toString() === props.departmentId)
        : props.departments.filter((d) => d.jobs.length),
    )
  }, [props.departments, props.departmentId])

  return (
    <ThemeProvider theme={{ ...theme, currentColor: theme.colors.blue }}>
      <Swirl direction="to bottom">
        <Header />
        <Section
          h1="Open Roles"
          text={
            <>
              We need the best in their fields to join the fight against climate change. If you don’t see a position
              that meets your unique skillset or background, please send your resumé and cover letter to{' '}
              <Anchor href="mailto:hustle@wunderpower.com" isAlwaysColored targetBlank underline>
                <strong>hustle@wunderpower.com</strong>
              </Anchor>
              .
            </>
          }
        >
          <DepartmentDropdown
            text={departments?.length === 1 ? departments[0].name : 'All Departments'}
            departments={props.departments}
          />
          <Button to={careersPath()}>Back to Careers</Button>
        </Section>
      </Swirl>

      <JobsList jobs={props.jobs} departments={departments} isLoading={props.isLoading} />

      <Footer />
    </ThemeProvider>
  )
}

function mapState(state: RootState): Props {
  return {
    jobs: selectJobs(state),
    departments: selectAllDepartments(state),
    isLoading: selectIsAnyLoading(state),
  }
}

export default connect(mapState)(OpenRoles)
