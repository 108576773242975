import React from 'react'

import { IndividualIconProps } from '../IconIndividualProps'

const Person = (props: IndividualIconProps): JSX.Element => {
  return (
    <svg viewBox="0 0 25 28" width={props.size} height={props.size}>
      <g fill="none" fillRule="evenodd">
        <g fill={props.color} fillOpacity={props.fillOpacity} stroke={props.color}>
          <path d="M10 17h5a9 9 0 019 9v1h0H1v-1a9 9 0 019-9zM12.5 15c3.464 0 6.273-4.686 6.273-8S15.964 1 12.5 1C9.036 1 6.227 3.686 6.227 7s2.809 8 6.273 8z" />
        </g>
      </g>
    </svg>
  )
}

export default Person
