import { createSelector } from 'reselect'

import { JobInterface } from './interfaces'

import { RootState } from 'marketing/redux/store'

export const selectJobs = (state: RootState): { [key: string]: JobInterface } => state.careers.jobs
const selectId = (state: RootState, id: number): number => id

export const selectAllJobs = createSelector(selectJobs, (jobs): JobInterface[] => {
  return Object.keys(jobs).map((key): JobInterface => jobs[key])
})

export const selectJobById = createSelector([selectJobs, selectId], (jobs, id): JobInterface => {
  return jobs[id]
})
