import React from 'react'

import Solar1 from './assets/solar1.jpg'
import Solar2 from './assets/solar2.jpg'
import Solar3 from './assets/solar3.jpg'
import Solar4 from './assets/solar4.jpg'
import Solar5 from './assets/solar5.jpg'
import Solar6 from './assets/solar6.jpg'
import Solar7 from './assets/solar7.jpg'
import Solar8 from './assets/solar8.jpg'
import Solar9 from './assets/solar9.jpg'
import Solar10 from './assets/solar10.jpg'

import ImageCollage from 'marketing/components/ImageCollage/ImageCollage'

const SolarPicturesSlider = (): JSX.Element => {
  return (
    <ImageCollage rows={1}>
      <img src={Solar1} />
      <img src={Solar2} />
      <img src={Solar3} />
      <img src={Solar4} />
      <img src={Solar5} />
      <img src={Solar6} />
      <img src={Solar7} />
      <img src={Solar8} />
      <img src={Solar9} />
      <img src={Solar10} />
    </ImageCollage>
  )
}

export default SolarPicturesSlider
