import React from 'react'
import styled from 'styled-components'
import { rgba } from 'polished'

import FooterLink from './FooterLink'
import cciaLogo from './logos/ccia.svg'
import coseiaLogo from './logos/coseia.svg'

import Container from 'marketing/components/Container/Container'
import {
  supportPath,
  aboutPath,
  careersPath,
  pressPath,
  contactPath,
  privacyPath,
  termsPath,
  sustainabilityPath,
} from 'marketing/helpers/routes'

const LinkGroup = styled.div((props) => ({
  '& + &': {
    marginTop: props.theme.spacing.xlarge,
  },

  '@media (min-width: 768px)': {
    '& + &': {
      marginTop: 0,
    },
  },
}))

const FooterStyled = styled.footer((props) => ({
  backgroundColor: props.theme.colors.offBlack,
  padding: `${props.theme.spacing.xlarge}px 0`,

  '& a': {
    color: 'white',
  },

  '& a:hover': {
    color: 'white',
  },

  '@media (min-width: 768px)': {
    padding: `${props.theme.spacing.xlarge * 2}px 0 ${props.theme.spacing.xlarge}px`,
  },
}))

const FooterLinks = styled.div({
  textAlign: 'center',

  '@media (min-width: 768px)': {
    display: 'flex',
    justifyContent: 'space-between',
    textAlign: 'left',
  },
})

const LinkGroupTitle = styled.h4((props) => ({
  color: props.theme.currentColor,
  marginTop: 0,
}))

const HiringBadge = styled.span((props) => ({
  backgroundColor: `${rgba(props.theme.currentColor, 0.1)}`,
  borderRadius: '15px',
  color: props.theme.currentColor,
  display: 'inline-block',
  fontWeight: 700,
  marginTop: -2,
  marginLeft: props.theme.spacing.small,
  padding: '2px 12px',
  position: 'absolute',
  textTransform: 'uppercase',
}))

const PartnerLogo = styled.div((props) => ({
  '& + &': {
    marginTop: props.theme.spacing.medium,
  },
}))

const DisclaimerText = styled.p((props) => ({
  color: 'white',
  fontSize: '.77rem',
  lineHeight: '21px',
  opacity: '25%',

  ':first-of-type': {
    marginTop: props.theme.spacing.xlarge,
  },
}))

/**
 * Colors change based on the grandparent's `ThemeProvider`, though it doesn't need to be inside a `ThemeProvider`.
 */
const Footer = (): JSX.Element => {
  return (
    <FooterStyled>
      <Container>
        <FooterLinks>
          <LinkGroup>
            <LinkGroupTitle>Resources</LinkGroupTitle>
            <FooterLink href={supportPath()}>Support</FooterLink>
          </LinkGroup>

          <LinkGroup>
            <LinkGroupTitle>Company</LinkGroupTitle>
            <FooterLink to={aboutPath()}>About Us</FooterLink>
            <FooterLink to={careersPath()}>
              Careers<HiringBadge>We’re Hiring!</HiringBadge>
            </FooterLink>
            <FooterLink to={sustainabilityPath()}>Sustainability</FooterLink>
            <FooterLink to={pressPath()}>Press</FooterLink>
            <FooterLink to={contactPath()}>Contact</FooterLink>
            <FooterLink to={privacyPath()}>Privacy</FooterLink>
            <FooterLink to={termsPath()}>Terms of Service</FooterLink>
          </LinkGroup>

          <LinkGroup>
            <PartnerLogo>
              <img src={cciaLogo} />
            </PartnerLogo>
            <PartnerLogo>
              <img src={coseiaLogo} />
            </PartnerLogo>
          </LinkGroup>
        </FooterLinks>

        <DisclaimerText>
          INFORMATION ON THIS PAGE IS SUMMARY IN NATURE AND IS NOT AN OFFER TO INVEST. INFORMATION ON THIS SITE
          PERTAINING TO AN INVESTMENT IN A FUND IS QUALIFIED IN ITS ENTIRETY BY THE FUND’S OFFERING MATERIALS, WHICH
          SHOULD BE REVIEWED CAREFULLY PRIOR TO MAKING AN INVESTMENT DECISION. PLEASE SEE THESE DOCUMENTS FOR FULL
          DETAILS REGARDING THE TERMS OF THE INVESTMENT, RISKS, MINIMUM INVESTMENT, FEES AND EXPENSES.
        </DisclaimerText>
        <DisclaimerText>
          WUNDERPOWER.COM IS INTENDED ONLY FOR ACCREDITED INVESTORS RESIDING IN THE UNITED STATES. INVESTORS SHOULD
          CONDUCT THEIR OWN DUE DILIGENCE AND ARE ENCOURAGED TO CONSULT WITH A FINANCIAL ADVISOR, ATTORNEY, ACCOUNTANT,
          AND ANY OTHER PROFESSIONAL THAT CAN HELP INVESTORS TO UNDERSTAND AND ASSESS THE RISKS ASSOCIATED WITH ANY
          INVESTMENT OPPORTUNITY.
        </DisclaimerText>
        <DisclaimerText>
          INVESTMENTS ARE NOT BANK DEPOSITS (AND THUS ARE NOT INSURED BY THE FDIC OR BY ANY OTHER GOVERNMENTAL AGENCY),
          ARE NOT GUARANTEED BY WUNDER OR ANY OTHER ENTITY, AND MAY LOSE VALUE. ANY INVESTMENT RETURNS STATED ON THIS
          WEBSITE ARE PROJECTED RETURNS AND THERE IS NO GUARANTEE THAT SUCH RETURNS WILL ULTIMATELY BE REALIZED.
          INVESTORS MUST BE ABLE TO AFFORD THE LOSS OF THEIR ENTIRE INVESTMENT. ANY EXAMPLES SHOWN ON THE WEBSITE ARE
          FOR ILLUSTRATIVE PURPOSES ONLY, AND DO NOT REPRESENT ACTUAL REALIZED INVESTMENTS.
        </DisclaimerText>
        <DisclaimerText>
          NEITHER THE SECURITIES AND EXCHANGE COMMISSION NOR ANY STATE SECURITIES COMMISSION HAS APPROVED OR DISAPPROVED
          OF THESE NOTES OR DETERMINED IF THE OFFERING MATERIALS ARE TRUTHFUL OR COMPLETE. ANY REPRESENTATION TO THE
          CONTRARY IS A CRIMINAL OFFENSE
        </DisclaimerText>
        <DisclaimerText>&copy; WUNDER</DisclaimerText>
      </Container>
    </FooterStyled>
  )
}

export default Footer
