import React, { useContext } from 'react'
import { ThemeContext } from 'styled-components'

/**
 * Colors change based on the grandparent's `ThemeProvider`, though it doesn't need to be inside a `ThemeProvider`.
 */
const Logo = (): JSX.Element => {
  const themeContext = useContext(ThemeContext)

  return (
    <svg width="60" height="17" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M56.311 1h-.096a4.824 4.824 0 00-2.504.756L40.747 9.128l-4.62-4.608A8.62 8.62 0 0030 1.99H30a8.62 8.62 0 00-6.126 2.53l-4.62 4.608L6.29 1.757l-.001-.001A4.823 4.823 0 003.785 1H3.69A2.785 2.785 0 001 3.778c0 .405.086.796.255 1.162l.013.028 4.75 8.146A5.178 5.178 0 0010.594 16h.054a8.618 8.618 0 006.072-2.531l2.75-2.742 6.259 3.58.352.179a8.724 8.724 0 003.917.932h.006a8.726 8.726 0 003.917-.932l.352-.18 6.259-3.579 2.75 2.742a8.618 8.618 0 006.072 2.53l.055.001h0a5.178 5.178 0 004.573-2.887l4.736-8.12.028-.054c.17-.366.255-.757.255-1.162 0-1.5-1.197-2.725-2.689-2.776h0zM15.81 12.563a7.34 7.34 0 01-5.184 2.155 3.89 3.89 0 01-3.143-1.647l-5.07-8.693a1.498 1.498 0 011.335-2.094c.527.008 1.04.132 1.507.362l13.056 7.423-2.5 2.494h0zm17.506.791a7.434 7.434 0 01-3.313.782h-.004a7.433 7.433 0 01-3.313-.782l-6.273-3.567 4.37-4.358A7.34 7.34 0 0130 3.273H30a7.34 7.34 0 015.216 2.155l4.371 4.358-6.273 3.567h0zm24.273-8.976l-5.07 8.693a3.89 3.89 0 01-3.142 1.647 7.341 7.341 0 01-5.185-2.155l-2.501-2.494 12.688-7.214.361-.206a3.543 3.543 0 011.514-.366 1.498 1.498 0 011.335 2.095h0z"
        fill={themeContext.currentColor}
        stroke={themeContext.currentColor}
        fillRule="evenodd"
      />
    </svg>
  )
}

export default Logo
