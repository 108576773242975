import React from 'react'
import styled from 'styled-components'
import { chunk } from 'lodash'

import Container from 'marketing/components/Container/Container'

interface Props {
  columns: number
  children: JSX.Element[]
}

const Row = styled.div<Props>((props) => ({
  justifyContent: 'center',

  '& > div': {
    marginTop: props.theme.spacing.large / 2,
    flexBasis: `calc(${100 / props.columns}% - 18px)`,
  },

  '@media (min-width: 768px)': {
    display: 'flex',

    '&:first-of-type > div': {
      marginTop: 0,
    },

    '& > div': {
      marginTop: props.theme.spacing.large,
    },

    '& > div + div': {
      marginLeft: props.theme.spacing.large,
    },
  },
}))

const Cards = (props: Props): JSX.Element => {
  const rows = chunk(props.children.filter(Boolean), props.columns)

  return (
    <Container>
      {rows.map((row, i) => (
        <Row key={i} {...props}>
          {row}
        </Row>
      ))}
    </Container>
  )
}

export default Cards
