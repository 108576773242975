import React from 'react'

import { IndividualIconProps } from '../IconIndividualProps'

const Sun = (props: IndividualIconProps): JSX.Element => {
  return (
    <svg viewBox="0 0 31 31" width={props.size} height={props.size}>
      <g fill="none" fillRule="evenodd">
        <g transform="translate(1 1)" stroke={props.color} strokeWidth={props.strokeWidth}>
          <circle fillOpacity={props.fillOpacity} fill={props.color} cx="14.5" cy="14.5" r="8" />
          <g strokeLinecap="round">
            <path d="M14.5 24.79v3.742M14.5.468V4.21" />
          </g>
          <g strokeLinecap="round">
            <path d="M24.79 14.5h3.742M.468 14.5H4.21" />
          </g>
          <g strokeLinecap="round">
            <path d="M21.776 7.224l2.646-2.646M4.578 24.422l2.646-2.646" />
          </g>
          <g strokeLinecap="round">
            <path d="M7.224 7.224L4.578 4.578M24.422 24.422l-2.646-2.646" />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default Sun
