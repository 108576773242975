import React from 'react'
import { Formik } from 'formik'
import { connect } from 'react-redux'
import { navigate } from '@reach/router'
import { toast } from 'react-toastify'

import Section from 'marketing/components/Section/Section'
import Container from 'marketing/components/Container/Container'
import FormWrapper from 'shared/components/Form/FormWrapper/FormWrapper'
import Password from 'shared/components/Form/Password/Password'
import Button from 'marketing/components/Button/Button'
import { RootState } from 'marketing/redux/store'
import { selectIsLoading } from 'shared/redux/loading'
import { isSuccess, getUrlParam } from 'shared/lib/utils'
import { setErrors } from 'shared/lib/formUtils'
import { changePassword, CHANGE_PASSWORD } from 'shared/redux/user/effects'
import { loginPath } from 'marketing/helpers/routes'
import { AppDispatch } from 'shared/redux/types'

interface Props {
  isLoading?: boolean
  dispatch?: AppDispatch
}

const ChangePasswordForm = (props: Props): JSX.Element => {
  const resetPasswordToken = getUrlParam('reset_password_token')
  const handleSubmit = (values, form): void => {
    props.dispatch(changePassword(values)).then((action): void => {
      if (isSuccess(action)) {
        toast.success('Your password has been changed. You may now log in.')
        navigate(loginPath())
      } else {
        setErrors(form, action.payload.errors)
      }
    })
  }

  return (
    <Section h1="Change Your Password" text="Enter a new password below." data-section="change-password-form">
      <Container maxWidth={400}>
        <Formik
          initialValues={{ password: '', passwordConfirmation: '', resetPasswordToken: resetPasswordToken }}
          onSubmit={handleSubmit}
        >
          <FormWrapper method="post">
            <Password name="password" label="New Password" />
            <Password name="passwordConfirmation" label="Confirm New Password" />

            <Button type="submit" block disabled={props.isLoading}>
              Submit
            </Button>
          </FormWrapper>
        </Formik>
      </Container>
    </Section>
  )
}

const mapState = (state: RootState): Props => ({
  isLoading: selectIsLoading(state, [CHANGE_PASSWORD]),
})

export default connect(mapState)(ChangePasswordForm)
