import React from 'react'
import { RouteComponentProps } from '@reach/router'
import { ThemeProvider } from 'styled-components'

import InvitationForm from './InvitationForm'

import theme from 'marketing/components/Global/theme'
import GrayPage from 'marketing/components/GrayPage/GrayPage'
import HeaderMinimal from 'marketing/components/Header/HeaderMinimal'
import useDocTitle from 'shared/lib/useDocTitle'

const Invitation: React.FC<RouteComponentProps> = (): JSX.Element => {
  useDocTitle('Invitation - Wunder')

  return (
    <ThemeProvider theme={{ ...theme, currentColor: theme.colors.blue }}>
      <GrayPage>
        <HeaderMinimal />
        <InvitationForm />
      </GrayPage>
    </ThemeProvider>
  )
}

export default Invitation
