import React from 'react'
import styled from 'styled-components'
import ReactMultiCarousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'

interface Props {
  children: JSX.Element[]
}

const config = {
  arrows: false,
  autoPlay: false,
  autoPlaySpeed: 10000,
  centerMode: true,
  // FIXME: Used for SSR. We should actually detect the user agent on the server and pass that in.
  deviceType: 'desktop',
  focusOnSelect: true,
  infinite: true,
  keyBoardControl: true,
  responsive: {
    desktop: {
      breakpoint: {
        max: 9999,
        min: 1024,
      },
      items: 1,
    },
    mobile: {
      breakpoint: {
        max: 1024,
        min: 0,
      },
      items: 1,
    },
  },
  showDots: false,
  slidesToSlide: 1,
  ssr: true,
}

const Slide = styled.div({
  cursor: 'pointer',
  display: 'flex',
  margin: '0 15px',
  minHeight: 247,
  transition: 'color .5s',
})

const Carousel = (props: Props): JSX.Element => {
  return (
    <ReactMultiCarousel {...config}>
      {props.children.map((slide) => (
        <Slide key={slide.key}>{slide}</Slide>
      ))}
    </ReactMultiCarousel>
  )
}

export default Carousel
