import styled from 'styled-components'
import { rgba } from 'polished'

import Question from './question.svg'
import Info from './info.svg'

import { slateFilter, slate, zIndex, borderRadius, spacingSm, white } from 'shared/lib/variables'
import { Icon } from 'shared/components/Form/Tooltip/Tooltip'

export const TooltipStyled = styled.div({
  display: 'none',
  height: 60,
  position: 'relative',

  // Tooltips inside form elements
  '.input &, .radio &, .checkbox &': {
    position: 'absolute',
    right: 0,
    top: 0,
  },

  '@media (min-width: 768px)': {
    display: 'block',
  },
})

const tooltipIcons = {
  question: Question,
  info: Info,
}

export const TooltipIcon = styled.div<{ icon: Icon }>((props) => ({
  alignItems: 'center',
  background: `linear-gradient(to right, ${rgba(white, 0.1)} 0%, ${rgba(white, 1)} 25%, ${rgba(white, 1)} 100%)`,
  borderTopRightRadius: borderRadius,
  borderBottomRightRadius: borderRadius,
  display: 'flex',
  height: 58,
  justifyContent: 'center',
  marginRight: 1,
  marginTop: 1,
  width: 50,

  '&::before': {
    background: `center / contain no-repeat url(${tooltipIcons[props.icon]})`,
    content: "''",
    display: 'block',
    filter: slateFilter,
    height: 18,
    width: 18,
  },

  // Inside disabled form elements
  '[data-disabled] &': {
    background: 'transparent',
  },
}))

export const TooltipContent = styled.div({
  background: slate,
  borderRadius: borderRadius,
  color: 'white',
  display: 'none',
  padding: spacingSm,
  position: 'absolute',
  right: 0,
  top: 60,
  width: 215,
  zIndex: zIndex.tooltip,

  // Triangle
  '&::after': {
    borderLeft: '10px solid transparent',
    borderRight: '10px solid transparent',
    borderBottom: `10px solid ${slate}`,
    content: "''",
    display: 'block',
    height: 0,
    position: 'absolute',
    right: 15,
    top: -10,
    width: 0,
  },

  [`${TooltipStyled}:hover &`]: {
    display: 'block',
  },
})
