import { createStore, applyMiddleware, combineReducers, compose } from 'redux'
import thunk from 'redux-thunk'

import apiMiddleware from 'shared/redux/apiMiddleware'
import loadingReducer from 'shared/redux/loading'
import { jobsReducer } from 'marketing/redux/careers/jobs/reducer'
import { departmentsReducer } from 'marketing/redux/careers/departments/reducer'
import { pressArticlesReducer } from 'marketing/redux/pressArticles/reducer'
import { JobInterface } from 'marketing/redux/careers/jobs/interfaces'
import { DepartmentInterface } from 'marketing/redux/careers/departments/interfaces'
import { PressArticleInterface } from 'marketing/redux/pressArticles/interfaces'
import { userReducer } from 'shared/redux/user/reducer'
import { UserInterface } from 'shared/redux/user/interfaces'

// Chrome React Dev Tools
const composeEnhancers =
  typeof window === 'object' && window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__']
    ? window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__']({})
    : compose

export interface RootState {
  careers: {
    jobs: { [id: string]: JobInterface }
    departments: { [id: string]: DepartmentInterface }
  }
  pressArticles: { [id: string]: PressArticleInterface }
  loading: { [id: string]: boolean }
  shared: {
    entities: {
      user: UserInterface
    }
  }
}

const careersReducer = combineReducers({
  jobs: jobsReducer,
  departments: departmentsReducer,
})

const sharedEntitiesReducer = combineReducers({
  user: userReducer,
})

const sharedReducer = combineReducers({
  entities: sharedEntitiesReducer,
})

const rootReducer = combineReducers({
  careers: careersReducer,
  pressArticles: pressArticlesReducer,
  loading: loadingReducer,
  shared: sharedReducer,
})

export default createStore(rootReducer, composeEnhancers(applyMiddleware(thunk, apiMiddleware)))
