let honeybadger

if (typeof window !== 'undefined') {
  const Honeybadger = require('@honeybadger-io/js')

  const config = {
    apiKey: process.env.HONEYBADGER_FRONTEND_API_KEY,
    breadcrumbsEnabled: true,
    environment: process.env.WUNDER_ENV
  }

  honeybadger = Honeybadger.configure(config)

  Honeybadger.beforeNotify(function (notice) {
    if (/UnhandledPromiseRejectionWarning: Object Not Found Matching Id:\d+/.test(notice.message)) {
      // Group any unhandled promises that look like this
      // (they have different id numbers, so it's difficult to ignore them all in honeybadger)
      // https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062/30
      notice.fingerprint = 'Ignorable unhandled rejection'
    } else if (notice.name === 'window.onunhandledrejection') {
      // Other unhandled rejections can be grouped by their exact message
      notice.fingerprint = notice.message
    }
  })
}

export default honeybadger
