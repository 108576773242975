import React from 'react'

import { IndividualIconProps } from '../IconIndividualProps'

const LifeJacket = (props: IndividualIconProps): JSX.Element => {
  return (
    <svg viewBox="0 0 25 26" width={props.size} height={props.size}>
      <g transform="translate(-4 -3.71)" stroke={props.color} fill="none" fillRule="evenodd">
        <rect x="4.5" y="4.5" width="24" height="24" rx="2" />
        <path
          d="M24.985 8.015A11.962 11.962 0 0128.5 16.5c0 3.071-1.172 6.142-3.515 8.485A11.962 11.962 0 0116.5 28.5a11.962 11.962 0 01-8.485-3.515A11.962 11.962 0 014.5 16.5c0-3.071 1.172-6.142 3.515-8.485A11.962 11.962 0 0116.5 4.5c3.071 0 6.142 1.172 8.485 3.515zm-4.242 4.242A5.981 5.981 0 0016.5 10.5a5.981 5.981 0 00-4.243 1.757A5.981 5.981 0 0010.5 16.5c0 1.536.586 3.071 1.757 4.243A5.981 5.981 0 0016.5 22.5a5.981 5.981 0 004.243-1.757A5.981 5.981 0 0022.5 16.5a5.981 5.981 0 00-1.757-4.243z"
          fillOpacity={props.fillOpacity}
          fill={props.color}
        />
        <path d="M12.257 12.258L8.015 8.015a11.966 11.966 0 00-3.512 8.748 11.96 11.96 0 004.248 8.9l4.255-4.254-.749-.666a5.979 5.979 0 01-1.753-4.01 5.983 5.983 0 011.753-4.475zm12.728-4.243l-4.242 4.243a5.983 5.983 0 011.753 4.475c-.057 1.457-.641 2.897-2.093 4.324l-.41.352 4.256 4.254a11.96 11.96 0 004.248-8.9 11.966 11.966 0 00-3.512-8.748z" />
      </g>
    </svg>
  )
}

export default LifeJacket
