import styled from 'styled-components'

import {
  spacingSm,
  middleGray,
  borderRadius,
  slate,
  fontWeightLight,
  spacingMd,
  blue,
  red,
  offWhite,
  darkGray,
} from 'shared/lib/variables'

const InputStyled = styled.div`
  margin-top: ${spacingSm};
  position: relative;

  .input__wrapper {
    overflow-x: hidden;
    position: relative;
  }

  .input__control {
    appearance: none;
    background: white;
    border: 1px solid ${middleGray};
    border-radius: ${borderRadius};
    color: ${slate};
    font-family: 'Lato', sans-serif;
    font-weight: ${fontWeightLight};
    height: 60px;
    outline: 0;
    padding: 18px 0 0 ${spacingMd};
    width: 100%;

    &:focus {
      border: 1px solid ${blue};
    }

    &:focus + .input__label,
    + .input__label--has-value {
      pointer-events: none;
      text-transform: uppercase;
      transform: translateY(-10px) scale(0.625);
    }

    &:disabled {
      background: ${offWhite};
      color: ${darkGray};
    }

    &:focus::placeholder {
      opacity: 1;
    }

    &::placeholder {
      opacity: 0;
    }
  }

  &.input--error .input__control {
    border-color: ${red};
  }

  .input__label {
    color: ${darkGray};
    cursor: text;
    left: ${spacingMd};
    position: absolute;
    top: 16px;
    transform-origin: left center;
    transition: transform 0.2s;
    user-select: none;
    white-space: nowrap;
  }
`

export default InputStyled
