import React from 'react'

import RainbowImg from './rainbow.svg'

import Section from 'marketing/components/Section/Section'
import Highlight from 'marketing/components/Highlight/Highlight'

const CareersDiversityInclusion = (): JSX.Element => {
  return (
    <Section
      topContent={<img src={RainbowImg} />}
      h2="Diversity, Equity &amp; Inclusion"
      text={
        <>
          Wunder is committed to creating and maintaining an inclusive workplace in which all employees have an
          opportunity to participate and contribute to the success of the business.{' '}
          <Highlight>At Wunder, people are valued for their skills, experiences, and unique perspectives.</Highlight>{' '}
          Our commitment to equity is of course embodied in company policy, but it’s also evident every day in how we
          conduct business and the programs we provide.
        </>
      }
      divider
    />
  )
}

export default CareersDiversityInclusion
