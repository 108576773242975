import React from 'react'
import styled from 'styled-components'

import CompanySubnav from './Subnavs/CompanySubnav'
import SubnavItem from './Subnavs/SubnavItem'

import { supportPath } from 'marketing/helpers/routes'

interface Props {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
}

const MobileDrawerStyled = styled.div<{ isOpen: boolean }>((props) => ({
  backgroundColor: 'white',
  bottom: 0,
  left: 0,
  overflow: 'scroll',
  position: 'fixed',
  right: 0,
  top: 0,
  transform: props.isOpen ? 'translateX(0)' : 'translateX(100vw)',
  transition: 'all .15s ease-in',
  zIndex: props.theme.zIndex.header,

  '@media (min-width: 768px)': {
    display: 'none',
  },
}))

const CloseButton = styled.div<{ onClick: () => void }>((props) => ({
  height: 32,
  position: 'absolute',
  right: props.theme.spacing.medium,
  top: props.theme.spacing.medium,
  width: 32,

  // Create two bars and rotate them to make an X
  '::before, ::after': {
    backgroundColor: props.theme.colors.offBlack,
    content: "''",
    height: 32,
    left: 15,
    opacity: '.5',
    position: 'absolute',
    width: 2,
  },
  '::before': {
    transform: 'rotate(45deg)',
  },
  '::after': {
    transform: 'rotate(-45deg)',
  },
}))

const MobileDrawerLinks = styled.div((props) => ({
  marginTop: props.theme.spacing.xlarge,
}))

const MobileHeader = (props: Props): JSX.Element => {
  return (
    <MobileDrawerStyled isOpen={props.isOpen}>
      <CloseButton onClick={() => props.setIsOpen(false)} />

      <MobileDrawerLinks>
        <SubnavItem href={supportPath()} description="Support" />
        <CompanySubnav />
      </MobileDrawerLinks>
    </MobileDrawerStyled>
  )
}

export default MobileHeader
