import React from 'react'
import styled from 'styled-components'

import Anchor from 'marketing/components/Anchor/Anchor'

interface Props {
  to?: string
  href?: string
  children: React.ReactNode
}

const LinkWrapper = styled.div({
  marginTop: '15px',

  '& > a': {
    height: '25px',
    lineHeight: '25px',
  },
})

const FooterLink = (props: Props): JSX.Element => {
  return (
    <LinkWrapper>
      <Anchor href={props.href} to={props.to} underline>
        {props.children}
      </Anchor>
    </LinkWrapper>
  )
}

export default FooterLink
