import { schema } from 'normalizr'

export interface PressArticleInterface {
  id: number
  title: string
  publication: string
  href: string
  publishedAt: string
}

const pressArticleSchema = new schema.Entity('pressArticles')
export const pressArticlesSchema = [pressArticleSchema]
