import React from 'react'
import { Formik } from 'formik'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { toast } from 'react-toastify'

import Section from 'marketing/components/Section/Section'
import Container from 'marketing/components/Container/Container'
import FormWrapper from 'shared/components/Form/FormWrapper/FormWrapper'
import Input from 'shared/components/Form/Input/Input'
import Button from 'marketing/components/Button/Button'
import { RootState } from 'marketing/redux/store'
import { UserInterface } from 'shared/redux/user/interfaces'
import { selectIsLoading } from 'shared/redux/loading'
import { isSuccess } from 'shared/lib/utils'
import { setErrors } from 'shared/lib/formUtils'
import Anchor from 'marketing/components/Anchor/Anchor'
import { AUTHENTICATE_MFA, authenticateMFA, resendMFACode, RESEND_MFA_CODE } from 'shared/redux/user/effects'
import { afterSignInPath } from 'marketing/helpers/routes'
import { AppDispatch } from 'shared/redux/types'

interface Props {
  user?: UserInterface
  isLoading?: boolean
  isLoadingResend?: boolean
  dispatch?: AppDispatch
}

const Text = styled.p({
  textAlign: 'center',
})

const MultiFactorAuthenticationForm = (props: Props): JSX.Element => {
  const handleSubmit = async (values, form): Promise<void> => {
    const action = await props.dispatch(authenticateMFA(values))

    if (isSuccess(action)) {
      toast.success('Success!')
      window.location.assign(afterSignInPath())
    } else {
      setErrors(form, action.payload.errors)
    }
  }
  const handleResendCode = async (): Promise<void> => {
    const action = await props.dispatch(resendMFACode())
    if (isSuccess(action)) toast(`We sent a code to your ${props.user?.mfaEmailEnabled ? 'email' : 'phone'}.`)
  }

  return (
    <Section
      h1="Two Factor Authentication"
      text={
        props.user?.mfaDeviceEnabled
          ? 'Please enter the verification code found in your Google Authenticator App.'
          : `We sent a login code to your ${props.user?.mfaEmailEnabled ? 'email' : 'phone'}.`
      }
      data-section="mfa-form"
    >
      <Container maxWidth={400}>
        <Formik initialValues={{ otpCode: '' }} onSubmit={handleSubmit}>
          <FormWrapper>
            <Input name="otpCode" label="Authentication Code" autoFocus />

            <Button type="submit" block disabled={props.isLoading}>
              Submit
            </Button>
          </FormWrapper>
        </Formik>

        {props.isLoadingResend ? (
          <Text>Sending...</Text>
        ) : (
          <Text>
            {props.user?.mfaDeviceEnabled ? 'Text Code using SMS Instead: ' : "Didn't receive a code? "}
            <Anchor onClick={handleResendCode} isAlwaysColored underline>
              {props.user?.mfaDeviceEnabled ? 'Send' : 'Re-send'}
            </Anchor>
          </Text>
        )}
      </Container>
    </Section>
  )
}

const mapState = (state: RootState, ownProps: Props): Props => ({
  ...ownProps,
  isLoading: selectIsLoading(state, [AUTHENTICATE_MFA]),
  isLoadingResend: selectIsLoading(state, [RESEND_MFA_CODE]),
})

export default connect(mapState)(MultiFactorAuthenticationForm)
