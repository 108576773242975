import React from 'react'
import styled from 'styled-components'

import Container from 'marketing/components/Container/Container'

interface Props {
  children: React.ReactNode
}

const MediaCardsStyled = styled.div((props) => ({
  display: 'flex',
  flexWrap: 'wrap',
  margin: -props.theme.spacing.small,
}))

const MediaCards = (props: Props): JSX.Element => {
  return (
    <Container>
      <MediaCardsStyled>{props.children}</MediaCardsStyled>
    </Container>
  )
}

export default MediaCards
