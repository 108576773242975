import React from 'react'
import styled, { ThemeProvider } from 'styled-components'

import Anchor from 'marketing/components/Anchor/Anchor'
import PopUp from 'marketing/components/PopUp/PopUp'
import BlackstoneLogo from 'marketing/content/PressLogos/assets/BlackstoneLogo.png'
import BlackstoneLogo2x from 'marketing/content/PressLogos/assets/BlackstoneLogo@2x.png'
import ClearGenLogo from 'marketing/content/PressLogos/assets/ClearGenLogo.png'
import ClearGenLogo2x from 'marketing/content/PressLogos/assets/ClearGenLogo@2x.png'
import theme from 'marketing/components/Global/theme'

const Logos = styled.div((props) => ({
  alignItems: 'center',
  display: 'flex',
  marginBottom: props.theme.spacing.medium,
}))

const Spacer = styled.div((props) => ({
  backgroundColor: 'white',
  height: 44,
  margin: `0 ${props.theme.spacing.medium}px`,
  opacity: 0.3,
  width: 1,
}))

const LinkWrapper = styled.div((props) => ({
  marginTop: props.theme.spacing.xlarge,
}))

export default function BlackstonePressReleasePopup(): JSX.Element {
  const href =
    'https://urbanland.uli.org/sustainability/wunders-650-million-deal-could-boost-use-of-solar-in-commercial-and-industrial-buildings/'

  return (
    <ThemeProvider theme={{ ...theme, currentColor: theme.colors.offWhite }}>
      <PopUp>
        <Logos>
          <img src={BlackstoneLogo} srcSet={BlackstoneLogo + ' 1x,' + BlackstoneLogo2x + ' 2x'} />
          <Spacer />
          <img src={ClearGenLogo} srcSet={ClearGenLogo + ' 1x,' + ClearGenLogo2x + ' 2x'} />
        </Logos>
        <h3>
          <Anchor href={href} targetBlank underline>
            Wunder Announces Landmark $650 Million Commercial Solar Deal With Blackstone and Cleargen
          </Anchor>
        </h3>
        <LinkWrapper>
          <Anchor href={href} targetBlank underline>
            READ MORE AT ULI
          </Anchor>
        </LinkWrapper>
      </PopUp>
    </ThemeProvider>
  )
}
