import React from 'react'
import { RouteComponentProps } from '@reach/router'
import styled, { ThemeProvider } from 'styled-components'

import TemperatureGraph from './assets/temperature-graph.png'
import TemperatureGraph2x from './assets/temperature-graph@2x.png'
import PriceInstallations from './assets/price-installations.png'
import PriceInstallations2x from './assets/price-installations@2x.png'
import TotalMw from './assets/total-mw.png'
import TotalMw2x from './assets/total-mw@2x.png'
import SustainabilityImpact from './SustainabilityImpact'

import Header from 'marketing/components/Header/Header'
import theme from 'marketing/components/Global/theme'
import Footer from 'marketing/components/Footer/Footer'
import Section from 'marketing/components/Section/Section'
import Swirl from 'marketing/components/Swirl/Swirl'
import Highlight from 'marketing/components/Highlight/Highlight'
import SideBySide from 'marketing/components/SideBySide/SideBySide'
import PullUp from 'marketing/components/PullUp/PullUp'
import Boxes from 'marketing/components/Box/Boxes'
import Box from 'marketing/components/Box/Box'
import { calculateMarketingStats } from 'marketing/content/About/impactStats'
import Anchor from 'marketing/components/Anchor/Anchor'
import Container from 'marketing/components/Container/Container'
import Cards from 'marketing/components/Card/Cards'
import Card from 'marketing/components/Card/Card'
import { careersPath, supportPath, pressPath } from 'marketing/helpers/routes'
import Icon from 'marketing/components/Icon/Icon'
import useDocTitle from 'shared/lib/useDocTitle'

const Source = styled.span({
  display: 'inline-block',
  fontSize: '.875rem',
  fontWeight: 400,
  textTransform: 'uppercase',
})

const Sustainability: React.FC<RouteComponentProps> = (): JSX.Element => {
  useDocTitle('Sustainability - Wunder')

  const impactStats = calculateMarketingStats()

  return (
    <ThemeProvider theme={{ ...theme, currentColor: theme.colors.green }}>
      <Header />
      <Section
        h1="Keep America Cold"
        text={
          <>
            <Highlight>Whether you believe in climate change or not doesn’t matter,</Highlight> science has proven that
            it’s real either way. Many companies and individuals are beginning to recognize this and realize that it
            will result in catastrophic human, economic, and environmental fallout. Since 2013, we’ve made it our
            express purpose and mission to fight climate change.
          </>
        }
      />
      <Swirl>
        <PullUp distance={60}>
          <Boxes columns={4}>
            <Box title="2013" text="Year Founded" />
            <Box title={`${impactStats.wunderers} (+ dogs)`} text="Wunder-ers Currently" />
            <Box title={impactStats.totalMw} text="Built to Date" />
            <Box
              title={impactStats.lbsDisplaced}
              text={
                <>
                  CO<sub>2</sub> Displaced per year
                </>
              }
            />
          </Boxes>
        </PullUp>

        <Section h2="The Unfortunate Science" text="The planet is warming and it’s our fault." divider>
          <Container>
            <SideBySide>
              <img src={TemperatureGraph} srcSet={TemperatureGraph + ' 1x,' + TemperatureGraph2x + ' 2x'} />
              <>
                <h5>
                  More CO<sub>2</sub> = higher temperatures
                </h5>
                <p>
                  Since the dawn of the Industrial Age, the amount of greenhouse gas emissions put into the atmosphere
                  has been increasing dramatically.{' '}
                </p>
                <p>
                  As a result, our most sophisticated climate models are all in agreement; our planet will get
                  significantly warmer over the coming years. If left unchanged, our current CO2 emissions are expected
                  to lead to what the U.N has termed “unacceptable and irreversable harm.”{' '}
                  <Anchor href="https://www.wri.org/ipcc-infographics" isAlwaysColored targetBlank underline>
                    <Source>Source: IPCC</Source>
                  </Anchor>
                </p>
              </>
            </SideBySide>
          </Container>
        </Section>

        <Section
          h2="The Solar Solution"
          text="The good news is that as our need for clean renewable energy has risen, so too has solar’s ability to compete with traditional fossil fuels."
          divider
        >
          <Container>
            <SideBySide>
              <>
                <h5>Falling Solar Costs</h5>
                <p>
                  Since 1975, the cost of solar has dropped roughly 99.75%. This is due in large part to rapidly falling
                  hardware costs (e.g., panels and inverters), as well as more cost-efficient installations.
                </p>
                <h5>Rapid Solar Adoption</h5>
                <p>
                  In response to the dramatic cost reduction that we’ve seen, the solar market has grown more than
                  23,999,900%. Solar energy is now the cheapest energy option in many regions, even in the absence of
                  government subsidies.
                </p>
              </>
              <img src={PriceInstallations} srcSet={PriceInstallations + ' 1x,' + PriceInstallations2x + ' 2x'} />
            </SideBySide>
          </Container>
        </Section>
      </Swirl>

      <Section
        h2="Wunder's Progress"
        text="Some companies tout that they’re now “Carbon Neutral.” We are too… but on steroids. We’ve been focused exclusively on building commercial-scale solar projects since 2013, and we’re getting pretty good at it."
        divider
      >
        <Container>
          <SideBySide>
            <img src={TotalMw} srcSet={TotalMw + ' 1x,' + TotalMw2x + ' 2x'} />
            <>
              <h5>We Bring Efficiency and Capital</h5>
              <p>
                At Wunder we’re focused on realizing our clean energy future. We develop software that empowers solar
                professionals to build more solar, and we work with financial institutions to deploy hundreds of
                millions of dollars into new projects.
              </p>
              <p>
                Importantly, we focus on the commercial and industrial solar market, developing projects for businesses,
                municipalities, non-profits, and communities that - without Wunder - would have gone unbuilt.
              </p>
            </>
          </SideBySide>
        </Container>
      </Section>

      <SustainabilityImpact />

      <Section
        h2="Get Involved"
        text="We’re not building another app for teenagers or supporting another SaaS product for big corps. We’re pursuing one of the only tangible opportunities to truly save the world; we’re fighting climate change by pushing renewable energy forward… and we could use your help!"
        dark
        divider
        data-section="get-involved"
      >
        <Cards columns={3}>
          <Card
            to={careersPath()}
            icon={<Icon name="two-ovals" size={64} />}
            title="Join our Team"
            text="Do the most important work of your career… and life."
            linkText="View Openings"
          />
          <Card
            to={supportPath()}
            icon={<Icon name="two-squares" size={64} />}
            title="Partner with Wunder"
            text="Leverage our turn-key financing and development tools to build more."
            linkText="Get Support"
          />
          <Card
            to={pressPath()}
            icon={<Icon name="two-triangles" size={64} />}
            title="Invest with Wunder"
            text="Get diversified C&I solar exposure and while impacting the World."
            linkText="Visit Press Center"
          />
        </Cards>
      </Section>
      <Footer />
    </ThemeProvider>
  )
}

export default Sustainability
