import React from 'react'
import { RouteComponentProps } from '@reach/router'
import { ThemeProvider } from 'styled-components'

import SignupForm from './SignupForm'

import theme from 'marketing/components/Global/theme'
import GrayPage from 'marketing/components/GrayPage/GrayPage'
import HeaderMinimal from 'marketing/components/Header/HeaderMinimal'
import useDocTitle from 'shared/lib/useDocTitle'

const Signup: React.FC<RouteComponentProps> = (): JSX.Element => {
  useDocTitle('Sign Up - Wunder')

  return (
    <ThemeProvider theme={{ ...theme, currentColor: theme.colors.blue }}>
      <GrayPage>
        <HeaderMinimal />
        <SignupForm />
      </GrayPage>
    </ThemeProvider>
  )
}

export default Signup
