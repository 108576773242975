import React from 'react'

import FormSectionStyled from './FormSection.style'

interface Props {
  className?: string
  children: JSX.Element | JSX.Element[]
}

// Any number of inputs can be passed as children, and they'll be spaced out automatically
const FormSection = (props: Props): JSX.Element => {
  return <FormSectionStyled className={props.className}>{props.children}</FormSectionStyled>
}

export default FormSection
