import React from 'react'
import { RouteComponentProps } from '@reach/router'
import { ThemeProvider } from 'styled-components'

import Header from 'marketing/components/Header/Header'
import theme from 'marketing/components/Global/theme'
import Footer from 'marketing/components/Footer/Footer'
import Section from 'marketing/components/Section/Section'
import Swirl from 'marketing/components/Swirl/Swirl'
import Container from 'marketing/components/Container/Container'
import PullUp from 'marketing/components/PullUp/PullUp'
import Content from 'marketing/components/Content/Content'
import Anchor from 'marketing/components/Anchor/Anchor'
import {
  dwollaPrivacyPath,
  dwollaTermsPath,
  synapseTermsPath,
  synapsePrivacyPath,
  evolvePrivacyPath,
} from 'marketing/helpers/routes'
import useDocTitle from 'shared/lib/useDocTitle'

const ProviderTerms: React.FC<RouteComponentProps> = (): JSX.Element => {
  useDocTitle('Provider Terms - Wunder')

  return (
    <ThemeProvider theme={{ ...theme, currentColor: theme.colors.blue }}>
      <Header />
      <Section
        h1="Terms and Conditions of Wunder's Payment Services Providers"
        text="For the purposes of ensuring reliable transactions, Wunder maintains redundant third-party payment services providers. By using this Website, you agree to be bound by the Privacy Policies and the Terms of Service of Wunder’s third-party Payment Services Providers (listed below), as well as all disclaimers and terms and conditions that appear elsewhere on this Website."
        maxWidth={940}
      />
      <Swirl>
        <PullUp>
          <Container>
            <Content data-section="terms-content">
              <p>
                IF YOU DO NOT AGREE TO THESE POLICIES, YOU AGREE THAT YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE
                USING THE WEBSITE.
              </p>
              <p>
                Dwolla, Inc:{' '}
                <Anchor href={dwollaTermsPath()} targetBlank isAlwaysColored underline>
                  Terms of Service
                </Anchor>{' '}
                and{' '}
                <Anchor href={dwollaPrivacyPath()} targetBlank isAlwaysColored underline>
                  Privacy Policy
                </Anchor>
                <br />
                Synapse Financial Technologies, Inc.:{' '}
                <Anchor href={synapseTermsPath()} targetBlank isAlwaysColored underline>
                  Terms of Service
                </Anchor>{' '}
                ,{' '}
                <Anchor href={synapsePrivacyPath()} targetBlank isAlwaysColored underline>
                  Privacy Policy
                </Anchor>
                , and{' '}
                <Anchor href={evolvePrivacyPath()} targetBlank isAlwaysColored underline>
                  Financial Institution Partner Privacy Policy
                </Anchor>
              </p>
            </Content>
          </Container>
        </PullUp>
      </Swirl>
      <Footer />
    </ThemeProvider>
  )
}

export default ProviderTerms
