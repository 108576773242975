import React from 'react'
import { Form } from 'formik'
import styled from 'styled-components'

import { spacingSm } from 'shared/lib/variables'
import Errors from 'shared/components/Form/Errors/Errors'

export const DEFAULT_FORM_ID = 'default-form-id'

interface Props {
  id?: string
  method?: 'get' | 'post'
  children: JSX.Element | JSX.Element[]
}

const FormWrapperStyled = styled.form({
  lineHeight: '28px',
  marginTop: `-${spacingSm}`,
})

const Submit = styled.input({
  display: 'none',
})

const FormWrapper = ({ id = DEFAULT_FORM_ID, method, children }: Props): JSX.Element => {
  const noJsContent = `<style type="text/css">#${id} { display: none; }</style><h3 style="text-align: center">Please enable JavaScript to continue.</h3>`

  return (
    <>
      {/* Hide the form if the user doesn't have JS enabled */}
      <noscript dangerouslySetInnerHTML={{ __html: noJsContent }} />

      <FormWrapperStyled as={Form} id={id} method={method} className="form-wrapper">
        <Submit type="submit" />
        {children}
        <Errors name="base" />
      </FormWrapperStyled>
    </>
  )
}

export default FormWrapper
