import React, { PropsWithChildren, useState } from 'react'
import styled from 'styled-components'
import { rgba } from 'polished'

const Wrapper = styled.div((props) => ({
  backgroundColor: props.theme.colors.blue,
  borderRadius: props.theme.borderRadius,
  bottom: props.theme.spacing.medium,
  color: props.theme.colors.offWhite,
  left: props.theme.spacing.medium,
  maxWidth: 360,
  right: props.theme.spacing.medium,
  padding: props.theme.spacing.large,
  position: 'fixed',

  '@media (min-width: 768px)': {
    right: 'unset',
    width: '100%',
  },
}))

const CloseButton = styled.button((props) => ({
  background: 'transparent',
  border: 'none',
  borderRadius: props.theme.borderRadius,
  color: props.theme.colors.offWhite,
  cursor: 'pointer',
  fontSize: 25,
  opacity: 0.5,
  outline: 'none',
  padding: '5px 10px',
  position: 'absolute',
  right: props.theme.spacing.small,
  top: props.theme.spacing.small,
  transition: 'all 0.15s ease-in-out',

  '&:hover': {
    background: rgba(props.theme.colors.offBlack, 0.15),
    opacity: 1,
  },
}))

export default function Floater(props: PropsWithChildren<Record<never, never>>): JSX.Element {
  const [open, setOpen] = useState(true)
  const closePopUp = () => setOpen(false)

  if (!open) return null

  return (
    <Wrapper>
      <CloseButton onClick={closePopUp}>✕</CloseButton>
      {props.children}
    </Wrapper>
  )
}
