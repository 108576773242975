import React from 'react'
import styled, { keyframes } from 'styled-components'

const checkmark = keyframes`
  0% { stroke-dashoffset: 50px; }
  100% { stroke-dashoffset: 0; }
`

const checkmarkCircle = keyframes`
  0% { stroke-dashoffset: 240px; }
  100% { stroke-dashoffset: 480px; }
`

const Wrapper = styled.div`
  text-align: center;

  svg path {
    animation: ${checkmark} .25s ease-in-out .7s backwards;
  };

  svg circle {
    animation: ${checkmarkCircle} .6s ease-in-out backwards;
  };
})
`

const SuccessCheckmark = (): JSX.Element => {
  return (
    <Wrapper>
      <svg xmlns="http://www.w3.org/2000/svg" width="72px" height="72px">
        <g fill="none" stroke="#8EC343" strokeWidth="2">
          <circle
            cx="36"
            cy="36"
            r="35"
            style={{ strokeDasharray: '240px, 240px', strokeDashoffset: '480px' }}
          ></circle>
          <path
            d="M17.417,37.778l9.93,9.909l25.444-25.393"
            style={{ strokeDasharray: '50px, 50px', strokeDashoffset: '0px' }}
          ></path>
        </g>
      </svg>
    </Wrapper>
  )
}

export default SuccessCheckmark
