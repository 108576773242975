import React from 'react'

import { IndividualIconProps } from '../IconIndividualProps'

const ChevronDown = (props: IndividualIconProps): JSX.Element => {
  return (
    <svg viewBox="0 0 10 26" width={props.size} height={props.size}>
      <g fill="none" fillRule="evenodd">
        <g transform="translate(.5 .85)" stroke={props.color}>
          <path fillOpacity={props.fillOpacity} fill={props.color} strokeLinejoin="round" d="M2 12l5-4v16H2z" />
          <path strokeLinecap="round" d="M0 24h9" />
          <circle fillOpacity={props.fillOpacity} fill={props.color} cx="4.5" cy="2.5" r="2.5" />
        </g>
      </g>
    </svg>
  )
}

export default ChevronDown
