import styled from 'styled-components'

import Checked from './radio-checked.svg'
import Unchecked from './radio-unchecked.svg'

import { middleGray, spacingMd, spacingXl, spacingSm } from 'shared/lib/variables'

export const LabelText = styled.span({
  '&::before': {
    backgroundImage: `url(${Unchecked})`,
    content: "''",
    display: 'block',
    height: spacingMd,
    left: spacingMd,
    position: 'absolute',
    top: spacingMd,
    width: spacingMd,
  },
})

export const RadioStyled = styled.div({
  position: 'relative',

  '& + &': {
    borderTop: `1px solid ${middleGray}`,
  },
})

export const TextWrapper = styled.div({
  marginLeft: spacingMd,
})

export const LabelStyled = styled.label({
  alignItems: 'flex-start',
  cursor: 'pointer',
  display: 'flex',
  minHeight: 60,
  padding: `16px ${spacingXl} 16px ${spacingMd}`,
  position: 'relative',
  userSelect: 'none',

  input: {
    marginLeft: 3,
    marginTop: 7,
  },

  [`input:checked + div ${LabelText}::before`]: {
    backgroundImage: `url(${Checked})`,
  },
})

export const Description = styled.p(() => ({
  fontStyle: 'italic',
  margin: `${spacingSm} 0`,
}))
