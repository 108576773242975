import React from 'react'

import { IndividualIconProps } from '../IconIndividualProps'

const Hamburger = (props: IndividualIconProps): JSX.Element => {
  return (
    <svg viewBox="0 0 32 32" width={props.size} height={props.size}>
      <path
        d="M4 10h24a2 2 0 000-4H4a2 2 0 000 4zm24 4H4a2 2 0 000 4h24a2 2 0 000-4zm0 8H4a2 2 0 000 4h24a2 2 0 000-4z"
        fill={props.color}
      />
    </svg>
  )
}

export default Hamburger
