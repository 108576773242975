import 'react-toastify/dist/ReactToastify.css'

import 'client/components/shared/Toastify/Toastify.scss'

const toastConfig = {
  position: 'bottom-left',
  hideProgressBar: true,
  autoClose: 5000
}

export default toastConfig
