import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import Section from 'marketing/components/Section/Section'
import Cards from 'marketing/components/Card/Cards'
import Card from 'marketing/components/Card/Card'
import Container from 'marketing/components/Container/Container'
import Anchor from 'marketing/components/Anchor/Anchor'
import { RootState } from 'marketing/redux/store'
import { JobInterface } from 'marketing/redux/careers/jobs/interfaces'
import { getJobs } from 'marketing/redux/careers/jobs/effects'
import { selectAllJobs } from 'marketing/redux/careers/jobs/selectors'
import { DepartmentInterface } from 'marketing/redux/careers/departments/interfaces'
import { getDepartments } from 'marketing/redux/careers/departments/effects'
import { selectAllDepartments } from 'marketing/redux/careers/departments/selectors'
import { openRolesPath } from 'marketing/helpers/routes'
import { AppDispatch } from 'shared/redux/types'

interface Props {
  jobs?: JobInterface[]
  departments?: DepartmentInterface[]
  dispatch?: AppDispatch
}

const OpenRoles = (props: Props): JSX.Element => {
  useEffect((): void => {
    props.dispatch(getJobs())
    props.dispatch(getDepartments())
  }, [])

  return (
    <Section
      h2="Open Roles"
      text="We need the best in their fields to join the fight against climate change."
      data-section="open-roles"
      divider
    >
      <Container center>
        <Cards columns={3}>
          {[
            ...props.departments
              .filter((d) => d.jobs.length)
              .map((d) => (
                <Card
                  key={d.id}
                  to={openRolesPath(d.id)}
                  title={d.name}
                  subtitle={`${d.jobs.length} opening${d.jobs.length !== 1 ? 's' : ''}`}
                  emphasis={d.jobs.length > 0}
                />
              )),

            <Card
              key="all"
              to={openRolesPath()}
              title="View All"
              subtitle={`${props.jobs.length} opening${props.jobs.length !== 1 ? 's' : ''}`}
              emphasis
            />,
          ]}
        </Cards>

        <p>
          Don’t see a position that meets your unique skillset or background?
          <br />
          Send your resumé and cover letter to{' '}
          <Anchor href="mailto:hustle@wunderpower.com" isAlwaysColored targetBlank underline>
            <strong>hustle@wunderpower.com</strong>
          </Anchor>
        </p>
      </Container>
    </Section>
  )
}

function mapState(state: RootState): Props {
  return {
    jobs: selectAllJobs(state),
    departments: selectAllDepartments(state),
  }
}

export default connect(mapState)(OpenRoles)
