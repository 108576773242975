import React from 'react'
import styled from 'styled-components'

import Anchor from 'marketing/components/Anchor/Anchor'
import Icon, { Icons } from 'marketing/components/Icon/Icon'

interface Props {
  title?: string
  description?: string
  href?: string
  to?: string
  icon?: Icons
  arrow?: boolean
}

const StyledAnchor = styled
  .a((props) => ({
    display: 'flex',
    marginTop: props.theme.spacing.small / 2,
    padding: `${props.theme.spacing.small}px ${props.theme.spacing.medium}px`,

    '@media (min-width: 768px)': {
      padding: '8px 0',

      ':first-of-type': {
        marginTop: 0,
      },
    },
  }))
  .withComponent(Anchor)

const IconWrapper = styled.div<Props>((props) => ({
  display: 'none',

  '@media (min-width: 768px)': {
    display: 'flex',
    justifyContent: 'center',
    marginRight: props.theme.spacing.medium,
  },
}))

const Title = styled.h4((props) => ({
  color: props.theme.currentColor,
}))

const Description = styled.p({
  fontSize: '1.35rem',
  margin: 0,
  padding: '3px 0',
  width: 'max-content',

  '@media (min-width: 768px)': {
    fontSize: '1.125rem',
    lineHeight: '18px',
  },
})

const SubnavItem = (props: Props): JSX.Element => {
  return (
    <StyledAnchor href={props.href} to={props.to}>
      {props.icon && (
        <IconWrapper {...props}>
          <Icon name={props.icon} size={25} />
        </IconWrapper>
      )}

      {props.title && <Title>{props.title}</Title>}

      <Description>{props.description}</Description>
    </StyledAnchor>
  )
}

export default SubnavItem
