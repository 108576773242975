import React from 'react'

import { IndividualIconProps } from '../IconIndividualProps'

const At = (props: IndividualIconProps): JSX.Element => {
  return (
    <svg viewBox="0 0 24 25" width={props.size} height={props.size}>
      <g fill="none" fillRule="evenodd">
        <g transform="translate(.5 .85)" stroke={props.color}>
          <path
            d="M23 11.5C23 5.149 17.851 0 11.5 0S0 5.149 0 11.5 5.149 23 11.5 23c1.893 0 3.68-.458 5.255-1.268"
            strokeLinecap="round"
          />
          <path d="M17 13.5a3 3 0 006 0" />
          <circle fillOpacity={props.fillOpacity} fill={props.color} cx="11.5" cy="11.5" r="5.5" />
          <path d="M17 11.5v2M23 11.5v2" />
        </g>
      </g>
    </svg>
  )
}

export default At
