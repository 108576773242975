import React from 'react'
import emailMask from 'text-mask-addons/dist/emailMask'

import Input, { InputProps } from '../Input/Input'

const Email = (props: InputProps): JSX.Element => {
  return <Input {...props} mask={emailMask} />
}

export default Email
