import React from 'react'
import { RouteComponentProps } from '@reach/router'
import { ThemeProvider } from 'styled-components'

import Hero from './Hero'
import PressArticles from './PressArticles'

import theme from 'marketing/components/Global/theme'
import Header from 'marketing/components/Header/Header'
import Footer from 'marketing/components/Footer/Footer'
import useDocTitle from 'shared/lib/useDocTitle'

const Press: React.FC<RouteComponentProps> = (): JSX.Element => {
  useDocTitle('Press - Wunder')

  return (
    <ThemeProvider theme={{ ...theme, currentColor: theme.colors.purple }}>
      <Header />
      <Hero />
      <PressArticles />
      <Footer />
    </ThemeProvider>
  )
}

export default Press
