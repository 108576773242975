import { FormikHelpers } from 'formik'

import { AnyObject } from 'shared/lib/interfaces'

// Formik can't handle fields initially set to null, so we need to set all null fields to '', recursively
export const initializeValues = (obj: AnyObject): AnyObject => {
  return JSON.parse(JSON.stringify(obj, (k, v) => (v === null ? '' : v)))
}

// Convert an object to FormData. Useful for file uploads or anytime you need multipart/form-data form submissions
export const convertObjectToFormData = (object: AnyObject): FormData => {
  return Object.keys(object).reduce((formData, key) => {
    // If the key is a FileList object, each file needs to be set individually.
    // By convention, Rails only knows how to accept params as arrays if they're serialized as `name[]`
    if (object[key] instanceof FileList) {
      Array.from(object[key]).forEach((item: File) => formData.append(key + '[]', item))
    } else {
      formData.append(key, object[key])
    }

    return formData
  }, new FormData())
}

export const stripFormatting = (value: string, charsToReplace = /[ \-()%]/g): string => {
  return !value ? '' : value.replace(charsToReplace, '')
}

export const setErrors = (form: FormikHelpers<unknown>, errors: Record<string, unknown>): void => {
  form.setStatus(errors)
}
