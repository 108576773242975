import { useEffect } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'

import honeybadger from '../config/honeybadger'
import toastConfig from '../config/toastConfig'
import './helpers/hustleConsoleLog'

import { selectUser } from 'shared/redux/user/selectors'
import { UserInterface } from 'shared/redux/user/interfaces'
import { loadUser } from 'shared/redux/user/effects'
import { RootState } from 'marketing/redux/store'
import { AppDispatch } from 'shared/redux/types'

interface Props {
  user?: UserInterface
  flash?: {
    message?: string
    level?: string
  }
  children?: JSX.Element
  dispatch?: AppDispatch
}

const PageSetup: React.FC<Props> = (props: Props): JSX.Element => {
  useEffect((): void => {
    props.dispatch(loadUser())

    // Toast notifications
    toast.configure(toastConfig)
    if (props.flash?.message) toast(props.flash?.message)
  }, [])

  useEffect((): void => {
    if (typeof window === 'undefined') return
    honeybadger.setContext({
      // user_id and user_email are handled specially by HB, but it will also record any other data you throw in
      user_id: props.user?.id,
      user_email: props.user?.email,
      fullName: props.user?.fullName,
    })
  }, [props.user])

  return props.children || null
}

function mapState(state: RootState, ownProps: Props): Props {
  return {
    user: selectUser(state),
    flash: ownProps.flash,
  }
}

export default connect(mapState)(PageSetup)
