export interface UserInterface {
  id: number
  confirmedAt: string
  email: string
  encodedGid: string
  firstName: string
  fullName: string
  hasInvestment: boolean
  isBorrower: boolean
  isInvestor: boolean
  lastName: string
  legacyReservationAmount: number
  mfaEnabled: boolean
  mfaEmailEnabled: boolean
  mfaDeviceEnabled: boolean
  otpProvisioningQrCodeUrl?: string
  phoneConfirmedAt: string
  phoneNumberMasked: string
  primeContractorOnly: boolean
  unconfirmedEmail: string
}

export const BORROWER = 'borrower'
export const INVESTOR = 'investor'
