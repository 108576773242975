import styled from 'styled-components'

const FormSectionStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-left: -5px;
  margin-right: -5px;

  .input,
  .select,
  .checkbox-group,
  .radio-group {
    flex: 1 1 45%;
    margin-left: 5px;
    margin-right: 5px;

    @media only screen and (max-width: 768px) {
      flex-basis: 100%;
    }
  }
`
export default FormSectionStyled
