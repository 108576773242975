import React from 'react'
import styled from 'styled-components'

interface Props {
  center?: boolean
  children: React.ReactNode
  maxWidth?: number
  paddingTop?: boolean
  paddingBottom?: boolean
}

export const ContainerStyled = styled.div<Props>((props) => ({
  margin: '0 auto',
  maxWidth: props.maxWidth ? props.maxWidth : 1100,
  paddingLeft: props.theme.spacing.medium,
  paddingRight: props.theme.spacing.medium,
  paddingTop: props.paddingTop ? props.theme.spacing.xlarge : null,
  paddingBottom: props.paddingBottom ? props.theme.spacing.xlarge : null,
  textAlign: props.center ? 'center' : 'unset',
  width: '100%',

  // Nested containers don't need to add side padding
  [`& &, .section &`]: {
    paddingLeft: 0,
    paddingRight: 0,
  },

  '@media (min-width: 768px)': {
    paddingTop: props.paddingTop ? props.theme.spacing.xlarge * 2 : null,
    paddingBottom: props.paddingBottom ? props.theme.spacing.xlarge * 2 : null,
  },
}))

const Container = (props: Props): JSX.Element => {
  return <ContainerStyled {...props}>{props.children}</ContainerStyled>
}

export default Container
