import React from 'react'

import Img1 from './pictures/careers-1.jpg'
import Img2 from './pictures/careers-2.jpg'
import Img3 from './pictures/careers-3.jpg'
import Img4 from './pictures/careers-4.jpg'
import Img5 from './pictures/careers-5.jpg'
import Img6 from './pictures/careers-6.jpg'
import Img7 from './pictures/careers-7.jpg'

import ImageCollage from 'marketing/components/ImageCollage/ImageCollage'

const PictureCollage = (): JSX.Element => {
  const pictures = [Img1, Img2, Img3, Img4, Img5, Img6, Img7]

  return (
    <ImageCollage rows={2}>
      {pictures.map((img) => (
        <img src={img} key={img} />
      ))}
    </ImageCollage>
  )
}

export default PictureCollage
