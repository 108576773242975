import React from 'react'

import { IndividualIconProps } from '../IconIndividualProps'

const ChevronDown = (props: IndividualIconProps): JSX.Element => {
  return (
    <svg viewBox="0 0 10 6" width={props.size} height={props.size}>
      <path
        d="M1 1l4.005 4L9 1.009"
        stroke={props.color}
        strokeWidth="1.5"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default ChevronDown
