import React from 'react'

import { IndividualIconProps } from '../IconIndividualProps'

const Dollar = (props: IndividualIconProps): JSX.Element => {
  return (
    <svg viewBox="0 0 17 29" width={props.size} height={props.size}>
      <g fill="none" fillRule="evenodd">
        <g>
          <path
            d="M9.05 14.5H6.1c-3.093 0-5.6-2.239-5.6-5s2.507-5 5.6-5h5.6c2.651 0 4.8 1.919 4.8 4.286v.082M8.489 14.5H10.9c3.093 0 5.6 2.462 5.6 5.5s-2.507 5.5-5.6 5.5H5.3c-2.651 0-4.8-2.11-4.8-4.714h0M8.5 1.142v27"
            stroke={props.color}
            strokeLinecap="round"
          />
          <path
            d="M5.833 14.5H8.5v-10H5.833C2.888 4.5.5 6.739.5 9.5s2.388 5 5.333 5zM11.167 25.642H8.5v-11h2.667c2.945 0 5.333 2.463 5.333 5.5 0 3.038-2.388 5.5-5.333 5.5z"
            fillOpacity={props.fillOpacity}
            fill={props.color}
          />
        </g>
      </g>
    </svg>
  )
}

export default Dollar
