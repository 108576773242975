import React from 'react'
import { Field } from 'formik'
import { get, startCase } from 'lodash'
import MaskedInput from 'react-text-mask'

import InputStyled from './Input.style'

import Errors from 'shared/components/Form/Errors/Errors'
import Tooltip from 'shared/components/Form/Tooltip/Tooltip'
import useGetErrors from 'shared/components/Form/Errors/useGetErrors'

export interface InputProps {
  name: string
  label?: string
  placeholder?: string
  type?: string
  tooltip?: string | JSX.Element
  autoComplete?: string
  disabled?: boolean
  autoFocus?: boolean
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void
  errorsName?: string
  mask?: (string | RegExp)[] | ((value: unknown) => (string | RegExp)[])
  pipe?: (value: string, config: { rawValue: string }) => string
  onSaveTransform?: (value: unknown) => unknown
  afterChange?: (value: unknown) => unknown
}

// Only use MaskedInput if a mask is set
const InputElement = ({ mask, placeholderChar, ...rest }): JSX.Element => {
  return mask ? <MaskedInput mask={mask} placeholderChar={placeholderChar} {...rest} /> : <input {...rest} />
}

/**
 * This component can be used as is, and is also meant to be reused by other types of text inputs.
 * For example, `<Phone />` uses this component but passes in a `mask`.
 */
const Input = (props: InputProps): JSX.Element => {
  const errors = useGetErrors(props.errorsName || props.name)

  return (
    <Field name={props.name}>
      {({ form, field }): JSX.Element => (
        <InputStyled
          className={errors ? 'input input--error' : 'input'}
          data-disabled={props.disabled}
          data-input={props.name}
        >
          <div className="input__wrapper">
            <InputElement
              {...field}
              mask={props.mask || null}
              pipe={props.pipe || null}
              placeholderChar={props.mask ? '\u2000' : null}
              type={props.type || 'text'}
              name={props.name}
              id={props.name}
              placeholder={props.placeholder}
              className="input__control"
              autoFocus={props.autoFocus}
              autoComplete={props.autoComplete}
              disabled={props.disabled}
              onChange={(e): void => {
                form.setFieldValue(
                  props.name,
                  props.onSaveTransform ? props.onSaveTransform(e.target.value) : e.target.value,
                )
                if (props.afterChange) props.afterChange(e.target.value)
              }}
              onFocus={props.onFocus}
            />

            <label
              htmlFor={props.name}
              className={
                get(form.values, `[${props.name}]`) !== '' ? 'input__label input__label--has-value' : 'input__label'
              }
            >
              {props.label || startCase(props.name)}
            </label>
          </div>

          {props.tooltip && <Tooltip>{props.tooltip}</Tooltip>}

          <Errors name={props.errorsName || props.name} />
        </InputStyled>
      )}
    </Field>
  )
}

export default Input
