import React, { useEffect } from 'react'
import styled from 'styled-components'
import { rgba } from 'polished'
import { navigate } from '@reach/router'

import Anchor from 'marketing/components/Anchor/Anchor'

interface Props {
  parentPath?: string
  children?: React.ReactNode
}

const ModalWrapper = styled.div((props) => ({
  alignItems: 'center',
  bottom: 0,
  display: 'flex',
  justifyContent: 'center',
  left: 0,
  padding: props.theme.spacing.small,
  position: 'fixed',
  right: 0,
  top: 0,
  zIndex: props.theme.zIndex.modal,
}))

const Backdrop = styled.div((props) => ({
  backdropFilter: 'blur(4px)',
  background: rgba(props.theme.colors.offBlack, 0.4),
  bottom: 0,
  left: 0,
  position: 'absolute',
  right: 0,
  top: 0,
}))

const ModalContent = styled.div((props) => ({
  border: `1px solid ${props.theme.colors.middleGray}`,
  borderRadius: props.theme.borderRadius,
  maxWidth: 660,
  maxHeight: '80vh',
  overflowY: 'auto',
  position: 'relative',
  width: '100%',
}))

const CloseButton = styled
  .a<{ onClick: () => void }>((props) => ({
    cursor: 'pointer',
    height: 25,
    position: 'absolute',
    right: props.theme.spacing.medium,
    top: props.theme.spacing.medium,
    width: 25,

    // Create two bars and rotate them to make an X
    '::before, ::after': {
      backgroundColor: props.theme.colors.offBlack,
      content: "''",
      height: 25,
      left: 15,
      opacity: 0.5,
      position: 'absolute',
      width: 2,
    },
    '::before': {
      transform: 'rotate(45deg)',
    },
    '::after': {
      transform: 'rotate(-45deg)',
    },

    ':hover': {
      '::before, ::after': {
        opacity: 0.75,
      },
    },
  }))
  .withComponent(Anchor)

const Modal = ({ parentPath = '../', children, ...rest }: Props): JSX.Element => {
  // Prevent the page behind the modal from scrolling
  useEffect((): (() => void) => {
    document.body.classList.add('modal-open')
    return (): void => document.body.classList.remove('modal-open')
  }, [])

  const closeModal = (): Promise<void> => navigate(parentPath, { state: { noScroll: true } })

  return (
    <ModalWrapper {...rest}>
      <Backdrop onClick={closeModal} />
      <ModalContent>
        <CloseButton onClick={closeModal} data-action="close-modal" />
        {children}
      </ModalContent>
    </ModalWrapper>
  )
}

export default Modal
