import React from 'react'

import { IndividualIconProps } from '../IconIndividualProps'

const TwoSquares = (props: IndividualIconProps): JSX.Element => {
  return (
    <svg viewBox="0 0 65 65" width={props.size} height={props.size}>
      <g id="Company-Pages" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Careers-@2x-Copy-7" transform="translate(-963.000000, -5615.000000)">
          <g id="Group-26-Copy-4" transform="translate(961.000000, 5613.000000)" stroke={props.color}>
            <path
              d="M12.5336823,11.0889642 C16.6653679,6.9466941 23.3247646,9.90141973 35.1788202,9.90141973 C47.0328758,9.90141973 54.5032432,6.64302548 58.9225219,11.0889642 C63.3418006,15.5349029 60.1099917,23.648911 60.1099917,34.9388822 C60.1099917,46.2288534 63.0502856,53.3629821 58.9225219,57.4762888 C54.7947582,61.5895954 48.2044835,58.6638333 36.3271562,58.6638333 C24.4498288,58.6638333 16.9480062,61.8673393 12.5336823,57.4762888 C8.11935841,53.0852382 11.3462125,45.1363406 11.3462125,33.7762784 C11.3462125,22.4162162 8.40199677,15.2312343 12.5336823,11.0889642 Z"
              id="Rectangle-Copy-24"
              fillOpacity={props.fillOpacity}
              fill={props.color}
            ></path>
            <path
              d="M11.6935026,11.7070432 C15.8015749,7.58844108 22.4229122,10.5262841 34.20922,10.5262841 C45.9955278,10.5262841 53.423201,7.28650756 57.8172228,11.7070432 C62.2112446,16.1275788 58.997906,24.1952252 58.997906,35.4206881 C58.997906,46.646151 61.9213957,53.7395169 57.8172228,57.8293211 C53.7130499,61.9191252 47.1604396,59.0100802 35.3509931,59.0100802 C23.5415465,59.0100802 16.0825979,62.1952821 11.6935026,57.8293211 C7.30440719,53.46336 10.5128194,45.5598806 10.5128194,34.2647272 C10.5128194,22.9695738 7.58543023,15.8256453 11.6935026,11.7070432 Z"
              id="Rectangle-Copy-26"
              transform="translate(34.756421, 34.756456) rotate(240.000000) translate(-34.756421, -34.756456) "
            ></path>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default TwoSquares
