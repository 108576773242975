export const fontSizeBase = 16
export const fontSizeMobile = 14
export const fontSizeSmall = 12

// Weights
export const fontWeightBold = 700
export const fontWeightNormal = 400
export const fontWeightLight = 300

// Colors
export const white = '#fff'
export const black = '#000'
export const offWhite = '#fafafa'
export const mehGray = '#f6f6f6'
export const middleGray = '#e8e8e8'
export const darkGray = '#a6a6a6'
export const slate = '#47525d'
export const green = '#67d66c'
export const teal = '#62c7c8'
export const blue = '#5890ff'
export const orange = '#ff5c39'
export const red = '#f00'
export const yellow = '#d5a900'
export const tagOrange = '#f4b795'
export const tagPurple = '#a8a2f9'

// Metronic colors
export const metronicColors = {
  yellowSaffron: '#F4D03F',
  blue: '#3598dc',
  red: '#e7505a',
  greenTurquoise: '#36D7B7',
  purple: '#8E44AD',
}

// Alpha colors
export const blue15a = `${blue}26`

// Box-shadow
export const boxShadow = '0 10px 15px rgba(0, 0, 0, .15)'

// Dimensions
export const borderRadius = '4px'
export const inputHeight = '60px'

// Spacing
export const spacingSm = '10px'
export const spacingMd = '20px'
export const spacingLg = '30px'
export const spacingXl = '70px'

// Filters
export const slateFilter = 'invert(31%) sepia(3%) saturate(3592%) hue-rotate(170deg) brightness(84%) contrast(76%)'
export const darkGrayFilter = 'invert(60%) sepia(52%) saturate(0%) hue-rotate(237deg) brightness(99%) contrast(92%)'
export const whiteFilter = 'invert(100%)'
export const blueFilter = 'invert(46%) sepia(89%) saturate(557%) hue-rotate(189deg) brightness(101%) contrast(101%)'

// Z-index
export const zIndex = {
  progressBar: 10500,
  flash: 10000,
  modalContent: 9001,
  modal: 9000,
  navigationClose: 8002,
  navigationOverlay: 8001,
  navigation: 8000,
  header: 7500,
  banner: 7500,
  dropdown: 7000,
  tooltip: 6000,
  iconTrail: 500,
  formSelect: 10,
  negative: -1,
}
