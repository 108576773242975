import React from 'react'
import { Formik } from 'formik'
import { connect } from 'react-redux'
import styled from 'styled-components'
import * as Yup from 'yup'

import Section from 'marketing/components/Section/Section'
import Container from 'marketing/components/Container/Container'
import FormWrapper from 'shared/components/Form/FormWrapper/FormWrapper'
import FormSection from 'shared/components/Form/FormSection/FormSection'
import Input from 'shared/components/Form/Input/Input'
import Password from 'shared/components/Form/Password/Password'
import Button from 'marketing/components/Button/Button'
import { RootState } from 'marketing/redux/store'
import { selectIsLoading } from 'shared/redux/loading'
import { createUser, CREATE_USER } from 'shared/redux/user/effects'
import { isSuccess } from 'shared/lib/utils'
import { setErrors } from 'shared/lib/formUtils'
import Email from 'shared/components/Form/Email/Email'
import Radio from 'shared/components/Form/Radio/Radio'
import RadioGroup from 'shared/components/Form/Radio/RadioGroup'
import Anchor from 'marketing/components/Anchor/Anchor'
import { loginPath, borrowerConfirmEmailPath, homePath } from 'marketing/helpers/routes'
import { BORROWER, INVESTOR } from 'shared/redux/user/interfaces'
import { AppDispatch } from 'shared/redux/types'

interface Props {
  isLoading?: boolean
  dispatch?: AppDispatch
}

const Text = styled.p({
  textAlign: 'center',
})

const SignupForm = (props: Props): JSX.Element => {
  const handleSubmit = async (values, form): Promise<void> => {
    const action = await props.dispatch(createUser(values))

    isSuccess(action) ? window.location.assign(borrowerConfirmEmailPath()) : setErrors(form, action.payload.errors)
  }
  const validation = Yup.object().shape({
    firstName: Yup.string().required('Required'),
    lastName: Yup.string().required('Required'),
    organizationLegalName: Yup.string().required('Required'),
    email: Yup.string().required('Required'),
    password: Yup.string().required('Required'),
  })

  return (
    <Section h1="Sign Up" text="To get started, tell us about yourself…">
      <Container maxWidth={400}>
        <Formik
          initialValues={{
            userType: BORROWER,
            firstName: '',
            lastName: '',
            organizationLegalName: '',
            email: '',
            password: '',
          }}
          onSubmit={handleSubmit}
          validationSchema={validation}
        >
          {({ values }): React.ReactNode => (
            <FormWrapper method="post">
              <RadioGroup name="userType">
                <Radio
                  value={BORROWER}
                  title="I'm a Solar Professional"
                  tooltip="I'm interested in securing financing for a commercial-scale solar project in the United States."
                />
                <Radio value={INVESTOR} title="I'm an Investor interested in Solar" />
              </RadioGroup>

              {values.userType === BORROWER && (
                <>
                  <FormSection>
                    <Input name="firstName" />
                    <Input name="lastName" />
                  </FormSection>

                  <Input name="organizationLegalName" />
                  <Email name="email" />
                  <Password name="password" />

                  <Button type="submit" block disabled={props.isLoading}>
                    Submit
                  </Button>
                </>
              )}

              {values.userType === INVESTOR && (
                <>
                  <Text>
                    Unfortunately we’re not accepting new individual investors at this time. We will reach out to you if
                    and when this changes.
                  </Text>
                  <Button to={homePath()} block>
                    Return to homepage
                  </Button>
                </>
              )}
            </FormWrapper>
          )}
        </Formik>
        <Text>
          Already have a Wunder account?{' '}
          <Anchor to={loginPath()} isAlwaysColored underline>
            Log In
          </Anchor>
        </Text>
      </Container>
    </Section>
  )
}

const mapState = (state: RootState): Props => ({
  isLoading: selectIsLoading(state, [CREATE_USER]),
})

export default connect(mapState)(SignupForm)
