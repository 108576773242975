import React from 'react'

import { IndividualIconProps } from '../IconIndividualProps'

const Gas = (props: IndividualIconProps): JSX.Element => {
  return (
    <svg viewBox="0 0 34 31" width={props.size} height={props.size}>
      <g stroke={props.color} strokeWidth={props.strokeWidth} fill="none" fillRule="evenodd">
        <path d="M28 12.07V26.9c0 1.175-.985 2.129-2.2 2.129H20V18h-6v11.029H8.2c-1.215 0-2.2-.954-2.2-2.13V12.03" />
        <g strokeLinecap="round">
          <path d="M1.5 16.5L17 1M32.5 16.5L17 1" />
        </g>
      </g>
    </svg>
  )
}

export default Gas
