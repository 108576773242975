import React, { useRef, useEffect } from 'react'
import styled from 'styled-components'
import { chunk } from 'lodash'

interface Props {
  rows?: number
  children: JSX.Element[]
}

const Row = styled.div((props) => ({
  display: 'flex',
  justifyContent: 'center',
  marginRight: 38,
  width: '100%',

  '& > img': {
    marginTop: props.theme.spacing.medium,

    '+ img': {
      marginLeft: props.theme.spacing.medium,
    },
  },
}))

const Inner = styled.div((props) => ({
  marginTop: -props.theme.spacing.medium,
  minWidth: '100%',
  width: 'max-content',
}))

const PicturesSlider = styled.div((props) => ({
  overflowX: 'scroll',

  [`${Inner} > img`]: {
    flex: '0 0 auto',
    marginTop: props.theme.spacing.medium,

    '+ img': {
      marginLeft: props.theme.spacing.medium,
    },
  },
}))

const ImageCollage = ({ rows = 1, children }: Props): JSX.Element => {
  const sliderRef = useRef(null)
  const innerRef = useRef(null)
  const chunks = chunk(children, Math.ceil(children.length / rows))

  useEffect(() => {
    // Scroll to the midpoint after images are loaded
    if (typeof window !== 'undefined') {
      try {
        const observer = new ResizeObserver(scrollToMidPoint)
        observer.observe(innerRef.current)

        return (): void => observer.unobserve(innerRef.current)
      } catch {
        // This is pretty safe to swallow if it errors
        // Browser support is decent, and if it's not available, it just won't scroll to the midpoint
      }
    }
  }, [])

  const scrollToMidPoint = (): void => {
    const scrollTo = Math.max((innerRef.current.scrollWidth - window.innerWidth) / 2, 0)
    sliderRef.current.scrollTo(scrollTo, 0)
  }

  return (
    <PicturesSlider ref={sliderRef}>
      <Inner ref={innerRef}>
        {chunks.map((chunk, i) => (
          <Row key={i}>{chunk}</Row>
        ))}
      </Inner>
    </PicturesSlider>
  )
}

export default ImageCollage
