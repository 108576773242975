import React from 'react'

import Section from 'marketing/components/Section/Section'
import Swirl from 'marketing/components/Swirl/Swirl'
import AboutUsBoxes from 'marketing/content/About/AboutUsBoxes'
import Boxes from 'marketing/components/Box/Boxes'
import Box from 'marketing/components/Box/Box'

const CareersWeInvest = (): JSX.Element => {
  return (
    <Swirl>
      <AboutUsBoxes />
      <Section
        h2="We Invest in Our People"
        text="Your job impacts nearly every facet of your life. We’re dedicated to ensuring that — at Wunder — it’s a
            positive impact, both in and out of the office. We’re continuously developing and improving programs to
            enrich the careers and personal lives of our coworkers."
        divider
      >
        <Boxes columns={3}>
          <Box title="Personal Growth" text="Support includes a generous professional development budget " />
          <Box title="Full Benefits" text="Top-notch medical, dental, vision, life, disability, and 401k benefits" />
          <Box title="Flexible Vacation" text="There are many great reasons to be “OOO,” and we support them" />
          <Box title="Equity for All" text="Everyone should feel ownership over their work — literally" />
          <Box title="Meals + Retreats + HH’s" text="Join a group that pulls — and lets loose — together, frequently" />
          <Box title="Unlimited Books" text="Wunder’s library budget is limitless, buy whatever you want" />
          <Box title="Family-Friendly" text="Success at work shouldn’t mean sacrifice at home" />
          <Box title="K9’s can ‘Come’" text="Your dog’s welcome to “support operations” in our offices" />
          <Box title="Hybrid Flexibility" text="Most team members come into our Boulder office 2-3 times a week" />
        </Boxes>
      </Section>
    </Swirl>
  )
}

export default CareersWeInvest
