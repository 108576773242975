import React from 'react'

import { IndividualIconProps } from '../IconIndividualProps'

const Briefcase = (props: IndividualIconProps): JSX.Element => {
  return (
    <svg viewBox="0 0 24 24" width={props.size} height={props.size}>
      <g fill="none" fillRule="evenodd">
        <g stroke={props.color}>
          <path
            d="M.5 18.02H.499v-.205L.5 6.35A1.5 1.5 0 012 4.85h20a1.5 1.5 0 011.5 1.5l.001 11.92-.002.001.001 3.079a1.5 1.5 0 01-1.5 1.5H2a1.5 1.5 0 01-1.5-1.5v-3.33z"
            fillOpacity={props.fillOpacity}
            fill={props.color}
          />
          <path d="M8.5.85h7a1 1 0 011 1v3h0-9v-3a1 1 0 011-1z" />
          <path strokeLinecap="round" d="M4.5 8.85v10M19.5 8.85v10" />
        </g>
      </g>
    </svg>
  )
}

export default Briefcase
