import { departmentsSchema } from './interfaces'

import { apiAction, ApiAction } from 'shared/redux/apiMiddleware'
import { apiDepartmentsPath } from 'marketing/helpers/greenhouseApiRoutes'

export const GET_DEPARTMENTS = 'getDepartments'

export function getDepartments(): ApiAction {
  return apiAction({
    type: GET_DEPARTMENTS,
    url: apiDepartmentsPath(),
    schema: departmentsSchema,
    transformCase: false,
  })
}
