import React from 'react'
import styled from 'styled-components'

import TechCrunch from './assets/techcrunch.png'
import TechCrunch2x from './assets/techcrunch@2x.png'
import Wsj from './assets/wsj.png'
import Wsj2x from './assets/wsj@2x.png'
import Forbes from './assets/forbes.png'
import Forbes2x from './assets/forbes@2x.png'
import Bloomberg from './assets/bloomberg.png'
import Bloomberg2x from './assets/bloomberg@2x.png'
import Gtm from './assets/gtm.png'
import Gtm2x from './assets/gtm@2x.png'

import PullUp from 'marketing/components/PullUp/PullUp'
import Container from 'marketing/components/Container/Container'
import Boxes from 'marketing/components/Box/Boxes'
import Box from 'marketing/components/Box/Box'

const BoxStyled = styled
  .div((props) => ({
    alignContent: 'center',

    '& img': {
      display: 'block',
      margin: `${props.theme.spacing.large}px auto`,
    },

    '@media (min-width: 768px)': {
      alignItems: 'center',
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-evenly',
      padding: 0,

      '& img': {
        margin: 0,
      },
    },
  }))
  .withComponent(Box)

const PressLogos = (): JSX.Element => {
  return (
    <PullUp distance={60}>
      <Container>
        <Boxes columns={1}>
          <BoxStyled>
            <img src={TechCrunch} srcSet={TechCrunch + ' 1x,' + TechCrunch2x + ' 2x'} />
            <img src={Wsj} srcSet={Wsj + ' 1x,' + Wsj2x + ' 2x'} />
            <img src={Forbes} srcSet={Forbes + ' 1x,' + Forbes2x + ' 2x'} />
            <img src={Bloomberg} srcSet={Bloomberg + ' 1x,' + Bloomberg2x + ' 2x'} />
            <img src={Gtm} srcSet={Gtm + ' 1x,' + Gtm2x + ' 2x'} />
          </BoxStyled>
        </Boxes>
      </Container>
    </PullUp>
  )
}

export default PressLogos
