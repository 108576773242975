import React from 'react'
import styled from 'styled-components'
import { chunk } from 'lodash'

import Container from 'marketing/components/Container/Container'

interface Props {
  columns: number
  open?: boolean
  children: JSX.Element | JSX.Element[]
}

const Row = styled.div<Props>((props) => ({
  backgroundColor: props.open ? null : 'white',
  margin: `0 ${props.theme.spacing.medium}px`,

  ':first-of-type': {
    borderTopLeftRadius: props.theme.borderRadius,
    borderTopRightRadius: props.theme.borderRadius,
  },

  ':last-child': {
    borderBottomLeftRadius: props.theme.borderRadius,
    borderBottomRightRadius: props.theme.borderRadius,
    borderBottom: 'none',
  },

  '@media (min-width: 768px)': {
    border: props.open ? 'none' : `1px solid ${props.theme.colors.middleGray}`,
    borderBottom: 0,
    display: 'flex',
    flexWrap: 'wrap',
    margin: 0,

    ':not(:first-of-type)': {
      borderTop: `1px solid ${props.theme.colors.middleGray}`,
    },

    ':last-child': {
      borderBottom: props.open ? 'none' : `1px solid ${props.theme.colors.middleGray}`,
    },

    '& > div': {
      flexBasis: `${100 / props.columns}%`,
    },
  },
}))

const Boxes = (props: Props): JSX.Element => {
  const children = Array.isArray(props.children) ? props.children : [props.children]
  const rows = chunk(children, props.columns)

  return (
    <Container>
      {rows.map((row, i) => (
        <Row key={i} {...props}>
          {row}
        </Row>
      ))}
    </Container>
  )
}

export default Boxes
