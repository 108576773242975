import React from 'react'
import styled from 'styled-components'
import { rgba } from 'polished'

interface Props {
  children: React.ReactNode
}

const HighlightStyled = styled.span((props) => ({
  background: rgba(props.theme.currentColor, 0.2),
  borderRadius: props.theme.borderRadius,
  boxShadow: `5px 0 0 ${rgba(props.theme.currentColor, 0.2)}, -5px 0 0 ${rgba(props.theme.currentColor, 0.2)}`,
  display: 'inline',
  margin: 5,
  padding: '3px 0',

  '& strong': {
    color: props.theme.currentColor,
  },
}))

/**
 * Highlight text inside a `<span>`
 */
const Highlight = (props: Props): JSX.Element => {
  return <HighlightStyled>{props.children}</HighlightStyled>
}

export default Highlight
