import React from 'react'
import styled from 'styled-components'

interface Props {
  children?: React.ReactNode
}

const ModalFooterStyled = styled.div((props) => ({
  backgroundColor: 'white',
  padding: props.theme.spacing.medium,
}))

const ModalFooter = ({ children, ...rest }: Props): JSX.Element => {
  return <ModalFooterStyled {...rest}>{children}</ModalFooterStyled>
}

export default ModalFooter
