import React, { useEffect, useState } from 'react'
import { PopupModal } from 'react-calendly'

import Button from 'marketing/components/Button/Button'

interface Props {
  buttonText: string
}

const CalendlyPopup = (props: Props): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false)
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    setMounted(true)
  }, [])

  if (typeof document === 'undefined') {
    return null
  }

  return (
    mounted && (
      <div>
        <Button onClick={() => setIsOpen(true)}>{props.buttonText}</Button>
        <PopupModal
          url="https://calendly.com/danewestwick/20min-1"
          onModalClose={() => setIsOpen(false)}
          open={isOpen}
          rootElement={document.getElementById('calendly-pop-container')}
        />
      </div>
    )
  )
}

export default CalendlyPopup
