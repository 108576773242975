import React from 'react'
import { RouteComponentProps } from '@reach/router'
import { ThemeProvider } from 'styled-components'

import CareersHero from './CareersHero'
import CareersWeInvest from './CareersWeInvest'
import PictureCollage from './PictureCollage'
import CareersDiversityInclusion from './CareersDiversityInclusion'
import OpenRoles from './OpenRoles'

import Header from 'marketing/components/Header/Header'
import theme from 'marketing/components/Global/theme'
import ImpactSection from 'marketing/content/About/ImpactSection'
import Swirl from 'marketing/components/Swirl/Swirl'
import OperatingPrinciples from 'marketing/content/About/OperatingPrinciples/OperatingPrinciples'
import LearnMore from 'marketing/content/About/LearnMore'
import Footer from 'marketing/components/Footer/Footer'
import useDocTitle from 'shared/lib/useDocTitle'

const Careers: React.FC<RouteComponentProps> = (): JSX.Element => {
  useDocTitle('Careers - Wunder')

  return (
    <ThemeProvider theme={{ ...theme, currentColor: theme.colors.blue }}>
      <Header />
      <CareersHero />
      <CareersWeInvest />
      <ImpactSection />
      <PictureCollage />
      <CareersDiversityInclusion />
      <Swirl direction="to bottom">
        <OperatingPrinciples />
        <OpenRoles />
        <LearnMore cards={['about', 'press']} />
      </Swirl>
      <Footer />
    </ThemeProvider>
  )
}

export default Careers
