import React from 'react'

import { IndividualIconProps } from '../IconIndividualProps'

const Planet = (props: IndividualIconProps): JSX.Element => {
  return (
    <>
      <svg viewBox="0 0 25 26" width={props.size} height={props.size}>
        <g fill="none" fillRule="evenodd">
          <g transform="translate(0 1.35)" stroke={props.color}>
            <circle fillOpacity={props.fillOpacity} fill={props.color} cx="12.5" cy="11.5" r="12" />
            <path d="M.5 11.5h24M2 5.5h21M2 17.5h20.604" />
          </g>
        </g>
      </svg>
    </>
  )
}

export default Planet
