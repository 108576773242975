import React from 'react'
import styled from 'styled-components'
import { rgba } from 'polished'

import SwirlImg from './swirl-tile.png'

interface Props {
  direction?: 'to bottom' | 'to top' | 'bi'
  children?: React.ReactNode
}

const SwirlStyled = styled.section<Props>((props) => {
  const background =
    props.direction === 'bi'
      ? `linear-gradient(to bottom, ${rgba(props.theme.currentColor, 0.1)}, white 35% 65%, ${rgba(
          props.theme.currentColor,
          0.1,
        )}), url(${SwirlImg})`
      : `linear-gradient(${props.direction}, white, ${rgba(props.theme.currentColor, 0.1)}), url(${SwirlImg})`

  return {
    background: background,
    backgroundRepeat: 'repeat',
    position: 'relative',
  }
})

const Swirl = ({ direction = 'to top', children }: Props): JSX.Element => {
  return <SwirlStyled direction={direction}>{children}</SwirlStyled>
}

export default Swirl
