import React from 'react'

import { openRolesPath } from 'marketing/helpers/routes'
import Section from 'marketing/components/Section/Section'
import Highlight from 'marketing/components/Highlight/Highlight'
import Button from 'marketing/components/Button/Button'

const CareersHero = (): JSX.Element => {
  return (
    <Section
      h1={
        <>
          Do the most <em>important</em>
          <br />
          work of your career… and life.
        </>
      }
      text={
        <>
          We’re not building another app for teenagers or supporting another SaaS product for big corps.{' '}
          <Highlight>We’re pursuing one of the only tangible opportunities to truly save the world;</Highlight> we’re
          fighting climate change by pushing renewable energy forward… and we could use your help!
        </>
      }
      data-section="careers-hero"
    >
      <Button to={openRolesPath()}>View Openings</Button>
    </Section>
  )
}

export default CareersHero
