import { BORROWER } from './interfaces'

import { apiAction, ApiAction } from 'shared/redux/apiMiddleware'
import {
  apiOtpDevicePath,
  apiUserPath,
  resendEmailConfirmationPath,
  apiPhoneConfirmationPath,
  apiDisableMfaPath,
} from 'client/lib/borrower/routes'
import { apiLoginPath, apiPasswordPath, apiMfaPath, apiUserInvitationPath } from 'marketing/helpers/routes'

export const CREATE_USER = 'shared/User/CreateUser'
export const ACCEPT_INVITATION = 'shared/User/AcceptInvitation'
export const LOG_IN = 'shared/User/LogIn'
export const RESET_PASSWORD = 'shared/user/ResetPassword'
export const CHANGE_PASSWORD = 'shared/user/ChangePassword'
export const AUTHENTICATE_MFA = 'shared/user/AuthenticateMfa'
export const RESEND_MFA_CODE = 'shared/user/ResendMfaCode'
export const GET_USER = 'shared/User/GET'
export const UPDATE_USER = 'shared/User/UPDATE'
export const RESEND_EMAIL_CONFIRMATION = 'shared/EmailConfirmation/RESEND'
export const CONFIRM_PHONE = 'shared/User/PhoneConfirmation'
export const DISABLE_MFA = 'shared/User/DisableMfa'
export const GET_OTP_DEVICE = 'shared/User/GetOtpDevice'
export const ENABLE_MFA_DEVICE = 'shared/EnableMfaDevice'

export function createUser(values: Record<string, unknown>): ApiAction {
  const userType = values.userType === BORROWER ? { isBorrower: true } : null

  return apiAction({
    type: CREATE_USER,
    url: apiUserPath(),
    method: 'POST',
    data: { user: { ...userType, ...values } },
  })
}

export function acceptInvitation(values: Record<string, unknown>): ApiAction {
  return apiAction({
    type: ACCEPT_INVITATION,
    url: apiUserInvitationPath(),
    method: 'PUT',
    data: { user: values },
  })
}

export function logIn(values: Record<string, unknown>): ApiAction {
  return apiAction({
    type: LOG_IN,
    url: apiLoginPath(),
    method: 'POST',
    data: { user: values },
  })
}

export function resetPassword(values: Record<string, unknown>): ApiAction {
  return apiAction({
    type: RESET_PASSWORD,
    url: apiPasswordPath(),
    method: 'POST',
    data: { user: values },
  })
}

export function changePassword(values: Record<string, unknown>): ApiAction {
  return apiAction({
    type: CHANGE_PASSWORD,
    url: apiPasswordPath(),
    method: 'PUT',
    data: { user: values },
  })
}

export function authenticateMFA(values: Record<string, unknown>): ApiAction {
  return apiAction({
    type: AUTHENTICATE_MFA,
    url: apiMfaPath(),
    method: 'PUT',
    data: { user: values },
  })
}

export function resendMFACode(): ApiAction {
  return apiAction({
    type: RESEND_MFA_CODE,
    url: apiMfaPath(),
    method: 'POST',
  })
}

export function loadUser(): ApiAction {
  return apiAction({
    type: GET_USER,
    url: apiUserPath(),
    method: 'GET',
  })
}

export function updateUser(user: Record<string, unknown>): ApiAction {
  return apiAction({
    type: UPDATE_USER,
    url: apiUserPath(),
    method: 'PUT',
    data: user,
  })
}

export function resendEmailConfirmation(): ApiAction {
  return apiAction({
    type: RESEND_EMAIL_CONFIRMATION,
    url: resendEmailConfirmationPath(),
    method: 'POST',
  })
}

export function confirmPhone(values: Record<string, unknown>): ApiAction {
  return apiAction({
    type: CONFIRM_PHONE,
    url: apiPhoneConfirmationPath(),
    // Adding and verifying phone numbers use the same route, but different verbs
    method: values.phoneNumber ? 'POST' : 'PUT',
    data: { phoneConfirmation: { ...values } },
  })
}

export function disableMfa(values: Record<string, unknown>): ApiAction {
  return apiAction({
    type: DISABLE_MFA,
    url: apiDisableMfaPath(),
    method: 'POST',
    data: values,
  })
}

export function getOtpDevice(): ApiAction {
  return apiAction({
    type: GET_OTP_DEVICE,
    url: apiOtpDevicePath(),
    method: 'GET',
  })
}

export function enableMfaDevice(values: Record<string, unknown>): ApiAction {
  return apiAction({
    type: ENABLE_MFA_DEVICE,
    url: apiOtpDevicePath(),
    method: 'PUT',
    data: values,
  })
}
