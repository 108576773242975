import { merge } from 'lodash'
import { AnyAction } from 'redux'

import { GET_JOBS } from './effects'

import { getSuccessAction } from 'shared/redux/actionHelpers'

export function jobsReducer(state = {}, action: AnyAction): Record<string, unknown> {
  switch (action.type) {
    case getSuccessAction(GET_JOBS):
      return merge({}, state, action.payload.entities.jobs)
    default:
      return state
  }
}
