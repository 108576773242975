/* eslint-disable max-lines */
import React from 'react'
import { RouteComponentProps } from '@reach/router'
import { ThemeProvider } from 'styled-components'

import Header from 'marketing/components/Header/Header'
import theme from 'marketing/components/Global/theme'
import Footer from 'marketing/components/Footer/Footer'
import Section from 'marketing/components/Section/Section'
import Swirl from 'marketing/components/Swirl/Swirl'
import Container from 'marketing/components/Container/Container'
import PullUp from 'marketing/components/PullUp/PullUp'
import Content from 'marketing/components/Content/Content'
import { termsPath } from 'marketing/helpers/routes'
import Anchor from 'marketing/components/Anchor/Anchor'
import useDocTitle from 'shared/lib/useDocTitle'

const Privacy: React.FC<RouteComponentProps> = (): JSX.Element => {
  useDocTitle('Privacy - Wunder')

  return (
    <ThemeProvider theme={{ ...theme, currentColor: theme.colors.blue }}>
      <Header />
      <Section
        h1="Privacy Policy"
        text="We know that you care about how your information is used, and we take your privacy very seriously. By visiting Wunder’s website, signing up with Wunder or using any Wunder services, you are accepting and agreeing to comply with this policy."
      />
      <Swirl>
        <PullUp>
          <Container>
            <Content data-section="privacy-content">
              <p>
                Notice: By using this Website, you agree to be bound by this Privacy Policy, the{' '}
                <Anchor to={termsPath()} isAlwaysColored underline>
                  Terms of Use
                </Anchor>{' '}
                and all disclaimers and terms and conditions that appear elsewhere on this Website.
              </p>
              <p>
                IF YOU DO NOT AGREE TO THIS PRIVACY POLICY, YOU AGREE THAT YOUR SOLE AND EXCLUSIVE REMEDY IS TO
                DISCONTINUE USING THE WEBSITE.
              </p>
              <p>
                <strong>This Privacy Policy was last modified June 11, 2018.</strong>
              </p>
              <hr />
              <h3>About This Privacy Policy</h3>
              <p>This Privacy Policy (this &quot;Policy&quot;) sets forth Wunder&#39;s policy with respect to:</p>
              <ul>
                <li>
                  <p>
                    The information we collect in connection with the operation of this website, including your account
                    on the Website (your &quot;Wunder Account&quot;);
                  </p>
                </li>
                <li>
                  <p>How information collected from or about you is used; and</p>
                </li>
                <li>
                  <p>How you can access and correct certain information that we may collect.</p>
                </li>
              </ul>
              <hr />
              <h3>In this Privacy Policy:</h3>
              <ul>
                <li>
                  <p>
                    Users of this Website, including owners, employees, agents and affiliates of any business,
                    corporation, partnership or other entity that is, or is considering becoming, a user, borrower, or
                    investor, are referred to as &quot;you&quot; or &quot;your.&quot;
                  </p>
                </li>
                <li>
                  <p>
                    The Wunder Company, a Delaware corporation, together with all applicable subsidiaries, affiliates,
                    assignees, successors and its and their respective officers, directors, employees and agents are
                    referred to as &quot;Wunder Capital,&quot; &quot;Wunder,&quot; &quot;we,&quot; &quot;us&quot; or
                    &quot;our.&quot; Together, you and we may be collectively referred to as &quot;Parties.&quot;
                  </p>
                </li>
              </ul>
              <p>
                The Website is designed to provide information about Wunder, its products and services, and to provide
                both investors and borrowers with access to information about their accounts with Wunder. This Policy
                applies to (i) information Wunder collects from you via the Website, including your use of, and
                activities on, the Website; (ii) information obtained or disclosed through electronic correspondence or
                communication; and (iii) information obtained from commercially available data. The Website is intended
                for persons 18 years of age and older. If you are under 18, we ask that you do not submit any personal
                information through the Website, by e-mail or any other means of communication to Wunder.
              </p>
              <hr />
              <h3>Modifications to This Policy</h3>
              <p>
                By using or accessing the Website, you agree that we may modify this Policy at any time without prior
                notice. We will have no further obligation to notify you of any modifications. We reserve the right to
                make changes to this Policy at any time and from time to time. Your continued use of the Website after
                any modification of this Policy will conclusively indicate your acceptance of the modification and the
                Policy as so modified.
              </p>
              <p>
                It is your responsibility to review this Policy from time to time to see if modifications have been
                made. Any modification is effective immediately upon posting on the Website. You will know if this
                Privacy Policy has been modified since your last visit to the Website by referring to the &quot;Last
                Modified&quot; date at the end of this page. Notwithstanding the above, we will seek your consent to
                future modifications to the extent we are required to do so by applicable law.
              </p>
              <h3 id="exclusions">Exclusions</h3>
              <p>
                This Policy applies only to information collected through our Website and by e-mail, telephone and other
                electronic communication and not to information collected otherwise, if any. This Policy does not apply
                to any unsolicited information you provide to Wunder through the Website or through any other means.
                This includes, but is not limited to, any ideas for new products or modifications to existing products,
                and other unsolicited comments or submissions (collectively, &quot;Unsolicited Information&quot;). All
                Unsolicited Information shall be deemed to be non-confidential and Wunder shall be free to reproduce,
                use, disclose, and distribute such Unsolicited Information to others without limitation or attribution.
              </p>
              <hr />
              <h3>We collect information about you in three ways:</h3>
              <h4>(I) Information You Provide:</h4>
              <p>
                When you create your Wunder Account, purchase an investment, initiate a transaction or conduct similar
                activities, you may be required to provide personally identifiable information such as your full legal
                name, address, telephone number, tax identification number (i.e., Social Security Number or Employer
                Identification Number), e-mail address, financial professional service provider, banking account
                information, and/or those of the business, corporation or other entity of which you may be an owner,
                employee, agent or representative. You may also have to provide certain documents (e.g., photo ID, tax
                returns) to verify your identity as required by Wunder&#39;s bank services providers, or to verify your
                status an &quot;accredited investor,&quot; as such term is defined pursuant to Rule 501(a) of Regulation
                D of the Securities Act of 1933.
              </p>
              <p>
                By voluntarily providing us with personally identifiable data, you are consenting to our use of it in
                accordance with this Policy. If you provide personally identifiable data to us via the Website, e-mail,
                telephone or other electronic means, you acknowledge and agree that such data may be transferred from
                your current location to the offices and servers of Wunder and/or any third party service providers
                authorized by Wunder, which may be located in the United States or other countries.
              </p>
              <h4>(II) Information from Third Parties and Other Sources:</h4>
              <p>
                Third parties, such as credit rating agencies, may provide us with information on you. We will obtain a
                credit score for any business, corporation or other entity interested in becoming an investor only with
                the permission of an authorized representative or agent of the entity. We may also obtain additional
                commercially available information from third parties or public sources.
              </p>
              <h4>(III) Information from Your Use of the Website:</h4>
              <p>
                We may collect additional user behavior information during your use of the Website. Such information
                includes, but is not limited to:
              </p>
              <ul>
                <li>
                  <p>
                    Device Information - We may collect device-specific information (such as your hardware model,
                    operating system version, internet browser or mobile internet browser, and, if applicable, mobile
                    network information including phone number, IP address of your mobile device). We may associate your
                    device identifiers or phone number with your Wunder Account(s).
                  </p>
                </li>
                <li>
                  <p>
                    Log Information - When you use the Website or view content provided in the Website, we may
                    automatically collect and store certain information in server logs, including with respect to your
                    usage of the Website, pages you visited, the content you viewed, etc.
                  </p>
                </li>
                <li>
                  <p>
                    Location Information - We may collect and process information about your actual location as reported
                    by your browser.
                  </p>
                </li>
              </ul>
              <hr />
              <h3>Our Use and Storage of Your Personally Identifiable Information</h3>
              <p>Any of the information we collect from you may be used in one or more of the following ways:</p>
              <ul>
                <li>
                  <p>
                    to provide you services and access that are personal to you, including your Wunder Account (for
                    example, your account information may be used in connection with processing your investment);
                  </p>
                </li>
                <li>
                  <p>
                    to personalize your experience (your information helps us to better respond to your individual
                    needs);
                  </p>
                </li>
                <li>
                  <p>
                    to enhance our Website and the services we offer (we continually strive to improve the Website, its
                    content and functionality, and to understand the needs and preferences of our investors and other
                    users, based on the information and feedback we receive from you and other users);
                  </p>
                </li>
                <li>
                  <p>
                    to improve customer service (your information helps us to more effectively respond to your customer
                    service requests and support needs);
                  </p>
                </li>
                <li>
                  <p>
                    to send you periodic communications (the e-mail address you provide at registration or for
                    transactions or investments may be used to send you information and updates pertaining to your
                    investment reservation or other transaction requests, in addition to occasional news, updates,
                    related product or service information, etc.)
                  </p>
                </li>
                <li>
                  <p>
                    to obtain, with your authorization, third-party data from credit bureaus or others in connection
                    with your investment reservation or other transaction requests;
                  </p>
                </li>
                <li>
                  <p>to verify your identity and guard against potential fraud; and</p>
                </li>
                <li>
                  <p>to enforce agreements and/or carry out regulatory checks.</p>
                </li>
              </ul>
              <p>
                Note: If you would like to unsubscribe from receiving e-mails at any time, please follow the
                &quot;unsubscribe&quot; instructions in any e-mail message or send us e-mail to support@wunderpower.com.
              </p>
              <p>
                Wunder uses third party vendors and hosting partners to provide hardware, software, networking, storage,
                support or related technology required to run our services. Your information may be accessible by such
                third party vendors for the purpose of enabling them to store such data in connection with the permitted
                uses of your information under this Privacy Policy.
              </p>
              <p>
                The personally identifiable information that you provide and that we collect will be stored and
                maintained by Wunder for so long as we, in our sole discretion, believe is necessary or appropriate (a)
                to carry out the purpose(s) for which such information was collected, in accordance with this Policy, or
                (b) to comply with applicable laws, contracts or other rules or regulations. When you contact Wunder, we
                may keep a record of your communication to help solve any issues you might be facing.
              </p>
              <p>
                If you register for a Wunder Account, the personal information that you provide and that we collect will
                be stored and maintained by Wunder for as long as you maintain an active Wunder Account and for as long
                thereafter as may be required by any applicable law or regulation.
              </p>
              <p>
                Upon our collection of your personally identifiable information, Wunder may use such personally
                identifiable information internally, separately or in combination with pre-existing information, for the
                purposes below. Such uses may be performed by Wunder directly or on Wunder&#39;s behalf by our third
                party service providers. We will ask for your consent before using information for any purpose other
                than those described here:
              </p>
              <ul>
                <li>
                  <p>
                    to verify your status an &quot;accredited investor,&quot; as such term is defined pursuant to Rule
                    501(a) of Regulation D of the Securities Act of 1933;
                  </p>
                </li>
                <li>
                  <p>to contact you, including via e-mail, to respond to your comments or requests for information;</p>
                </li>
                <li>
                  <p>to troubleshoot problems with the Website, or any services, as requested;</p>
                </li>
                <li>
                  <p>to improve our products and services and identify, develop or offer new products and services;</p>
                </li>
                <li>
                  <p>to contact you with more information about Wunder and its services;</p>
                </li>
                <li>
                  <p>
                    to customize your experience, content or Wunder Account, including providing you with more relevant
                    products, services or content;
                  </p>
                </li>
                <li>
                  <p>to enforce our Terms of Use or this Policy or other legal rights;</p>
                </li>
                <li>
                  <p>
                    to generate aggregate statistical studies and conduct research which may be used in marketing our
                    services to you or to third parties; and
                  </p>
                </li>
                <li>
                  <p>to provide any legitimate business service or product.</p>
                </li>
              </ul>
              <p>
                When showing or providing you with tailored content, we will not associate a cookie or anonymous
                identifier with sensitive categories, such as those based on race, religion, sexual orientation or
                health. We may combine personal information about you received from several sources in order to tailor
                the content you may access and/or receive. Wunder reserves the right to de-identify your personally
                identifiable information and to store and use such de-identified information in any manner whatsoever,
                including sharing such de-identified information with third parties in an aggregate form as described
                under this Privacy Policy.
              </p>
              <hr />
              <h3>Cookies and Other Technologies</h3>
              <p>
                We use various technologies to collect and store personal and non-personally identifiable information
                when you use the Website. This may include sending one or more cookies or anonymous identifiers to your
                computer or mobile device, including &quot;web beacons,&quot; clear GIFs, pixel tags and other means.
                &quot;Cookies&quot; are small files that a website or its service provider transfers to your computer or
                mobile device through your Internet browser when you visit a website that enable the website&#39;s or
                service provider&#39;s system to recognize your browser and capture and remember certain information.
                &quot;Pixel tags&quot; are not visible to a user of a website and consist of a few lines of computer
                coding delivered with the web pages accessed. Wunder may use such technologies or similar technologies
                to obtain non-personal information from you as an online visitor.
              </p>
              <p>
                Most browsers accept Cookies automatically, but can be configured not to accept them or to indicate when
                a cookie is being sent. Generally, information in your browser&#39;s &quot;Help&quot; section of the
                toolbar, or on the website of the browser designer or vendor, will explain how to turn off or modify
                your browser&#39;s settings regarding Cookies. Please note that without Cookies, you may not be able to
                take full advantage of all of the Website&#39;s features.
              </p>
              <p>We may use Cookies or Pixel Tags to, among other things,</p>
              <ul>
                <li>
                  <p>tell Wunder when you&#39;re logged in to the Website;</p>
                </li>
                <li>
                  <p>personalize the content and offerings presented to you on the Website;</p>
                </li>
                <li>
                  <p>track your usage of the Website;</p>
                </li>
                <li>
                  <p>
                    present you with ads on the Website and retargeting advertising on other sites based on your
                    previous interaction on the Website;
                  </p>
                </li>
                <li>
                  <p>
                    measure the performance of ads, and make them more relevant to you based on criteria like your
                    activity on the Website and visits to our ad partners&#39; websites; and
                  </p>
                </li>
                <li>
                  <p>compile aggregate, non-personally identifiable data about site traffic and site interaction.</p>
                </li>
              </ul>
              <p>
                We may share information gathered using Cookies and Pixel Tags with third-party service providers to
                assist us in better understanding and delivering services to our site visitors.
              </p>
              <p>
                Wunder uses Google Analytics to collect information about use of the Website. Google Analytics collects
                information such as how often users visit the Website, what pages they visit when they do so, and what
                other sites they used prior to coming to the Website. Wunder uses the information that we get from
                Google Analytics only to improve the Website. Wunder does not combine the information collected through
                the use of Google Analytics with personally identifiable information. Google&#39;s ability to use and
                share information collected by Google Analytics about your visits to this site is restricted by the
                Google Analytics <a href="http://www.google.com/analytics/terms/us.html">Terms of Service</a>, and the
                Google <a href="http://www.google.com/policies/privacy">Privacy Policy</a>.
              </p>
              <hr />
              <h3>Safeguarding Your Access Information and Password; Your Responsibilities</h3>
              <p>
                Subject to our account registration procedures and the Terms of Use, you may not be granted access to
                your Wunder Account, your account information or certain investment or financial information online
                until you have entered a user name and password. To conduct a financial transaction, you may also be
                required to identify itself by using your account password.
              </p>
              <p>
                As a user of the Website and your Wunder Account, you are solely responsible for maintaining the secrecy
                and confidentiality of your password and other account information and should take steps to protect them
                from misuse. You are solely responsible for all actions or activities that occur on the Website or any
                Wunder server, computer, system, network, cloud or software connected to or through the website that is
                performed on or through your Wunder Account during the time your user ID and password are logged in to
                the Website, whether or not expressly authorized by you. You and we acknowledge and agree that we have
                no duty to inquire as to the authority or propriety of any instructions given to or input on the Website
                or your Wunder Account using your ID and password or other security data or credentials and that we are
                entitled to act upon any such instructions or input without liability to you.
              </p>
              <p>
                Although we strive to develop and maintain adequate safeguards designed to protect personal information,
                please be aware that no security measures are completely secure or impenetrable. We cannot guarantee
                that only authorized persons will view your information or that information you share on the Website or
                via e-mail or other electronic communication with Wunder will not become publicly available or
                intercepted by third parties. By your continued use of the Website, you acknowledge and agree that
                Wunder and its service providers will not be liable for any harm or damage you may experience by
                transmitting or submitting personal or confidential information to Wunder by e-mail or other electronic
                communications or via the Website.
              </p>
              <p>
                If you become aware of any unauthorized use of, loss or theft of your username, password, or other
                account information or credentials, you should immediately inform us at support@wunderpower.com.
              </p>
              <hr />
              <h3>Our Sharing and Transfer of Your Personal Information</h3>
              <p>
                We will not trade, sell or share your personal information with or to any third party except in the
                following circumstances:
              </p>
              <ul>
                <li>
                  <p>
                    With Your Third Party Service Providers - If you have given third party service providers,
                    representatives or agents access to your Wunder Account, then your Wunder Account, investment and/or
                    transaction information will be visible to such persons and they may have access to messages and
                    communications from us sent to your Wunder Account and/or e-mail address.
                  </p>
                </li>
                <li>
                  <p>
                    Related Entities -- As noted above, applicable subsidiaries, affiliates, assignees or successors of
                    Wunder may have access to your personal information for purposes consistent with this Privacy
                    Policy.
                  </p>
                </li>
                <li>
                  <p>
                    As Required by Law or for the Protection of Rights - Wunder reserves the right to disclose your
                    personally identifiable information based on the good faith belief that such action is necessary or
                    appropriate to: (a) meet any applicable law, regulation, legal process or enforceable governmental
                    or judicial request (including discovery or subpoena); (b) enforce our Terms of Use or this Policy,
                    including investigation of potential violations or criminal activity by you or third parties; (c)
                    detect, prevent, or otherwise address fraud, security or technical issues; (d) protect and defend
                    the rights or property of Wunder, its directors, officers, employees, agents or service providers;
                    or (e) act in urgent circumstances to protect the safety or security of the public (or any segment
                    thereof) or of users of Wunder&#39;s services and/or the Website.
                  </p>
                </li>
                <li>
                  <p>
                    Marketing/Research - We may share aggregated, non-personally identifiable user information publicly
                    and/or with companies that perform marketing services on our behalf or with other financial
                    institutions with which we have joint marketing arrangements. For example, we may share information
                    publicly to show trends about the general use of our services or the Website. We may also sell or
                    make available for free such aggregated, non-personally identifiable information to third parties.
                  </p>
                </li>
                <li>
                  <p>
                    Change of Control - In the event that Wunder is involved in a transaction or event such as a merger,
                    acquisition, reorganization or sale of assets, your personal information may be transferred to the
                    other party in such transaction. You will be notified by e-mail and/or a prominent notice on the
                    Website of a change in ownership of Wunder or material transfer of control of your personal
                    information, as well as any choices you may have regarding your personal information.
                  </p>
                </li>
              </ul>
              <hr />
              <h3>Indemnity for Service Provider Acts</h3>
              <p>
                You and we acknowledge and agree that Wunder shall have no liability to you or any other person for, or
                resulting from, any acts or omissions by any agent, representative or third-party service provider whom
                you may at any time delegate, or who is acting on your behalf, while such person is using your Wunder
                Account. You hereby agree to indemnify, save and hold harmless Wunder, its affiliates and subsidiaries
                and its and their respective successors, assigns, shareholders, partners, members, officers, directors,
                managers, licensors, independent contractors, consultants, employees and advisors in their individual
                and representative capacities from and against any suit, action, claim, demand, penalty or loss,
                including reasonable attorneys&#39; fees and expenses and any amount paid in settlement to a third
                party, brought by or resulting from any third party (including any government agency or body) due to, in
                connection with or arising out of any such act or omission by your agent, representative or third-party
                service provider while such person is using your Wunder Account, regardless of whether the specific use
                was authorized by you.
              </p>
              <hr />
              <h3>Your Access to Your Personal Information</h3>
              <p>
                We attempt to keep your files complete, up-to-date, and accurate. We offer several ways for you to
                update your personal information or notify us about information needing correction:
              </p>
              <ul>
                <li>
                  <p>
                    You may use the contact information set forth at the end of this document to contact us for any
                    inquiry or request regarding this Policy or the handling of your personal information;
                  </p>
                </li>
                <li>
                  <p>
                    You may log in to the Website and correct any information about yourself on your Wunder Account;
                  </p>
                </li>
                <li>
                  <p>
                    You may contact us directly if you wish to: (a) update or delete your contact information or (b)
                    request that we stop sending you promotional or other information via mail or e-mail. Such requests
                    should be sent to us at support@wunderpower.com;
                  </p>
                </li>
                <li>
                  <p>
                    You may also unsubscribe to any of our e-mails by following the &quot;unsubscribe&quot; instructions
                    in any e-mail message; or
                  </p>
                </li>
                <li>
                  <p>You may close your account at any time if you have no active investments or loans.</p>
                </li>
              </ul>
              <p>
                We reserve the right to deny access to your personally identifiable information (a) when such denial of
                access is required by law, (b) when granting you access is reasonably likely to negatively impact other
                people&#39;s privacy, (c) to protect our rights and property and (d) when such requests are frivolous or
                made for fraudulent purposes or in bad faith.
              </p>
              <hr />
              <h3>Children&#39;s Privacy</h3>
              <p>
                The Website is intended for persons 18 years of age and older. We do not knowingly collect personal
                information from persons under 18 years of age. Accordingly, the provisions of the Children&#39;s Online
                Privacy Protection Act do not apply to the Website.
              </p>
              <hr />
              <h3>Our Security Measures; Users Outside the United States</h3>
              <p>
                The data and privacy protection applicable or available to your personal information may differ from the
                protections required by the laws of certain countries or territories, such as the European Union. We
                make no claims that the Website or any of its contents may be downloaded or accessed outside of the
                United States or are appropriate for or comply with the laws of any other country or territory. If you
                access the Website from outside the United States, you do so at your own risk and are responsible for
                compliance with the laws of your country. You may not use or export any content of the Website in
                violation of U.S. export laws and regulations or any other U.S. or foreign statutes, rules or
                regulations, or international treaties.
              </p>
              <p>
                We use reasonable physical, electronic, and procedural safeguards that comply with federal standards to
                protect and limit access to personal information under our control. However, please note that
                transmissions via the Internet, telephone, e-mail, mobile networks and other electronic means are never
                completely private or secure. You acknowledge that (i) any messages or personal information you send to
                us or the Website, including by e-mail, may be read or intercepted by third parties and (ii) in the
                unlikely event of a security breach, the servers containing your information could become accessible. If
                you have any questions about the security of personally identifiable information we collect, please
                contact us at support@wunderpower.com.
              </p>
              <p>
                We use secure technology to encrypt your personal information such as user IDs, passwords and account
                information over the Internet. Any information we send you is encrypted en route and decoded once it
                reaches your browser. Also, when you submit information through an online form, we&#39;ll encrypt it to
                help keep your information secure. To the extent such technology is utilized on a given page, you may be
                unable to use certain account or customization features of the Website unless your web browser supports
                such encryption. Please note that no technology can be considered completely secure or impenetrable and
                Internet protocols and other public and/or proprietary technology used or accessed by the Website may be
                vulnerable to exploitation or compromise by persons engaged in criminal conduct. We and you each
                acknowledge and agree that we shall have no liability to you for any such exploitation or criminal
                conduct by third parties.
              </p>
              <hr />
              <h3>General</h3>
              <p>
                You acknowledge and agree that all rights not otherwise expressly granted to you by this Policy are
                reserved to Wunder. This Policy, together with the{' '}
                <a href="https://www.wunderpower.com/legal/terms">Terms of Use</a>, which are hereby incorporated by
                reference herein in their entirety, constitutes the sole and entire agreement between you and us with
                respect to the subject matter hereof and thereof and supersedes all other prior or contemporaneous
                negotiations, discussions, agreements, understandings, representations and warranties, both written and
                oral, between you and us with respect to such subject matter. You agree that no joint venture,
                partnership, employment, or agency relationship exists between you and Wunder as a result of this Policy
                or any use of the Website. The failure of Wunder to exercise or enforce any right or provision of this
                Policy shall not constitute a waiver of such right or provision. If any provision of this Policy is
                found by a court of competent jurisdiction to be invalid, void, unlawful or unenforceable, the Parties
                agree that the court should give effect to the Parties&#39; intentions as reflected in such provision,
                and the other provisions of this Policy shall remain in full force and effect. The section titles in
                this Policy are for convenience only and have no legal or contractual effect. Neither the course of
                conduct between you and Wunder nor trade or industry practice shall serve to modify or waive any
                provision of the Policy. For purposes of this Policy, the terms &quot;include,&quot;
                &quot;includes,&quot; &quot;including,&quot; &quot;such as&quot; and &quot;for example,&quot; shall be
                construed as if each term were followed by the words, &quot;without limitation.&quot; Except where
                context requires otherwise, use of the singular form of any noun includes the plural, use of the plural
                includes the singular, and use of any masculine, feminine or neutral pronoun includes any other gender.
              </p>
              <hr />
              <h3>Choice and Opt-Out Preference; Termination</h3>
              <p>
                If, at any time, you prefer not to receive further e-mail communications from Wunder, you will have the
                ability to unsubscribe from all or some of such communications by updating your preferences in your
                Wunder Account or to contact us to opt out using the contact instructions listed below. If, at any time,
                you prefer not to receive any other form of communication from Wunder, you will have the ability to
                unsubscribe from such communications by:
              </p>
              <ul>
                <li>
                  <p>Contacting us directly at support@wunderpower.com;</p>
                </li>
                <li>
                  <p>
                    Unsubscribing from any of our e-mails by following the &quot;unsubscribe&quot; instructions in the
                    body of any e-mail message; or
                  </p>
                </li>
                <li>
                  <p>Closing your Wunder Account.</p>
                </li>
              </ul>
              <p>
                As noted above, we will retain your information for as long as your Wunder Account is active or as
                needed to provide you services or as necessary to comply with our legal obligations, resolve disputes,
                and enforce our agreements. If you wish to cancel your Wunder Account or request that we no longer use
                your information to provide you services, contact us in accordance with the contact information above.
              </p>
              <hr />
              <h3>Choice of Law; Forum for Disputes</h3>
              <p>
                This Policy and all disputes, claims or controversies (whether in tort, contract or otherwise) arising
                out of or relating in any way to this Policy, the Website or the Content, the interpretation, validity
                or performance of this Policy, the rights and obligations of you and us under this Policy or any
                transaction contemplated by the Website shall be governed by and construed in accordance with the laws
                of the State of Delaware without regard to the rules or principles of conflict of laws of such State or
                any other jurisdiction that would permit or require the application of the laws of any other
                jurisdiction.
              </p>
              <p>
                We and you each hereby irrevocably and unconditionally consent to submit to the sole and exclusive
                jurisdiction of the courts of the State of Delaware and of the United States of America located in the
                State of Delaware, City of Wilmington (the &quot;Delaware Courts&quot;) for any litigation, lawsuit or
                proceeding between you and us arising out of or relating in any way to this Policy (including any
                non-contractual claims), the Website, the interpretation, validity or performance of this Policy, the
                rights and obligations of you and us hereunder or any transaction contemplated by the Website. You and
                we each waive (give up) any objection to the laying of venue of any such litigation in the Delaware
                Courts and agree not to plead or claim in any Delaware Court that such litigation brought therein has
                been brought in an inconvenient forum or that there are indispensable parties to such litigation that
                are not subject to the jurisdiction of the Delaware Courts. You and we each hereby irrevocably waive any
                and all rights which you or we, respectively, may have, or may have had, to bring such litigation in or
                before any other court or tribunal (whether domestic or foreign), or before any similar domestic or
                foreign authority or body, and agree not to claim or plead any such rights.
              </p>
              <hr />
              <h3>WAIVER OF JURY TRIAL</h3>
              <p>
                WE AND YOU EACH ACKNOWLEDGE AND AGREE THAT ANY CONTROVERSY THAT MAY ARISE UNDER THESE TERMS IS LIKELY TO
                INVOLVE COMPLICATED AND DIFFICULT ISSUES AND, THEREFORE, EACH PARTY IRREVOCABLY AND UNCONDITIONALLY
                WAIVES ANY RIGHT IT MAY HAVE TO A TRIAL BY JURY IN RESPECT OF ANY LEGAL ACTION OR PROCEEDING ARISING OUT
                OF OR RELATING TO THIS POLICY OR THE WEBSITE AND FOR ANY COUNTERCLAIM THEREIN. EACH PARTY CERTIFIES AND
                ACKNOWLEDGES THAT (I) NO REPRESENTATIVE OF THE OTHER PARTY HAS REPRESENTED, EXPRESSLY OR OTHERWISE, THAT
                SUCH THE PARTY WOULD NOT SEEK TO ENFORCE THE FOREGOING WAIVER IN THE EVENT OF A LEGAL ACTION, (II) SUCH
                PARTY HAS CONSIDERED THE IMPLICATIONS OF THIS WAIVER, (III) SUCH PARTY MAKES THIS WAIVER VOLUNTARILY AND
                (IV) SUCH PARTY HAS BEEN INDUCED TO ENTER INTO THIS AGREEMENT BY, AMONG OTHER THINGS, THE MUTUAL WAIVERS
                AND CERTIFICATIONS IN THIS PROVISION.
              </p>
              <hr />
              <h3>CLASS ACTION WAIVER</h3>
              <p>
                WE AND YOU EACH AGREE THAT THERE WILL BE NO RIGHT OR AUTHORITY FOR ANY DISPUTE TO BE BROUGHT, HEARD OR
                ARBITRATED AS A CLASS ACTION (INCLUDING WITHOUT LIMITATION OPT OUT CLASS ACTIONS OR OPT IN COLLECTIVE
                CLASS ACTIONS), OR IN A REPRESENTATIVE OR PRIVATE ATTORNEY GENERAL CAPACITY ON BEHALF OF A CLASS OF
                PERSONS OR THE GENERAL PUBLIC. ALL DISPUTES SHALL BE RESOLVED ON AN INDIVIDUAL BASIS ONLY.
              </p>
              <hr />
              <h3>Links to Third Party Websites</h3>
              <p>
                The Website may also contain advertisements or other links to websites that are not controlled or
                operated by us. This Policy does not apply to such third party websites, and we are not responsible for
                the content of such websites or the privacy practices of the third parties controlling or operating such
                websites. We encourage you to request and review the privacy policies of any third parties before
                disclosing your information to such parties or visiting such third party websites. Any links to third
                party websites or information do not imply that we sponsor, endorse or have reviewed such websites or
                information.
              </p>
              <hr />
              <h3>Special Notice to California Residents Regarding S.B. 27</h3>
              <p>
                Under S.B. 27 as enacted in California law, California residents have the right to receive, once a year,
                information about third parties with whom we have shared information about you or your family for their
                marketing purposes during the previous calendar year, and a description of the categories of personal
                information shared. To make a request under S.B. 27: please send a message to support@wunderpower.com.
                We will respond to you within thirty (30) days of receiving such a request.
              </p>
              <hr />
              <h3>Do Not Track</h3>
              <p>
                We support Do Not Track (&quot;DNT&quot;) with respect to your usage of the Website. DNT is a privacy
                preference that you can set in your web browser to indicate that you do not want certain information
                about your webpage visits collected across websites when you have not interacted with that service on
                the page. For all the details, including how to turn on Do Not Track, visit donottrack.us.
              </p>
              <p>
                When you turn on DNT in your browser, we stop collecting the information that allows us to optimize the
                services and communications we provide you and offer additional Wunder products and services that may be
                of interest to you based on your recent visits to websites that have integrated our buttons or widgets.
                Specifically, we stop collecting the unique browser cookie that links your browser to your previous
                visits to these websites.
              </p>
              <hr />
              <h3>Third Party Collection of Your Personally Identifiable Information</h3>
              <p>
                Third parties may collect personally identifiable information about your online activities over time and
                across different websites when you use the Website.
              </p>
              <hr />
              <h3>Questions or Comments -- Our Contact Information</h3>
              <p>
                We welcome questions and comments about this Policy. Questions or comments should be directed to
                support@wunderpower.com.
              </p>
            </Content>
          </Container>
        </PullUp>
      </Swirl>
      <Footer />
    </ThemeProvider>
  )
}

export default Privacy
