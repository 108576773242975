import zIndex from './zIndex'

// Colors
const colors = {
  red: '#FF5C39',
  yellow: '#F5A519',
  green: '#27C753',
  turquoise: '#15D0CC',
  blue: '#5890FF',
  navy: '#466FC0',
  purple: '#A058FF',
  offWhite: '#FAFAFA',
  offBlack: '#1E1F21',
  middleGray: '#E8E8E8', // offblack with .1 alpha
  darkGray: '#A6A6A6',
}

// Spacing
const spacing = {
  small: 10,
  medium: 20,
  large: 30,
  xlarge: 70,
}

const theme = {
  borderRadius: 4,
  boxShadow: `0 10px 50px 0 rgba(0, 0, 0, 0.2)`,
  colors,
  currentColor: colors.yellow,
  spacing,
  zIndex,
}

export default theme
