import React from 'react'
import { Formik } from 'formik'
import { connect } from 'react-redux'
import { navigate } from '@reach/router'

import Section from 'marketing/components/Section/Section'
import Container from 'marketing/components/Container/Container'
import FormWrapper from 'shared/components/Form/FormWrapper/FormWrapper'
import FormSection from 'shared/components/Form/FormSection/FormSection'
import Input from 'shared/components/Form/Input/Input'
import Password from 'shared/components/Form/Password/Password'
import Button from 'marketing/components/Button/Button'
import { RootState } from 'marketing/redux/store'
import { selectIsLoading } from 'shared/redux/loading'
import { acceptInvitation, ACCEPT_INVITATION } from 'shared/redux/user/effects'
import { isSuccess, getUrlParam } from 'shared/lib/utils'
import { setErrors } from 'shared/lib/formUtils'
import { loginPath } from 'marketing/helpers/routes'
import { AppDispatch } from 'shared/redux/types'

interface Props {
  isLoading?: boolean
  dispatch?: AppDispatch
}

const InvitationForm = (props: Props): JSX.Element => {
  const invitationToken = getUrlParam('invitation_token')

  const handleSubmit = async (values, form): Promise<void> => {
    const action = await props.dispatch(acceptInvitation(values))
    isSuccess(action) ? navigate(loginPath()) : setErrors(form, action.payload.errors)
  }

  return (
    <Section h1="Set Up Your Account" text="Create your new Wunder account by providing some basic information below.">
      <Container maxWidth={400}>
        <Formik
          initialValues={{ firstName: '', lastName: '', password: '', invitationToken: invitationToken }}
          onSubmit={handleSubmit}
        >
          <FormWrapper method="post">
            <FormSection>
              <Input name="firstName" />
              <Input name="lastName" />
            </FormSection>

            <Password name="password" />

            <Button type="submit" block disabled={props.isLoading}>
              Submit
            </Button>
          </FormWrapper>
        </Formik>
      </Container>
    </Section>
  )
}

const mapState = (state: RootState): Props => ({
  isLoading: selectIsLoading(state, [ACCEPT_INVITATION]),
})

export default connect(mapState)(InvitationForm)
