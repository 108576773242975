import React from 'react'
import { RouteComponentProps } from '@reach/router'
import { ThemeProvider } from 'styled-components'

import Hero from './Hero'
import TopBoxes from './TopBoxes'
import FinancingMediaCards from './FinancingMediaCards'
import SolarPicturesSlider from './SolarPicturesSlider'
import SeamlessExperience from './SeamlessExperience'
import MakeAnImpact from './MakeAnImpact'
import SaveOnElectricity from './SaveOnElectricity'

import theme from 'marketing/components/Global/theme'
import Header from 'marketing/components/Header/Header'
import Footer from 'marketing/components/Footer/Footer'
import Swirl from 'marketing/components/Swirl/Swirl'
import PressLogos from 'marketing/content/PressLogos/PressLogos'
import useDocTitle from 'shared/lib/useDocTitle'

const GoSolar: React.FC<RouteComponentProps> = ({ children }): JSX.Element => {
  useDocTitle('Wunder')

  return (
    <ThemeProvider theme={{ ...theme, currentColor: theme.colors.blue }}>
      <Header />
      <Hero />

      <Swirl direction="bi">
        <TopBoxes />
        <FinancingMediaCards />
        <SolarPicturesSlider />
        <SeamlessExperience />
      </Swirl>

      <MakeAnImpact companyName="Wunder" />
      <SaveOnElectricity />

      <Swirl>
        <PressLogos />
      </Swirl>

      <Footer />

      {children}
    </ThemeProvider>
  )
}

export default GoSolar
