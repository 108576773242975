import React from 'react'
import styled from 'styled-components'

import { middleGray, borderRadius, red, spacingSm } from 'shared/lib/variables'
import Errors from 'shared/components/Form/Errors/Errors'
import useGetErrors from 'shared/components/Form/Errors/useGetErrors'

interface Props {
  name: string
  noMargin?: boolean
  afterChange?: (value: unknown) => void
  children: JSX.Element | JSX.Element[]
}

const RadioGroupWrapper = styled.div<{ noMargin: boolean }>((props) => ({
  width: '100%',
  marginTop: props.noMargin ? 0 : spacingSm,
}))

const RadioGroupStyled = styled.div<{ hasError: boolean }>((props) => ({
  backgroundColor: 'white',
  border: `1px solid ${props.hasError ? red : middleGray}`,
  borderRadius: borderRadius,
}))

const RadioGroup = (props: Props): JSX.Element => {
  const errors = useGetErrors(props.name)
  const children = Array.isArray(props.children) ? props.children : [props.children]

  return (
    <RadioGroupWrapper noMargin={props.noMargin}>
      <RadioGroupStyled hasError={!!errors} className="radio-group">
        {/* Add `props.name` to each radio option */}
        {children.map((component) =>
          React.cloneElement(component, {
            key: component.props.value,
            name: props.name,
            afterChange: props.afterChange,
          }),
        )}
      </RadioGroupStyled>

      <Errors name={props.name} />
    </RadioGroupWrapper>
  )
}

export default RadioGroup
