const operatingPrinciples = [
  {
    title: 'Trust',
    subtitle: 'Driver Drives',
    text: 'We start from a place of trust in each role. We value outputs, not inputs. We treat each other as peers with the freedom to experiment and iterate. We believe the closest person to the problem should generally solve the problem.',
  },
  {
    title: 'Improvement',
    subtitle: 'Fast Frequent Feedback',
    text: 'We push ourselves to provide uncomfortable feedback. We speak openly about mistakes and faults. We actively seek critical feedback from each other. We are deeply committed to helping our teammates learn and improve over time.',
  },
  {
    title: 'First Principles',
    subtitle: 'No Problem is Too Hard',
    text: 'We set audacious goals. We relish tackling hard problems. We think independently, logically, and empirically about each aspect of Wunder, and our industry.',
  },
  {
    title: 'Speed',
    text: 'Our biggest advantage is speed. Luckily, the best advantage is speed. We move to decisions swiftly and decisively. We prioritize the critical, and remove the excess. We address internal blockers quickly, and avoid unnecessary external dependencies.',
  },
  {
    title: 'Focus',
    text: 'We stay acutely aware of how the activities that we undertake relate to our goals. We regularly and systematically check in on our goals to ensure that the most important work is getting done quickly, efficiently, and first.',
  },
  {
    title: 'Superlative',
    subtitle: 'Aim Higher',
    text: 'We believe that the work and dedication required to go from good to world-class, to artistic, to boundary-pushing, delivers far more in reward than cost. We are not satisfied until we develop the depth and expertise in our roles to intuit, to innovate and to lead in our fields.',
  },
  {
    title: 'Commitment',
    text: 'At Wunder, every person is deeply committed to our fight against climate change, every person is committed to their team and their coworkers, and every person recognizes that resilience is a key ingredient when it comes to realizing the impact that we all seek.',
  },
]

export default operatingPrinciples
