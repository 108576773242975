import { createSelector } from 'reselect'
import { sortBy } from 'lodash'

import { DepartmentInterface } from './interfaces'

import { RootState } from 'marketing/redux/store'

export const selectDepartments = (state: RootState): { [key: string]: DepartmentInterface } => state.careers.departments
const selectId = (state: RootState, id: number): number => id

// This filters out 'No Department', which has id: 0
export const selectAllDepartments = createSelector(selectDepartments, (departments): DepartmentInterface[] => {
  const result = Object.keys(departments)
    .filter((key) => key !== '0')
    .map((key) => departments[key])

  return sortBy(result, (d) => d.name)
})

export const selectDepartmentById = createSelector(
  [selectDepartments, selectId],
  (departments, id): DepartmentInterface => {
    return departments[id]
  },
)
