import React from 'react'

import Section from 'marketing/components/Section/Section'
import Card from 'marketing/components/Card/Card'
import Cards from 'marketing/components/Card/Cards'
import Icon from 'marketing/components/Icon/Icon'
import { pressPath, supportPath, contactPath, careersPath, aboutPath } from 'marketing/helpers/routes'

interface Props {
  cards: ('about' | 'careers' | 'press' | 'support' | 'contact')[]
}

const LearnMore = (props: Props): JSX.Element => {
  return (
    <Section h2="Learn More" data-section="learn-more">
      <Cards columns={2}>
        {props.cards.includes('about') && (
          <Card
            icon={<Icon name="two-triangles" size={64} />}
            title="Overview"
            text="Learn about Wunder’s history, mission, and software-based approach to scaling commercial solar"
            to={aboutPath()}
          />
        )}
        {props.cards.includes('careers') && (
          <Card
            icon={<Icon name="two-triangles" size={64} />}
            title="Careers"
            text="Learn about opportunities at Wunder and start doing the most important work of your career"
            to={careersPath()}
          />
        )}
        {props.cards.includes('press') && (
          <Card
            icon={<Icon name="two-squares" size={64} />}
            title="Press"
            text="Read recent press coverage and clippings about Wunder’s efforts to scale the commercial solar market"
            to={pressPath()}
          />
        )}
        {props.cards.includes('support') && (
          <Card
            icon={<Icon name="two-ovals" size={64} />}
            title="Support"
            text="Get quick answers to your questions and learn more about Wunder’s finance offerings and processes"
            href={supportPath()}
          />
        )}
        {props.cards.includes('contact') && (
          <Card
            icon={<Icon name="two-triangles" size={64} />}
            title="Contact"
            text="We’re here to answer your questions, help solve your problems, and build more solar"
            to={contactPath()}
          />
        )}
      </Cards>
    </Section>
  )
}

export default LearnMore
