import React from 'react'
import { ServerStyleSheet } from 'styled-components'
import ReactDOMServer from 'react-dom/server'

// Taken from https://github.com/reactjs/react-rails/issues/264#issuecomment-552326663
// This is for showing the actual error instead of `Element type is invalid: expected a string`
export function fromRequireContext (reqctx) {
  return function (className) {
    const parts = className.split('.')
    const filename = parts.shift()
    const keys = parts
    // Load the module:
    let component = reqctx('./' + filename)
    // Then access each key:
    keys.forEach((k) => { component = component[k] })
    // support `export default`
    if (component.__esModule) { component = component.default }

    return component
  }
}

// Taken from https://gist.github.com/maxim/d9f2ee902b7800c728136a08c49797d2
// This does a few extra steps to extract style tags and put them in the <head> for SSR purposes
export function serverRender (renderFunction, componentName, props) {
  const ComponentConstructor = this.getConstructor(componentName)
  const stylesheet = new ServerStyleSheet()
  const wrappedElement = stylesheet.collectStyles(
    <ComponentConstructor {...props} />
  )
  const html = ReactDOMServer[renderFunction](wrappedElement)

  return {
    html,
    styles: stylesheet.getStyleTags()
  }
}
