import { useEffect } from 'react'
import { globalHistory } from '@reach/router'

const useScrollToTop = (): void => {
  useEffect((): void => {
    // @reach/router isn't good about setting the scroll position when changing pages.
    // This hook listens to the URL history and scrolls to the top of the window when it changes.
    // It no-ops if the user pressed Back (action: 'POP') or a link had the `noScroll` prop set.
    globalHistory.listen((props) => {
      if (typeof window !== 'undefined' && props.action !== 'POP' && !props.location.state?.noScroll)
        window.scrollTo(0, 0)
    })
  }, [])
}

export default useScrollToTop
