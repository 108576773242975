import React from 'react'
import { RouteComponentProps } from '@reach/router'
import { ThemeProvider } from 'styled-components'

import SolarStack from './assets/solar-software-stack.png'
import SolarStack2x from './assets/solar-software-stack@2x.png'
import Investors from './Investors'

import Header from 'marketing/components/Header/Header'
import theme from 'marketing/components/Global/theme'
import Footer from 'marketing/components/Footer/Footer'
import Section from 'marketing/components/Section/Section'
import Swirl from 'marketing/components/Swirl/Swirl'
import Highlight from 'marketing/components/Highlight/Highlight'
import Button from 'marketing/components/Button/Button'
import AboutUsBoxes from 'marketing/content/About/AboutUsBoxes'
import SideBySide from 'marketing/components/SideBySide/SideBySide'
import Container from 'marketing/components/Container/Container'
import ImpactSection from 'marketing/content/About/ImpactSection'
import OperatingPrinciplesSection from 'marketing/content/About/OperatingPrinciples/OperatingPrinciples'
import LearnMore from 'marketing/content/About/LearnMore'
import { careersPath } from 'marketing/helpers/routes'
import useDocTitle from 'shared/lib/useDocTitle'

const About: React.FC<RouteComponentProps> = (): JSX.Element => {
  useDocTitle('About - Wunder')

  return (
    <ThemeProvider theme={{ ...theme, currentColor: theme.colors.yellow }}>
      <Header />
      <Section
        h1={
          <>
            We are realizing
            <br /> America’s clean energy future
          </>
        }
        text={
          <>
            <Highlight>
              {' '}
              We’ve made going solar a painless process for hundreds of real estate firms and businesses just like
              yours.
            </Highlight>
            We’ll take care of the details so you can stay focused on your core business and start enjoying the benefits
            of solar ASAP.
          </>
        }
      >
        <Button to={careersPath()}>Join Our Team</Button>
      </Section>

      <Swirl direction="bi">
        <AboutUsBoxes />
        <ImpactSection divider />
        <Investors />

        <Section
          h2="Meet the solar development software stack"
          text={
            <>
              Wunder has integrated an industry-defining project finance platform with a suite of tailored software
              applications to{' '}
              <Highlight>put solar professionals in the driver seat and C&I solar on the fast track.</Highlight>
            </>
          }
        >
          <Container>
            <SideBySide>
              <>
                <h5>Commercial Solar Projects</h5>
                <div>
                  <h5>Development Applications</h5>
                  <p>
                    Wunder’s software tools turbocharge the origination, development, and management of C&I solar
                    assets.
                  </p>
                </div>
                <div>
                  <h5>Finance Platform</h5>
                  <p>
                    At Wunder’s core is a powerful finance platform that makes financing C&I solar projects easy and
                    scalable.
                  </p>
                </div>
                <h5>Wall Street Investors</h5>
              </>
              <img src={SolarStack} srcSet={SolarStack + ' 1x,' + SolarStack2x + ' 2x'} />
            </SideBySide>
          </Container>
        </Section>
      </Swirl>

      <OperatingPrinciplesSection />

      <Swirl>
        <LearnMore cards={['careers', 'press', 'support', 'contact']} />
      </Swirl>
      <Footer />
    </ThemeProvider>
  )
}

export default About
