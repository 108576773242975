import { format } from 'date-fns'
import { formatInTimeZone } from 'date-fns-tz'
import { camelCase, snakeCase } from 'lodash'
import { AnyAction } from 'redux'

import { AnyObject } from 'shared/lib/interfaces'
import iterateRecursively from 'shared/vendor/iterateRecursively'

// 150000 -> $1,500.00
export const centsToCurrency = (value: string | number, fractionDigits = 2): string => {
  const num = typeof value === 'string' ? Number.parseFloat(value) : value
  if (Number.isNaN(num)) return ''

  const number = num / 100

  return number.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  })
}

// '$1,500.00' -> 150000
export const currencyToCents = (value: string | number, precision = 2): number => {
  const number = typeof value === 'string' ? parseFloat(value.replace('$-', '').replace(/[$,_]/g, '')) : value

  if (isNaN(number)) {
    return null
  }

  const rawValue = number * 100

  return +rawValue.toFixed(precision - 2)
}

export const bpsToPercent = (value: string | number, fractionDigits = 2): string => {
  return `${(Number(value) / 100).toFixed(fractionDigits).toString()}%`
}

export const addCommas = (value: string | number): string => {
  const num = typeof value === 'string' ? Number.parseInt(value) : value
  if (Number.isNaN(num) || num == undefined) return ''

  return num.toLocaleString('en-US', { style: 'decimal' })
}

export const formatDateTime = (datetime: string | Date, fmt = 'L/d/yy @ h:mma'): string => {
  if (!datetime) return ''
  const date = typeof datetime === 'string' ? new Date(datetime) : datetime
  return format(date, fmt)
}

export const formatDate = (datetime: string | Date, fmt = 'L/d/yy'): string => {
  if (!datetime) return ''
  const date = new Date(datetime)
  return formatInTimeZone(date, 'Etc/Utc', fmt)
}

export const getClientTimeZone = (): string => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone
}

export const goBack = (): void => {
  window.history.back()
}

// { first_name } -> { firstName } (recursively)
export function camelize<T>(o: T): AnyObject {
  return iterateRecursively(o, camelCase)
}

// { firstName } -> { first_name } (recursively)
export function snakeize<T>(o: T): AnyObject {
  return iterateRecursively(o, snakeCase)
}

export const getRef = (namespace: string, model: string, id: number): string => {
  return `gid://${namespace}/${model}/${id}`
}

export const getEncodedRef = (namespace: string, model: string, id: number): string => {
  return btoa(getRef(namespace, model, id))
}

export const getIdFromRef = (ref: string, namespace: string, model: string): number | null => {
  const regex = new RegExp(`${namespace}\/${model}\/(\\d+)$`)
  const match = ref?.match(regex)
  const id = match ? match[1] : null
  return id ? parseInt(id) : null
}

// 'foo-bar.pdf' -> 'PDF'
export const getFileExtensionFromFileName = (filename: string): string => {
  const match = (filename || '').match(/.+\.(\w+)/)
  if (!match || match.length < 1) return ''

  return match[1].toUpperCase()
}

// Formats 10-14 digit US-style numbers
// 1234567890 -> (123) 456-7890
export const formatPhoneNumber = (number: string): string => {
  if (!number) return ''
  if (number.length < 10) return number

  return number.replace(/(\d{3})(\d{3})(\d{4})(\d{0,4})/, '($1) $2-$3 $4').trim()
}

export const isSuccess = (action: AnyAction): boolean => {
  return /SUCCESS/i.test(action.type)
}

export const isAccepted = (action: AnyAction): boolean => {
  return /ACCEPTED/i.test(action.type)
}

export const isCanceled = (action: AnyAction): boolean => {
  return /CANCELED/i.test(action.type)
}

export function checkVisible(element: Element): boolean {
  const rect = element.getBoundingClientRect()
  const viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight)
  return !(rect.bottom < 0 || rect.top - viewHeight >= 0)
}

export const scrollToHeight = (element: HTMLElement, height: number): void => {
  element.scrollTo(0, height)
}

export const getUrlParam = (param: string): string => {
  if (typeof window !== 'undefined') {
    const params = new URLSearchParams(window.location.search)
    return params.get(param)
  }
}

// 'foo/bar' -> '../..'
// '/foo/bar/baz' -> '../../..'
export const getParentPath = (path: string): string => {
  if (!path) return ''
  return path.replace(/^\/?/, '').replace(/([^\/])+/g, '..')
}

export const truncatedFullName = (fullName: string): string => {
  if (!fullName) return ''

  const nameComponents = fullName.split(' ')

  return nameComponents[0] + (nameComponents[1] ? ` ${nameComponents[1][0]}.` : '')
}
