import React from 'react'
import styled from 'styled-components'

import Anchor from 'marketing/components/Anchor/Anchor'

interface Props {
  title?: React.ReactNode
  subtitle?: React.ReactNode
  text?: React.ReactNode
  to?: string
  href?: string
  targetBlank?: boolean
  background?: string
  columns?: number
  className?: string
  children?: React.ReactNode
}

const MediaCardStyled = styled.div<{ to: string; href: string; background: string; columns: number }>((props) => ({
  background: `white no-repeat center/cover url(${props.background})`,
  border: `1px solid ${props.theme.colors.middleGray}`,
  borderRadius: props.theme.borderRadius,
  color: props.theme.colors.offBlack,
  flexGrow: 1,
  flexShrink: 0,
  flexBasis: '90%',
  margin: props.theme.spacing.small,
  minHeight: 340, // This makes a square on desktop resolutions

  ':hover': {
    border: `1px solid ${props.to || props.href ? props.theme.currentColor : props.theme.colors.middleGray}`,
  },

  ':hover a': {
    color: props.theme.colors.offBlack,
    textDecoration: 'none',
  },

  '@media (min-width: 768px)': {
    flexBasis: props.columns === 1 ? '30%' : props.columns === 2 ? '63%' : '97%',
  },
}))

const InnerMediaCard = styled.div((props) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  justifyContent: 'space-between',
  padding: props.theme.spacing.large,
}))

const Subtitle = styled.h5((props) => ({
  color: props.theme.currentColor,
}))

const Title = styled.h3({
  lineHeight: 1.6,
})

const TextStyled = styled.p({
  letterSpacing: '.45px',
  margin: 0,
})

const MediaCard = ({
  title,
  subtitle,
  text,
  to,
  href,
  targetBlank,
  background,
  columns = 1,
  className,
}: Props): JSX.Element => {
  return (
    <MediaCardStyled className={className} to={to} href={href} background={background} columns={columns}>
      <Anchor to={to} href={href} targetBlank={targetBlank}>
        <InnerMediaCard>
          {subtitle && <Subtitle>{subtitle}</Subtitle>}
          <div>
            {title && <Title>{title}</Title>}
            {text && <TextStyled>{text}</TextStyled>}
          </div>
        </InnerMediaCard>
      </Anchor>
    </MediaCardStyled>
  )
}

export default MediaCard
