import React from 'react'
import styled from 'styled-components'

interface Props {
  children: React.ReactNode
}

const GrayPageStyled = styled.div((props) => ({
  backgroundColor: props.theme.colors.offWhite,
  minHeight: '100vh',
  minWidth: '100vw',
}))

/**
 * GrayPage has a minimum width/height of the viewport
 */
const GrayPage = (props: Props): JSX.Element => {
  return <GrayPageStyled>{props.children}</GrayPageStyled>
}

export default GrayPage
