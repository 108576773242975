import React from 'react'

import { calculateMarketingStats } from './impactStats'

import Boxes from 'marketing/components/Box/Boxes'
import Box from 'marketing/components/Box/Box'
import PullUp from 'marketing/components/PullUp/PullUp'

const AboutUsBoxes = (): JSX.Element => {
  const impactStats = calculateMarketingStats()

  return (
    <PullUp distance={60}>
      <Boxes columns={4}>
        <Box title="2013" text="Year Founded" />
        <Box title={`${impactStats.wunderers} (+ dogs)`} text="Wunder-ers Currently" />
        <Box title={impactStats.totalMw} text="Built to Date" />
        <Box
          title={impactStats.lbsDisplaced}
          text={
            <>
              CO<sub>2</sub> Displaced per year
            </>
          }
        />
      </Boxes>
    </PullUp>
  )
}

export default AboutUsBoxes
