import React from 'react'
import styled from 'styled-components'

import GTM from './assets/GTM.jpg'
import ImpactAlpha from './assets/ImpactAlpha.jpg'
import SPW from './assets/SPW.jpg'
import TC from './assets/TC.jpg'
import WSJ from './assets/WSJ.jpg'
import Triangles from './assets/triangles.png'

import MediaCards from 'marketing/components/MediaCard/MediaCards'
import MediaCard from 'marketing/components/MediaCard/MediaCard'
import { aboutPath } from 'marketing/helpers/routes'

const Wrapper = styled.div((props) => ({
  marginTop: props.theme.spacing.xlarge,
}))

const FeaturedPress = (): JSX.Element => {
  return (
    <Wrapper>
      <MediaCards>
        <MediaCard
          href="https://www.businesswire.com/news/home/20220524005185/en/Wunder-Closes-One-of-the-Largest-Commercial-Solar-Deals-in-U.S.-History"
          subtitle="Business Wire"
          title="Wunder Closes One of the Largest Commercial Solar Deals in U.S. History”"
          background={SPW}
          targetBlank
        />
        <MediaCard
          href="https://www.bloomberg.com/news/articles/2020-07-14/wunder-capital-raises-100-million-for-u-s-solar-project-loans"
          subtitle="Bloomberg"
          title="“Wunder Capital raises $100 million for US Solar Projects”"
          background={TC}
          targetBlank
        />
        <MediaCard
          to={aboutPath()}
          subtitle="About Wunder"
          title={
            <>
              Learn more about Wunder’s mission,
              <br /> team, and company values.
            </>
          }
          background={Triangles}
          columns={2}
        />
        <MediaCard
          href="https://impactalpha.com/wunder-capital-sells-loans-to-free-200-million-for-solar-financing/"
          subtitle="ImpactAlpha"
          title="“Wunder Capital raises $200 million for solar financing”"
          background={ImpactAlpha}
          targetBlank
        />
        <MediaCard
          href="https://www.wsj.com/articles/a-ray-of-sunshine-for-solar-market-1524051000"
          subtitle="The Wall Street Journal"
          title="“A Ray of Sunshine for Solar Market”"
          background={WSJ}
          targetBlank
        />
        <MediaCard
          href="https://www.fastcompany.com/most-innovative-companies/2019/sectors/finance"
          subtitle="Fast Company"
          title="“Wunder Named as one of The World's Most Innovative Companies”"
          background={GTM}
          targetBlank
        />
        <MediaCard
          href="https://www.builtincolorado.com/companies/best-places-to-work-colorado-2021#wunder-capital"
          subtitle="BuiltinColorado"
          title="“Wunder named one of Colorado's Best Places to Work”"
          background={SPW}
          targetBlank
        />
      </MediaCards>
    </Wrapper>
  )
}

export default FeaturedPress
