import React from 'react'
import styled from 'styled-components'

import theme from 'marketing/components/Global/theme'

interface Props {
  distance?: number
  children?: React.ReactNode
}

const PullUpStyled = styled.div<{ distance: number }>((props) => ({
  paddingTop: props.theme.spacing.medium,

  '@media (min-width: 768px)': {
    marginBottom: -props.distance,
    marginTop: props.distance,
    paddingTop: 0,
    width: '100%',
    transform: `translateY(-${props.distance}px)`,
  },
}))

/**
 * Pull Up children components to create an effect that looks overlapped with components above
 */
const PullUp = ({ distance = theme.spacing.xlarge, children, ...rest }: Props): JSX.Element => {
  return (
    <PullUpStyled distance={distance} {...rest}>
      {children}
    </PullUpStyled>
  )
}

export default PullUp
