import React from 'react'

import PullUp from 'marketing/components/PullUp/PullUp'
import Boxes from 'marketing/components/Box/Boxes'
import Box from 'marketing/components/Box/Box'

const TopBoxes = (): JSX.Element => {
  return (
    <PullUp distance={60}>
      <Boxes columns={4}>
        <Box title="Unlock Revenue" text="No-CAPEX Options" />
        <Box title="Seamless Process" text="Dedicated Support" />
        <Box title="National Coverage" text="Proven Track Record" />
        <Box title="ESG Focus" text="Act Responsibly" />
      </Boxes>
    </PullUp>
  )
}

export default TopBoxes
