import React from 'react'
import styled from 'styled-components'

import Card from 'marketing/components/Card/Card'
import principles from 'marketing/content/About/OperatingPrinciples/principles'
import Highlight from 'marketing/components/Highlight/Highlight'
import Section from 'marketing/components/Section/Section'
import Carousel from 'marketing/components/Carousel/Carousel'

const buildTitle = (title, subtitle): JSX.Element => {
  return subtitle ? (
    <>
      {title} — <em>{subtitle}</em>
    </>
  ) : (
    title
  )
}

const CarouselContainer = styled.div((props) => ({
  color: props.theme.colors.darkGray,
  width: 600,

  '& .react-multi-carousel-item--active': {
    color: props.theme.colors.offBlack,

    [`& ${CardStyled}`]: {
      borderColor: props.theme.currentColor,
    },
  },

  '@media (min-width: 768px)': {
    width: 1060,
  },
}))

const CardStyled = styled
  .div({
    textAlign: 'left',
    transition: 'border-color .75s',
  })
  .withComponent(Card)

const OperatingPrinciplesSection = (): JSX.Element => {
  return (
    <Section
      h2="Wunder’s Operating Principles"
      text={
        <>
          Wunder’s mission is to <Highlight>accelerate our clean, plentiful energy future.</Highlight> Our operating
          principles help us daily to both build an organization that we are all proud to be a part of and succeed in
          our ambitious mission.
        </>
      }
      divider
    >
      <CarouselContainer>
        <Carousel>
          {principles.map((cardInfo) => (
            <CardStyled
              key={cardInfo.title}
              title={buildTitle(cardInfo.title, cardInfo.subtitle)}
              text={cardInfo.text}
            />
          ))}
        </Carousel>
      </CarouselContainer>
    </Section>
  )
}

export default OperatingPrinciplesSection
