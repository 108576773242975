import React from 'react'

import { IndividualIconProps } from '../IconIndividualProps'

const Blog = (props: IndividualIconProps): JSX.Element => {
  return (
    <svg viewBox="0 0 25 27" width={props.size} height={props.size}>
      <g fill="none" fillRule="evenodd">
        <g transform="translate(1 1.35)" stroke={props.color}>
          <rect x="-.5" y="-.5" width="24" height="25" rx="1" />
          <path
            d="M-.5 4.5h24V23a1.5 1.5 0 01-1.5 1.5H1A1.5 1.5 0 01-.5 23V4.5z"
            fillOpacity={props.fillOpacity}
            fill={props.color}
          />
          <path strokeLinecap="round" d="M13.5 4.5h4M4.5 19.5h14M4.5 9.5h14M4.5 14.5h14" />
        </g>
      </g>
    </svg>
  )
}

export default Blog
