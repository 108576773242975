import { schema } from 'normalizr'

import { departmentSchema } from 'marketing/redux/careers/departments/interfaces'

export interface JobInterface {
  id: number
  content: string
  departments: number[]
  location: {
    name: string
  }
  title: string
}

// Normalizr schemas
export const jobSchema = new schema.Entity('jobs', {
  departments: [departmentSchema],
})
export const jobListSchema = new schema.Entity('jobList', {
  jobs: [jobSchema],
})
