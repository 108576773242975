import React from 'react'
import styled from 'styled-components'

import HeaderItem from './HeaderItem'
import CompanySubnav from './Subnavs/CompanySubnav'
import MobileHeader from './MobileHeader'

import Anchor from 'marketing/components/Anchor/Anchor'
import Container from 'marketing/components/Container/Container'
import Logo from 'marketing/components/Logo/Logo'
import { signupPath, loginPath, homePath } from 'marketing/helpers/routes'

interface Props {
  /**
   * A static header is a part of the content flow, and pushes the next element down.
   * A non-static header is positioned absolute, so it does not push the next element down.
   */
  staticPos?: boolean
}

const HeaderStyled = styled.header<{ staticPos: boolean }>((props) => ({
  ...(props.staticPos
    ? {}
    : {
        left: 0,
        position: 'absolute',
        right: 0,
        top: 0,
      }),
}))

const DesktopContainer = styled.div((props) => ({
  display: 'none',

  '@media (min-width: 768px)': {
    display: 'flex',
    justifyContent: 'space-between',
  },

  '@media (min-width: 1200px)': {
    margin: `0 -${props.theme.spacing.large}px`,
  },
}))

const HeaderSection = styled.div({
  display: 'flex',

  ':first-of-type': {
    paddingLeft: 0,
  },

  ':last-child': {
    paddingRight: 0,
  },
})

const LogoText = styled.div({
  marginLeft: 10,
  marginTop: -4,
})

const Separator = styled.span((props) => ({
  color: props.theme.currentColor,
  fontWeight: 400,
  padding: `0 ${props.theme.spacing.small}px`,
}))

/**
 * Colors change based on the grandparent's `ThemeProvider`, though it doesn't need to be inside a `ThemeProvider`.
 */
const Header = ({ staticPos = true }: Props): JSX.Element => {
  return (
    <HeaderStyled staticPos={staticPos} data-section="marketing-header">
      <Container>
        {/* Desktop Header */}
        <DesktopContainer>
          <HeaderSection>
            <HeaderItem to={homePath()}>
              <Logo />
              <LogoText>Wunder</LogoText>
            </HeaderItem>
          </HeaderSection>

          <HeaderSection>
            <HeaderItem to={homePath()}>Go Solar</HeaderItem>
            <HeaderItem subnav={CompanySubnav}>Company</HeaderItem>
          </HeaderSection>

          <HeaderSection>
            <HeaderItem>
              <Anchor to={signupPath()} underline>
                Sign Up
              </Anchor>
              <Separator>|</Separator>
              <Anchor to={loginPath()} underline>
                Log In
              </Anchor>
            </HeaderItem>
          </HeaderSection>
        </DesktopContainer>

        {/* Mobile Header */}
        <MobileHeader />
      </Container>
    </HeaderStyled>
  )
}

export default Header
