import React from 'react'

import Section from 'marketing/components/Section/Section'
import Boxes from 'marketing/components/Box/Boxes'
import Box from 'marketing/components/Box/Box'
import Icon from 'marketing/components/Icon/Icon'
import theme from 'marketing/components/Global/theme'

interface Props {
  companyName: string
}

const MakeAnImpact = (props: Props): JSX.Element => {
  return (
    <Section
      h2="A Measurable ESG Impact"
      text="Many organizations and investors are recognizing that climate change will result in catastrophic human, economic, and environmental fallout. By deploying solar across your properties you can help fight climate change and show that you’re committed to being a responsible business leader."
      divider
    >
      <Boxes columns={3} open>
        <Box
          icon={<Icon name="sun" fill={false} color={theme.colors.offBlack} strokeWidth={2} />}
          title="250"
          text={`The average solar system size (in kilowatts) that businesses need when going solar with ${props.companyName}`}
        />
        <Box
          icon={<Icon name="cart" fill={false} color={theme.colors.offBlack} strokeWidth={2} size={25} />}
          title="292,000+"
          text="Pounds of burning coal a 250kW solar system can displace, offsetting the resulting carbon pollution"
        />
        <Box
          icon={<Icon name="weight" fill={false} color={theme.colors.offBlack} strokeWidth={2} size={25} />}
          title="584,000+"
          text="Pounds of carbon dioxide a 250kW solar system will displace this year (and save the atmosphere from)"
        />
      </Boxes>
    </Section>
  )
}

export default MakeAnImpact
