/* eslint-disable max-depth */
/* eslint-disable fp/no-loops */
/* eslint-disable fp/no-mutation */
/* eslint-disable fp/no-let */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { AnyObject } from 'shared/lib/interfaces'

// Modified version of https://stackoverflow.com/a/26215431
// Iterates recursively over an object of any shape and performs a transform function on each value
export default function iterateRecursively<T>(o: T, transform: (v: string) => string): AnyObject {
  let newO, origKey, newKey, value
  if (o instanceof Array) {
    return o.map(function (value): Record<string, unknown>[] {
      if (typeof value === 'object') {
        value = iterateRecursively(value, transform)
      }
      return value
    })
  } else {
    newO = {}
    for (origKey in o) {
      if (o.hasOwnProperty(origKey)) {
        newKey = transform(origKey)
        value = o[origKey]
        if (value instanceof Array || (value !== null && value.constructor === Object)) {
          value = iterateRecursively(value, transform)
        }
        newO[newKey] = value
      }
    }
  }
  return newO
}
