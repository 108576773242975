import React from 'react'
import styled from 'styled-components'
import { rgba } from 'polished'

import Container from 'marketing/components/Container/Container'

interface Props {
  /** An element that goes above the Section title */
  topContent?: React.ReactNode
  h1?: React.ReactNode
  h2?: React.ReactNode
  text?: React.ReactNode
  divider?: boolean
  dark?: boolean
  /** @default 760 */
  maxWidth?: number
  children?: React.ReactNode
}

export const SectionStyled = styled.section<Props>((props) => ({
  borderBottom: props.divider ? `1px solid ${rgba(props.theme.currentColor, 0.2)}` : 'none',
  padding: `${props.theme.spacing.xlarge}px ${props.theme.spacing.medium}px`,

  // Dark theme
  ...(props.dark
    ? {
        backgroundColor: props.theme.colors.offBlack,
        borderBottom: props.divider ? `1px solid ${rgba(props.theme.currentColor, 0.2)}` : 'none',
        color: 'white',
      }
    : {}),

  '@media (min-width: 768px)': {
    padding: `${props.theme.spacing.xlarge * 2}px ${props.theme.spacing.medium}px`,
  },
}))

const TopContent = styled.div((props) => ({
  marginBottom: props.theme.spacing.large,
}))

const Text = styled.div({
  fontSize: '1.375rem',
  letterSpacing: '1px',
  lineHeight: '36px',
  margin: '10px 0 25px',

  '@media (min-width: 768px)': {
    padding: '0 20px',
  },
})

const ChildrenWrapper = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
})

/**
 * The `hero` of a page, the first `<Section>`, uses the `h1` prop.
 * Following instances on a page will use the `h2` prop.
 */
const Section = (props: Props): JSX.Element => {
  return (
    <SectionStyled className="section" {...props}>
      <Container maxWidth={props.maxWidth || 880} center>
        {props.topContent && <TopContent>{props.topContent}</TopContent>}
        {props.h1 && <h1>{props.h1}</h1>}
        {props.h2 && <h2>{props.h2}</h2>}
        <Text>{props.text}</Text>
      </Container>
      {props.children && <ChildrenWrapper>{props.children}</ChildrenWrapper>}
    </SectionStyled>
  )
}

export default Section
