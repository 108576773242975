import React from 'react'

import { IndividualIconProps } from '../IconIndividualProps'

const Gas = (props: IndividualIconProps): JSX.Element => {
  return (
    <svg viewBox="0 0 34 32" width={props.size} height={props.size}>
      <g transform="translate(1 1)" stroke={props.color} strokeWidth={props.strokeWidth} fill="none" fillRule="evenodd">
        <path
          d="M10.819 24h10.029m8.458-1.694l2.348-15.153A1 1 0 0030.666 6H1a1 1 0 00-.988 1.153L2.36 22.306"
          strokeLinecap="round"
        />
        <circle cx="6.333" cy="25.5" r="4.5" />
        <path d="M15.833 0c5.574 0 10.338 2.488 12.25 6h-24.5c1.912-3.512 6.676-6 12.25-6z" />
        <circle fill="#FFF" cx="25.333" cy="25.5" r="4.5" />
      </g>
    </svg>
  )
}

export default Gas
