import React from 'react'
import styled from 'styled-components'

import GreenHills from './assets/green-hills.jpg'

import Boxes from 'marketing/components/Box/Boxes'
import Box from 'marketing/components/Box/Box'
import Section from 'marketing/components/Section/Section'
import Icon from 'marketing/components/Icon/Icon'
import theme from 'marketing/components/Global/theme'
import { calculateMarketingStats } from 'marketing/content/About/impactStats'

const ImpactBoxesStyled = styled.div({
  marginTop: 15,
  width: '100%',
})
const imageWidth = 1434

// Fade in the top and sides so it's not as abrupt
const SectionStyled = styled
  .div({
    background: `linear-gradient(to top, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 25%),
                 bottom no-repeat url(${GreenHills})`,

    [`@media (min-width: ${imageWidth}px)`]: {
      background: `linear-gradient(to top, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 55%),
                 linear-gradient(to left, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 25%),
                 linear-gradient(to right, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 25%),
                 bottom no-repeat url(${GreenHills})`,
      margin: '0 auto',
      maxWidth: imageWidth,
    },
  })
  .withComponent(Section)

const SustainabilityImpact = (): JSX.Element => {
  const impactStats = calculateMarketingStats()

  return (
    <SectionStyled
      h2="Our Impact Matters"
      text="At Wunder, we help build hundreds of large-scale solar systems every year, and each system is expected to operate for 25+ years, producing clean energy for communities across the United States. So what’s our impact? Well, in just one year, our solar portfolio will offset:"
    >
      <ImpactBoxesStyled>
        <Boxes columns={3} open>
          <Box
            icon={<Icon name="sun" fill={false} color={theme.colors.offBlack} strokeWidth={2} />}
            title={impactStats.totalKwh}
            text="As a reminder, this is how many kilowatts of solar Wunder has developed to date"
          />
          <Box
            icon={<Icon name="car" fill={false} color={theme.colors.offBlack} strokeWidth={2} size={25} />}
            title={impactStats.vehiclesDisplaced}
            text="How many vehicles on the road we displace each year, offsetting their carbon pollution"
          />
          <Box
            icon={<Icon name="weight" fill={false} color={theme.colors.offBlack} strokeWidth={2} size={25} />}
            title={impactStats.lbsDisplaced}
            text="How many pounds of carbon dioxide we will displace this year (and save the atmosphere from)"
          />
          <Box
            icon={<Icon name="gas" fill={false} color={theme.colors.offBlack} strokeWidth={2} size={25} />}
            title={impactStats.gallonsDisplaced}
            text="How many gallons of gasoline we displace each year, offsetting their carbon pollution"
          />
          <Box
            icon={<Icon name="cart" fill={false} color={theme.colors.offBlack} strokeWidth={2} size={25} />}
            title={impactStats.coalDisplaced}
            text="How many pounds of coal burned we displace each year, offsetting their carbon pollution"
          />
          <Box
            icon={<Icon name="house" fill={false} color={theme.colors.offBlack} strokeWidth={2} size={25} />}
            title={impactStats.homesDisplaced}
            text="How many homes’ electricity we displace each year, offsetting their carbon pollution"
          />
        </Boxes>
      </ImpactBoxesStyled>
    </SectionStyled>
  )
}

export default SustainabilityImpact
