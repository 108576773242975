import { merge } from 'lodash'
import { AnyAction } from 'redux'

import { GET_USER, UPDATE_USER, GET_OTP_DEVICE } from './effects'

import { getSuccessAction } from 'shared/redux/actionHelpers'

export function userReducer(state = {}, action: AnyAction): Record<string, unknown> {
  switch (action.type) {
    case getSuccessAction(GET_USER):
      return merge({}, state, action.payload)
    case getSuccessAction(UPDATE_USER):
      return merge({}, state, action.payload)
    case getSuccessAction(GET_OTP_DEVICE):
      return merge({}, state, { otpProvisioningQrCodeUrl: action.payload.otpProvisioningQrCodeUrl })
    default:
      return state
  }
}
