/* eslint-disable fp/no-mutation */
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { ThemeProvider } from 'styled-components'
import { RouteComponentProps } from '@reach/router'
import _ from 'lodash'
import queryString from 'query-string'
import { useCookies } from 'react-cookie'

import theme from 'marketing/components/Global/theme'
import Header from 'marketing/components/Header/Header'
import Swirl from 'marketing/components/Swirl/Swirl'
import Section from 'marketing/components/Section/Section'
import Footer from 'marketing/components/Footer/Footer'
import { RootState } from 'marketing/redux/store'
import { JobInterface } from 'marketing/redux/careers/jobs/interfaces'
import { getJobs } from 'marketing/redux/careers/jobs/effects'
import { DepartmentInterface } from 'marketing/redux/careers/departments/interfaces'
import { getDepartments } from 'marketing/redux/careers/departments/effects'
import { selectDepartmentById } from 'marketing/redux/careers/departments/selectors'
import { selectJobById } from 'marketing/redux/careers/jobs/selectors'
import { selectIsAnyLoading } from 'shared/redux/loading'
import Button from 'marketing/components/Button/Button'
import { openRolesPath, jobPath } from 'marketing/helpers/routes'
import Container from 'marketing/components/Container/Container'
import PullUp from 'marketing/components/PullUp/PullUp'
import Content from 'marketing/components/Content/Content'
import useDocTitle from 'shared/lib/useDocTitle'
import { AppDispatch } from 'shared/redux/types'

interface Props {
  jobId?: string
  job?: JobInterface
  department?: DepartmentInterface
  isLoading?: boolean
  dispatch?: AppDispatch
}

declare global {
  interface Window {
    Grnhse: { Iframe: { load: (jobId: string, shareCode: string) => void } }
  }
}

const JobApplication = (props: Props & RouteComponentProps): JSX.Element => {
  useDocTitle('Application - Wunder')
  const [cookies, setCookie] = useCookies(['wunder-careers'])

  useEffect(() => {
    if (typeof document !== 'undefined') {
      const script = document.createElement('script')

      script.src = 'https://boards.greenhouse.io/embed/job_board/js?for=wundercapital'
      script.async = true
      script.onload = (): void => {
        // Set a timeout to prevent a race condition where Greenhouse isn't fully loaded
        setTimeout((): void => {
          loadGreenhouseApplication()
        }, 100)
      }
      document.body.appendChild(script)

      return (): void => {
        document.body.removeChild(script)
      }
    }
  }, [])

  useEffect((): void => {
    props.dispatch(getJobs())
    props.dispatch(getDepartments())
  }, [])

  useEffect((): void => {
    loadGreenhouseApplication()
  }, [props.jobId, props.location.pathname])

  useEffect((): void => {
    // Set the share code in a cookie, so if the person navigates away from this page but comes back, whoever shared
    // the code still gets credit.
    setCookie('ghSrc', queryString.parse(props.location.search)['gh_src'])
  }, [props.location.search])

  const loadGreenhouseApplication = (): void => {
    if (typeof window !== 'undefined') {
      // Pass along the `gh_src` code to track who shared the link
      const shareCode = queryString.parse(props.location.search)['gh_src'] || cookies['ghSrc']

      // Load the application form
      if (window.Grnhse) window.Grnhse.Iframe.load(props.jobId, shareCode)
    }
  }

  return (
    <ThemeProvider theme={{ ...theme, currentColor: theme.colors.blue }}>
      <Header />

      {props.job && props.department && (
        <>
          <Section
            h1={props.job.title}
            text={`${props.job.location.name} | ${props.department.name}`}
            maxWidth={1100}
            data-section="application-hero"
          >
            <Button href={`${jobPath(props.job.id)}#grnhse_app`}>Apply Now</Button>
            <Button to={openRolesPath()}>View All Openings</Button>
          </Section>

          <Swirl>
            <PullUp>
              <Container>
                <Content data-section="application-description">
                  <div dangerouslySetInnerHTML={{ __html: _.unescape(props.job.content) }} />
                </Content>
              </Container>
            </PullUp>
          </Swirl>
        </>
      )}

      <Container>
        <div id="grnhse_app"></div>
      </Container>

      <Footer />
    </ThemeProvider>
  )
}

function mapState(state: RootState, ownProps: Props): Props {
  const job = selectJobById(state, +ownProps.jobId)

  return {
    job: job,
    department: selectDepartmentById(state, job?.departments[0]),
    isLoading: selectIsAnyLoading(state),
  }
}

export default connect(mapState)(JobApplication)
