import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import Section from 'marketing/components/Section/Section'
import Container from 'marketing/components/Container/Container'
import TableList from 'marketing/components/TableList/TableList'
import TableListItem from 'marketing/components/TableList/TableListItem'
import { RootState } from 'marketing/redux/store'
import { PressArticleInterface } from 'marketing/redux/pressArticles/interfaces'
import { getPressArticles } from 'marketing/redux/pressArticles/effects'
import { selectAllPressArticles } from 'marketing/redux/pressArticles/selectors'
import { AppDispatch } from 'shared/redux/types'

interface Props {
  pressArticles?: PressArticleInterface[]
  dispatch?: AppDispatch
}

const PressArticles = (props: Props): JSX.Element => {
  useEffect((): void => {
    props.dispatch(getPressArticles())
  }, [])

  return (
    <Section data-section="press-articles">
      <Container>
        <TableList title="Recent Coverage">
          {props.pressArticles.map((article) => (
            <TableListItem
              key={article.id}
              href={article.href}
              title={article.publication}
              description={article.title}
              targetBlank
            />
          ))}
        </TableList>
      </Container>
    </Section>
  )
}

function mapState(state: RootState): Props {
  return {
    pressArticles: selectAllPressArticles(state),
  }
}

export default connect(mapState)(PressArticles)
