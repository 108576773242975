import React from 'react'
import styled from 'styled-components'

interface Props {
  title?: string
  description?: string
  children?: React.ReactNode
}

const TableListStyled = styled.section<Props>((props) => ({
  '& + &': {
    marginTop: props.theme.spacing.xlarge,
  },

  '@media (min-width: 768px)': {
    '& + &': {
      marginTop: props.theme.spacing.xlarge * 2,
    },
  },
}))

const ItemsWrapper = styled.div((props) => ({
  borderTop: `1px solid ${props.theme.colors.offBlack}`,
  marginTop: props.theme.spacing.medium,
}))

const TableList = ({ title, description, children, ...rest }: Props): JSX.Element => {
  return (
    <TableListStyled {...rest}>
      {title && <h2>{title}</h2>}
      {description && <p>{description}</p>}
      <ItemsWrapper>{children}</ItemsWrapper>
    </TableListStyled>
  )
}

export default TableList
