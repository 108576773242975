import React from 'react'
import styled from 'styled-components'

import Icon from 'marketing/components/Icon/Icon'
import Anchor from 'marketing/components/Anchor/Anchor'

interface Props {
  href?: string
  to?: string
  onClick?: (event: React.MouseEvent<HTMLElement>) => void
  subnav?: () => JSX.Element
  children: React.ReactNode
}

const HeaderItemStyle = styled.div((props) => ({
  alignItems: 'center',
  display: 'flex',
  fontSize: '1.125rem',
  height: 17 + props.theme.spacing.large * 2, // The height of the logo, plus top and bottom
  padding: `0 ${props.theme.spacing.small}px`,
  position: 'relative',

  'div&': {
    cursor: 'default',
  },

  '&:hover': {
    textDecoration: 'none',
  },

  '@media (min-width: 768px) and (max-width: 1024px)': {
    padding: `0 ${props.theme.spacing.medium}px`,
  },

  '@media (min-width: 1024px)': {
    padding: `0 ${props.theme.spacing.large}px`,
  },
}))
const StyledAnchor = HeaderItemStyle.withComponent(Anchor)

const ChevronWrapper = styled.div({
  margin: '-2px 0 0 6px',
})

const HeaderItem = (props: Props): JSX.Element => {
  return props.href || props.to || props.onClick ? (
    <StyledAnchor href={props.href} to={props.to} onClick={props.onClick}>
      {props.children}
    </StyledAnchor>
  ) : (
    <HeaderItemStyle className="header-item">
      {props.children}
      {props.subnav && (
        <>
          <ChevronWrapper>
            <Icon name="chevron-down" size={10} />
          </ChevronWrapper>
          <props.subnav />
        </>
      )}
    </HeaderItemStyle>
  )
}

export default HeaderItem
