import React from 'react'
import { RouteComponentProps } from '@reach/router'

import Modal from 'marketing/components/Modal/Modal'
import ModalHeader from 'marketing/components/Modal/ModalHeader'
import SuccessCheckmark from 'shared/components/SuccessCheckmark/SuccessCheckmark'

const RequestInfoSuccess: React.FC<RouteComponentProps> = (): JSX.Element => {
  return (
    <Modal>
      <ModalHeader title="Thanks for your interest" subtitle="We'll be in touch soon!">
        <SuccessCheckmark />
      </ModalHeader>
    </Modal>
  )
}

export default RequestInfoSuccess
