import React from 'react'
import styled from 'styled-components'

interface Props {
  title?: React.ReactNode
  subtitle?: React.ReactNode
  children?: React.ReactNode
}

const Header = styled.div((props) => ({
  backgroundColor: 'white',
  padding: props.theme.spacing.medium,
}))

const Title = styled.h3({
  textAlign: 'center',
})

const Subtitle = styled.p({
  textAlign: 'center',
})

const ModalHeader = ({ title, subtitle, children, ...rest }: Props): JSX.Element => {
  return (
    <Header {...rest}>
      {title && <Title>{title}</Title>}
      {subtitle && <Subtitle>{subtitle}</Subtitle>}
      {children}
    </Header>
  )
}

export default ModalHeader
