import React from 'react'
import styled from 'styled-components'
import { rgba } from 'polished'

import { DropdownButtonStyled } from '../DropdownButton/DropdownButton.style'

import Anchor from 'marketing/components/Anchor/Anchor'
import { ContainerStyled } from 'marketing/components/Container/Container'

interface Props {
  to?: string
  href?: string
  onClick?: () => void
  block?: boolean
  type?: 'button' | 'submit'
  primary?: boolean
  disabled?: boolean
  className?: string
  children: React.ReactNode
}

const ButtonStyled = styled.button<{ block?: boolean; primary?: boolean }>((props) => ({
  alignItems: 'center',
  backgroundColor: props.primary ? props.theme.colors.blue : props.theme.colors.offBlack,
  border: 0,
  borderRadius: props.theme.borderRadius / 2,
  color: 'white',
  display: 'inline-flex',
  fontSize: '1.125rem',
  fontWeight: 400,
  height: 44,
  justifyContent: 'center',
  minWidth: 220,
  outline: 0,
  padding: `0 ${props.theme.spacing.medium}px`,
  verticalAlign: 'top',
  width: props.block ? '100%' : null,

  ':hover': {
    color: 'white',
    cursor: 'pointer',
    textDecoration: 'none',
  },

  ':disabled': {
    backgroundColor: rgba(props.theme.colors.offBlack, 0.5),
    cursor: 'default',
  },

  [`& + &, ${DropdownButtonStyled} + &, ${ContainerStyled} + &`]: {
    marginTop: props.theme.spacing.medium,
  },

  '.form-wrapper &': {
    marginTop: props.theme.spacing.small,
  },

  '@media (min-width: 768px)': {
    [`& + &, ${DropdownButtonStyled} + &`]: {
      marginTop: props.block ? props.theme.spacing.small : 0,
      marginLeft: props.block ? 0 : props.theme.spacing.small,
    },
  },
}))

// Filter attributes out that we don't want to put in the dom
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const FilteredButton = ({ block, primary, ...rest }): JSX.Element => <Anchor {...rest} />
const AnchorStyled = ButtonStyled.withComponent(FilteredButton)

/**
 * Buttons can be `<button>` or `<anchor>` tags, but look identical.
 */
const Button = ({ to, href, onClick, type, disabled, children, ...commonProps }: Props): JSX.Element => {
  return href || to ? (
    <AnchorStyled to={to} href={href} {...commonProps}>
      {children}
    </AnchorStyled>
  ) : (
    <ButtonStyled onClick={onClick} type={type} disabled={disabled} {...commonProps}>
      {children}
    </ButtonStyled>
  )
}

export default Button
