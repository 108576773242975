import React from 'react'
import { RouteComponentProps } from '@reach/router'
import { ThemeProvider } from 'styled-components'

import MultiFactorAuthenticationForm from './MultiFactorAuthenticationForm'

import theme from 'marketing/components/Global/theme'
import GrayPage from 'marketing/components/GrayPage/GrayPage'
import HeaderMinimal from 'marketing/components/Header/HeaderMinimal'
import useDocTitle from 'shared/lib/useDocTitle'

const MultiFactorAuthentication = (props: RouteComponentProps): JSX.Element => {
  useDocTitle('Two Factor Authentication - Wunder')

  return (
    <ThemeProvider theme={{ ...theme, currentColor: theme.colors.blue }}>
      <GrayPage>
        <HeaderMinimal />
        <MultiFactorAuthenticationForm user={props.location?.state?.['user']} />
      </GrayPage>
    </ThemeProvider>
  )
}

export default MultiFactorAuthentication
