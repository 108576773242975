import React from 'react'
import styled from 'styled-components'

interface Props {
  children: React.ReactNode
}

const StyledSubnav = styled.div((props) => ({
  '@media (min-width: 768px)': {
    backgroundColor: 'white',
    border: `1px solid ${props.theme.colors.middleGray}`,
    borderRadius: props.theme.borderRadius,
    boxShadow: props.theme.boxShadow,
    left: '50%',
    opacity: 0,
    padding: 50,
    position: 'absolute',
    top: 17 + props.theme.spacing.large * 2, // The height of the logo, plus top and bottom
    transform: 'translateX(-50%)',
    transition: 'all 0.2s',
    visibility: 'hidden',
    zIndex: props.theme.zIndex.subnav,

    // Triangle on top
    '::before, ::after': {
      border: 'solid transparent',
      bottom: '100%',
      content: "''",
      height: 0,
      left: '50%',
      pointerEvents: 'none',
      position: 'absolute',
      width: 0,
    },
    '&::before': {
      borderBottomColor: props.theme.colors.middleGray,
      borderWidth: 10,
      marginLeft: -10,
    },
    '&::after': {
      borderBottomColor: 'white',
      borderWidth: 9,
      marginLeft: -9,
    },

    '.header-item:hover &': {
      opacity: 1,
      visibility: 'visible',
    },
  },
}))

const Subnav = (props: Props): JSX.Element => {
  return <StyledSubnav>{props.children}</StyledSubnav>
}

export default Subnav
