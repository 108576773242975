import React from 'react'
import { RouteComponentProps } from '@reach/router'

import Section from 'marketing/components/Section/Section'

const ResetPasswordSuccess: React.FC<RouteComponentProps> = (): JSX.Element => {
  return (
    <Section
      h1="Reset Password"
      text="Thanks, we just sent you an email with instructions to reset your password."
      data-section="reset-password-success"
    />
  )
}

export default ResetPasswordSuccess
