import React from 'react'

import { TooltipStyled, TooltipIcon, TooltipContent } from './Tooltip.style'

export type Icon = 'info' | 'question'
interface Props {
  icon?: Icon
  children: React.ReactNode
}

const Tooltip = ({ icon, children }: Props): JSX.Element => {
  return (
    <TooltipStyled>
      <TooltipIcon icon={icon || 'question'} />
      <TooltipContent>{children}</TooltipContent>
    </TooltipStyled>
  )
}

export default Tooltip
