import React from 'react'

import './LoadingIcon.scss'

interface Props {
  centered?: boolean
}

const LoadingIcon: React.FC<Props> = (props): JSX.Element => {
  const icon = <span className="loading-icon" data-item="loading-icon" />

  if (props.centered) return <div className="center-block">{icon}</div>

  return icon
}

export default LoadingIcon
