import React, { useContext } from 'react'
import styled, { ThemeContext } from 'styled-components'

import ArrowRight from './Icons/ArrowRight'
import Article from './Icons/Article'
import At from './Icons/At'
import Blog from './Icons/Blog'
import Briefcase from './Icons/Briefcase'
import Candle from './Icons/Candle'
import Car from './Icons/Car'
import Cart from './Icons/Cart'
import ChevronDown from './Icons/ChevronDown'
import Dollar from './Icons/Dollar'
import Gas from './Icons/Gas'
import Hamburger from './Icons/Hamburger'
import House from './Icons/House'
import LifeJacket from './Icons/LifeJacket'
import Lock from './Icons/Lock'
import Planet from './Icons/Planet'
import Person from './Icons/Person'
import Phone from './Icons/Phone'
import Shield from './Icons/Shield'
import Sun from './Icons/Sun'
import TwoOvals from './Icons/TwoOvals'
import TwoSquares from './Icons/TwoSquares'
import TwoTriangles from './Icons/TwoTriangles'
import Weight from './Icons/Weight'

const icons = {
  'arrow-right': ArrowRight,
  article: Article,
  at: At,
  blog: Blog,
  briefcase: Briefcase,
  candle: Candle,
  car: Car,
  cart: Cart,
  'chevron-down': ChevronDown,
  dollar: Dollar,
  gas: Gas,
  hamburger: Hamburger,
  house: House,
  'life-jacket': LifeJacket,
  lock: Lock,
  person: Person,
  planet: Planet,
  phone: Phone,
  shield: Shield,
  sun: Sun,
  'two-triangles': TwoTriangles,
  'two-squares': TwoSquares,
  'two-ovals': TwoOvals,
  weight: Weight,
}
export type Icons = keyof typeof icons

export interface Props {
  name?: Icons
  size?: number
  /** @default `theme.currentColor` */
  color?: string
  /** If false, icon will be a single color with no fill */
  fill?: boolean
  strokeWidth?: number
  className?: string
}
const IconWrapper = styled.span<{ size: number }>((props) => ({
  display: 'inline-flex',
  width: props.size,
}))

/**
 * Use `Icon` with a variety of props, though not all props work on every icon.
 */
export const Icon = ({ name, color, size = 32, fill = true, strokeWidth = 1, className }: Props): JSX.Element => {
  const theme = useContext(ThemeContext)
  const SelectedIcon = icons[name]

  const fillOpacity = fill ? '.1' : '0'

  return (
    <IconWrapper size={size} className={className}>
      <SelectedIcon
        color={color || theme.currentColor}
        size={size}
        strokeWidth={strokeWidth}
        fillOpacity={fillOpacity}
      />
    </IconWrapper>
  )
}

export default Icon
