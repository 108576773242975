import { pressArticlesSchema } from './interfaces'

import { apiAction, ApiAction } from 'shared/redux/apiMiddleware'
import { apiPressArticlesPath } from 'marketing/helpers/routes'

export const GET_PRESS_ARTICLES = 'getPressArticles'

export function getPressArticles(): ApiAction {
  return apiAction({
    type: GET_PRESS_ARTICLES,
    url: apiPressArticlesPath(),
    schema: pressArticlesSchema,
  })
}
