import React from 'react'
import styled from 'styled-components'
import { Link } from '@reach/router'

interface Props {
  href?: string
  to?: string
  onClick?: (event: React.MouseEvent<HTMLElement>) => void
  isAlwaysColored?: boolean
  className?: string
  targetBlank?: boolean
  /** Note that in order to use underline, the anchor must be either 1) display: inline, or 2) if display: block, not long enough to wrap */
  underline?: boolean
  /** If true, clicked links will not scroll to the top of the page */
  noScroll?: boolean
  children?: React.ReactNode
}
const defaultProps: Props = { isAlwaysColored: false }

// This is needed to filter props that shouldn't be passed through and set on DOM elements, such as isAlwaysColored
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const FilteredLink = ({ isAlwaysColored, underline, to, ...rest }): JSX.Element => <Link to={to} {...rest} />
const AnchorStyled = styled.a<Props>((props) => ({
  color: props.isAlwaysColored ? props.theme.currentColor : 'inherit',
  cursor: 'pointer',

  ':hover': {
    color: props.theme.currentColor,
  },

  // Underlined text
  ...(props.underline
    ? {
        borderBottom: '1px solid transparent',
        display: 'inline',
        paddingTop: 1,
        ':hover': {
          color: props.theme.currentColor,
          borderBottom: `1px dashed ${props.theme.currentColor}`,
        },
      }
    : {}),
}))
const LinkStyled = AnchorStyled.withComponent(FilteredLink)

/**
 * Internal (within the SPA) links have a `to` prop, while external links have an `href` prop.
 */
const Anchor = (props = defaultProps): JSX.Element => {
  const { children, href, to, onClick, targetBlank, noScroll, ...commonProps } = props

  if (href || onClick) {
    return (
      <AnchorStyled
        href={href}
        onClick={onClick}
        target={targetBlank ? '_blank' : null}
        rel={targetBlank ? 'noopener noreferrer' : null}
        {...commonProps}
      >
        {children}
      </AnchorStyled>
    )
  } else if (to) {
    return (
      <LinkStyled to={to} {...commonProps} state={noScroll ? { noScroll: true } : null}>
        {children}
      </LinkStyled>
    )
  } else {
    return <>{children}</>
  }
}

export default Anchor
