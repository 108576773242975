import React, { useState } from 'react'
import styled from 'styled-components'

import HeaderItem from './HeaderItem'
import MobileDrawer from './MobileDrawer'

import { homePath } from 'marketing/helpers/routes'
import Logo from 'marketing/components/Logo/Logo'
import Icon from 'marketing/components/Icon/Icon'

const MobileContainer = styled.div({
  display: 'flex',
  justifyContent: 'space-between',

  '@media (min-width: 768px)': {
    display: 'none',
  },
})

const MobileHeader = (): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <MobileContainer>
        <HeaderItem to={homePath()}>
          <Logo />
        </HeaderItem>

        <div onClick={(): void => setIsOpen(!isOpen)}>
          <HeaderItem>
            <Icon name="hamburger" />
          </HeaderItem>
        </div>
      </MobileContainer>

      <MobileDrawer isOpen={isOpen} setIsOpen={setIsOpen} />
    </>
  )
}

export default MobileHeader
