import React, { useState, useRef } from 'react'

import { DropdownButtonStyled, IconStyled, Popout, AnchorStyled } from './DropdownButton.style'

import Button from 'marketing/components/Button/Button'
import useOnClickOutside from 'shared/lib/useOnClickOutside'

interface DropdownOption {
  to?: string
  text: string
}

interface Props {
  options: DropdownOption[]
  children: React.ReactNode
}

/**
 * DropdownButtons render a list of elements in a dropdown
 */
const DropdownButton = ({ options, children, ...rest }: Props): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false)
  const ref = useRef()
  useOnClickOutside(ref, (): void => setIsOpen(false))

  return (
    <DropdownButtonStyled ref={ref} {...rest}>
      <Button onClick={(): void => setIsOpen(!isOpen)}>
        {children}
        <IconStyled name="chevron-down" color="white" size={10} />
      </Button>
      {isOpen && (
        <Popout onClick={(): void => setIsOpen(!isOpen)}>
          {options.map((option) => (
            <AnchorStyled to={option.to} key={option.text} noScroll>
              {option.text}
            </AnchorStyled>
          ))}
        </Popout>
      )}
    </DropdownButtonStyled>
  )
}

export default DropdownButton
