import React from 'react'
import styled from 'styled-components'

import Section from 'marketing/components/Section/Section'
import Container from 'marketing/components/Container/Container'

const CheckmarkContainer = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  width: '100%',
})

const Text = styled.h3((props) => ({
  flexBasis: '100%',
  margin: `${props.theme.spacing.medium}px auto 0`,
  maxWidth: 230,

  '@media (min-width: 768px)': {
    flexBasis: '33%',
    maxWidth: 'unset',
    paddingLeft: 50,
  },
}))

const SeamlessExperience = (): JSX.Element => {
  return (
    <Section
      h2="Seamless, Programmatic Solar Solutions"
      text="Unlike other solar providers that lack the capabilities, Wunder exclusively focuses on serving commercial and industrial property owners. We’re set up to help our clients seamlessly deploy solar across their portfolio holdings, whether they’re an international institution or a smaller regionally-focused firm. Our team will manage the process end-to-end so that you can stay focused on what’s most important: your business."
    >
      <Container>
        <CheckmarkContainer>
          <Text>✓ Dedicated Support</Text>
          <Text>✓ Design &amp; Engineering</Text>
          <Text>✓ Permitting</Text>
          <Text>✓ Turnkey Financing</Text>
          <Text>✓ Quality Installation</Text>
          <Text>✓ Ongoing Operations</Text>
        </CheckmarkContainer>
      </Container>
    </Section>
  )
}

export default SeamlessExperience
