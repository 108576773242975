import React from 'react'

import Subnav from './Subnav'
import SubnavItem from './SubnavItem'

import {
  aboutPath,
  careersPath,
  contactPath,
  pressPath,
  privacyPath,
  termsPath,
  sustainabilityPath,
  supportPath,
} from 'marketing/helpers/routes'

const CompanySubnav = (): JSX.Element => {
  return (
    <Subnav>
      <SubnavItem icon="life-jacket" href={supportPath()} description="Support" />
      <SubnavItem icon="person" to={aboutPath()} description="About Us" />
      <SubnavItem icon="briefcase" to={careersPath()} description="Careers" />
      <SubnavItem icon="planet" to={sustainabilityPath()} description="Sustainability" />
      <SubnavItem icon="article" to={pressPath()} description="Press" />
      <SubnavItem icon="at" to={contactPath()} description="Contact" />
      <SubnavItem icon="shield" to={privacyPath()} description="Privacy" />
      <SubnavItem icon="article" to={termsPath()} description="Terms of Service" />
    </Subnav>
  )
}

export default CompanySubnav
