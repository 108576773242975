import { createSelector } from 'reselect'
import { sortBy, reverse } from 'lodash'

import { PressArticleInterface } from './interfaces'

import { RootState } from 'marketing/redux/store'

export const selectPressArticles = (state: RootState): { [key: string]: PressArticleInterface } => state.pressArticles

// Returns articles sorted by publishedAt descending
export const selectAllPressArticles = createSelector(selectPressArticles, (pressArticles): PressArticleInterface[] => {
  const result = Object.keys(pressArticles).map((key) => pressArticles[key])

  return reverse(sortBy(result, (article) => article.publishedAt))
})
