import { schema } from 'normalizr'

export interface DepartmentInterface {
  id: number
  name: string
  jobs: number[]
}

// Normalizr schemas
const jobSchema = new schema.Entity('jobs')
export const departmentSchema = new schema.Entity('departments', {
  jobs: [jobSchema],
})
export const departmentsSchema = new schema.Entity('departmentList', {
  departments: [departmentSchema],
})
