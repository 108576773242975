import React from 'react'
import styled from 'styled-components'

interface Props {
  children?: JSX.Element[]
}

const SideBySideStyled = styled.div({
  display: 'flex',
  flexWrap: 'wrap',

  '& img': {
    maxWidth: '100%',
  },

  '@media (min-width: 768px)': {
    flexWrap: 'nowrap',
  },
})

const Side = styled.div((props) => ({
  alignContent: 'space-evenly',
  display: 'flex',
  flexWrap: 'wrap',

  '& h5': {
    marginTop: props.theme.spacing.medium,
  },

  '& p:last-of-type': {
    margin: 0,
  },

  '@media (min-width: 768px)': {
    maxWidth: 'calc(50% - 30px)',

    '& h5': {
      marginTop: 0,
    },

    '& + &': {
      marginLeft: props.theme.spacing.large,
    },
  },
}))

/**
 * SideBySide components display two children.... side-by-side
 */
const SideBySide = ({ children, ...rest }: Props): JSX.Element => {
  if (children.length !== 2) return null

  return (
    <SideBySideStyled {...rest}>
      <Side>{children[0]}</Side>
      <Side>{children[1]}</Side>
    </SideBySideStyled>
  )
}

export default SideBySide
