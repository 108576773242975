import React from 'react'
import styled from 'styled-components'

interface Props {
  children?: React.ReactNode
}

const ContentStyled = styled.div((props) => ({
  background: 'white',
  border: `1px solid ${props.theme.colors.middleGray}`,
  borderRadius: props.theme.borderRadius,
  padding: props.theme.spacing.medium,

  '& hr': {
    border: 0,
    borderTop: `1px solid ${props.theme.colors.middleGray}`,
    display: 'block',
    height: 1,
    margin: `${props.theme.spacing.large}px -${props.theme.spacing.medium}px`,
    padding: 0,
  },

  '& ul': {
    paddingLeft: props.theme.spacing.medium,
  },

  '@media (min-width: 768px)': {
    padding: props.theme.spacing.xlarge,

    '& hr': {
      margin: `${props.theme.spacing.xlarge}px -${props.theme.spacing.xlarge}px`,
    },
  },
}))

/**
 * A bordered box meant for text content
 */
const Content = ({ children, ...rest }: Props): JSX.Element => {
  return <ContentStyled {...rest}>{children}</ContentStyled>
}

export default Content
