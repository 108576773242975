import React from 'react'

import Ares from './assets/ares1x.png'
import Ares2x from './assets/ares2x.png'
import Blackstone from './assets/blackstone1x.png'
import Blackstone2x from './assets/blackstone2x.png'
import BlackhornVentures from './assets/blackhorn-ventures.png'
import BlackhornVentures2x from './assets/blackhorn-ventures@2x.png'
import FenwaySummerVentures from './assets/fenway-summer-ventures.png'
import FenwaySummerVentures2x from './assets/fenway-summer-ventures@2x.png'
import FintechCollective from './assets/fintech-collective.png'
import FintechCollective2x from './assets/fintech-collective@2x.png'
import Fundamental from './assets/fundamental.png'
import Fundamental2x from './assets/fundamental@2x.png'
import KeyFrame from './assets/keyframe1x.png'
import KeyFrame2x from './assets/keyframe2x.png'
import Cyrus from './assets/cyrus.png'
import Cyrus2x from './assets/cyrus@2x.png'
import TechStars from './assets/techstars.png'
import TechStars2x from './assets/techstars@2x.png'

import Section from 'marketing/components/Section/Section'
import Boxes from 'marketing/components/Box/Boxes'
import Box from 'marketing/components/Box/Box'

const Investors = (): JSX.Element => {
  const investors = [
    { '1x': Blackstone, '2x': Blackstone2x },
    { '1x': Ares, '2x': Ares2x },
    { '1x': KeyFrame, '2x': KeyFrame2x },
    { '1x': Cyrus, '2x': Cyrus2x },
    { '1x': TechStars, '2x': TechStars2x },
    { '1x': FintechCollective, '2x': FintechCollective2x },
    { '1x': FenwaySummerVentures, '2x': FenwaySummerVentures2x },
    { '1x': BlackhornVentures, '2x': BlackhornVentures2x },
    { '1x': Fundamental, '2x': Fundamental2x },
  ]

  return (
    <Section
      h2="We’re backed by some of the best"
      text="Wunder’s investors have helped us grow from our first watt to more than 500+ megawatts of solar. We are proud to have them with us as we redefine America’s energy landscape and push renewable energy forward."
      divider
    >
      <Boxes columns={3}>
        {investors.map((investor) => (
          <Box key={investor['1x']}>
            <img src={investor['1x']} srcSet={investor['1x'] + ' 1x,' + investor['2x'] + ' 2x'} />
          </Box>
        ))}
      </Boxes>
    </Section>
  )
}

export default Investors
