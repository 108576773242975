import React from 'react'
import { RouteComponentProps } from '@reach/router'
import { ThemeProvider } from 'styled-components'

import Header from 'marketing/components/Header/Header'
import theme from 'marketing/components/Global/theme'
import Footer from 'marketing/components/Footer/Footer'
import Section from 'marketing/components/Section/Section'
import Swirl from 'marketing/components/Swirl/Swirl'
import Highlight from 'marketing/components/Highlight/Highlight'
import Container from 'marketing/components/Container/Container'
import Cards from 'marketing/components/Card/Cards'
import Card from 'marketing/components/Card/Card'
import Icon from 'marketing/components/Icon/Icon'
import { supportPath, pressPath } from 'marketing/helpers/routes'
import Anchor from 'marketing/components/Anchor/Anchor'
import useDocTitle from 'shared/lib/useDocTitle'

const Contact: React.FC<RouteComponentProps> = (): JSX.Element => {
  useDocTitle('Contact - Wunder')

  return (
    <ThemeProvider theme={{ ...theme, currentColor: theme.colors.green }}>
      <Swirl direction="to bottom">
        <Header />
        <Section
          h1="Contact Us"
          text={
            <>
              Our team is always happy to answer any questions that you have, help solve problems, and build more solar.{' '}
              <Highlight>Just let us know how we can help!</Highlight>
            </>
          }
        />
        <Container>
          <Cards columns={3}>
            <Card
              href="mailto:hello@wunderpower.com"
              icon={<Icon name="two-ovals" size={64} />}
              title="General"
              text="We’d love to talk about how we can work together"
              linkText="Email Us"
            />
            <Card
              href={supportPath()}
              icon={<Icon name="two-squares" size={64} />}
              title="Help + Support"
              text="Get quick answers to your most pressing questions and problems"
              linkText="Get Support"
            />
            <Card
              to={pressPath()}
              icon={<Icon name="two-triangles" size={64} />}
              title="Press"
              text="Get Wunder news, company info, and media resources"
              linkText="Visit Press Center"
            />
          </Cards>
        </Container>
        <Container center paddingBottom maxWidth={640}>
          <p>
            If you have an urgent issue regarding a solar project that you’ve engaged Wunder on, reach out to your
            dedicated Wunder partner or email us at{' '}
            <Anchor href="mailto:support@wunderpower.com" isAlwaysColored targetBlank underline>
              <strong>Support@wunderpower.com</strong>
            </Anchor>
          </p>
        </Container>
      </Swirl>
      <Footer />
    </ThemeProvider>
  )
}

export default Contact
