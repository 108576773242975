import React from 'react'
import styled from 'styled-components'

import BG1440 from './assets/roof-installation@1440.jpg'
import BG2880 from './assets/roof-installation@2880.jpg'
import CalendlyPopup from './CalendlyPopup'

import Section from 'marketing/components/Section/Section'

const HeroStyled = styled.div((props) => ({
  paddingBottom: props.theme.spacing.xlarge,
  marginBottom: -props.theme.spacing.xlarge,

  '@media (min-width: 768px)': {
    background: `linear-gradient(to bottom, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 25%),
                 center no-repeat url(${BG1440})`,
  },

  '@media (min-width: 1441px)': {
    background: `linear-gradient(to bottom, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 25%),
                 center no-repeat url(${BG2880})`,
  },
}))

const Text = styled.div({
  // Black text on a black background is hard to see, so put some white shadow around the text
  textShadow: '1px 0 1px white, -1px 0 1px white, 0 1px 1px white, 0 -1px 1px white',
})

const Hero = (): JSX.Element => {
  return (
    <HeroStyled>
      <Section
        h1="The #1 Solar Energy Provider for Commercial & Industrial Real Estate"
        text={
          <Text>
            We’ve made going solar a painless process for hundreds of real estate firms and businesses just like yours.
            We’ll take care of the details so you can stay focused on your core business and start enjoying the benefits
            of solar ASAP.
          </Text>
        }
      >
        <CalendlyPopup buttonText="Learn More" />
      </Section>
    </HeroStyled>
  )
}

export default Hero
