import React from 'react'
import classNames from 'classnames'

import LoadingIcon from './LoadingIcon'

interface Props {
  noMargin?: boolean
}

const LoadingBlock = (props: Props): JSX.Element => {
  const classes = classNames({
    'section-top': !props.noMargin,
    'section-bottom': !props.noMargin,
  })

  return (
    <div className={classes}>
      <LoadingIcon centered />
    </div>
  )
}

export default LoadingBlock
