import { jobListSchema } from './interfaces'

import { apiAction, ApiAction } from 'shared/redux/apiMiddleware'
import { apiJobsPath } from 'marketing/helpers/greenhouseApiRoutes'

export const GET_JOBS = 'getJobs'

export function getJobs(): ApiAction {
  return apiAction({
    type: GET_JOBS,
    url: apiJobsPath(),
    schema: jobListSchema,
    transformCase: false,
  })
}
