import React from 'react'

import { IndividualIconProps } from '../IconIndividualProps'

const TwoSquares = (props: IndividualIconProps): JSX.Element => {
  return (
    <svg viewBox="0 0 46 45" width={props.size} height={props.size}>
      <g id="Navigation" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Solutions-Nav" transform="translate(-117.000000, -458.000000)">
          <g id="Group-Copy-5" transform="translate(110.000000, 450.142136)" stroke={props.color}>
            <path
              d="M29.8659057,6.32129032 C39.4138521,6.37515849 47.1405882,14.1021768 47.1941078,23.6501251 L47.269118,37.0320535 C47.3218661,46.4423741 39.7360547,54.113707 30.3257341,54.1664551 C30.2618547,54.1668132 30.1979737,54.166812 30.1340943,54.1664516 C20.5861479,54.1125834 12.8594118,46.3855651 12.8058922,36.8376168 L12.730882,23.4556884 C12.6781339,14.0453678 20.2639453,6.37403489 29.6742659,6.32128682 C29.7381453,6.32092876 29.8020263,6.32092992 29.8659057,6.32129032 Z"
              id="Rectangle-Copy-7"
              transform="translate(30.000000, 30.243871) rotate(-45.000000) translate(-30.000000, -30.243871) "
            ></path>
            <path
              d="M30.1340943,6.32129032 C39.544413,6.26819863 47.2160229,13.8537299 47.2691145,23.2640486 C47.2694749,23.327928 47.2694761,23.391809 47.269118,23.4556884 L47.1941078,36.8376168 C47.1405882,46.3855651 39.4138521,54.1125834 29.8659057,54.1664516 C20.455587,54.2195433 12.7839771,46.6340121 12.7308855,37.2236934 C12.7305251,37.1598139 12.7305239,37.095933 12.730882,37.0320535 L12.8058922,23.6501251 C12.8594118,14.1021768 20.5861479,6.37515849 30.1340943,6.32129032 Z"
              id="Rectangle-Copy-8"
              fillOpacity={props.fillOpacity}
              fill={props.color}
              transform="translate(30.000000, 30.243871) rotate(45.000000) translate(-30.000000, -30.243871) "
            ></path>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default TwoSquares
