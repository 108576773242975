import React from 'react'
import styled from 'styled-components'

import HeaderItem from './HeaderItem'

import Logo from 'marketing/components/Logo/Logo'
import { homePath } from 'marketing/helpers/routes'

const HeaderStyled = styled.header({
  display: 'flex',
  justifyContent: 'center',
})

const HeaderMinimal = (): JSX.Element => {
  return (
    <HeaderStyled data-section="header-minimal">
      <HeaderItem to={homePath()}>
        <Logo />
      </HeaderItem>
    </HeaderStyled>
  )
}

export default HeaderMinimal
