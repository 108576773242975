import { get, isEmpty } from 'lodash'
import { useFormikContext } from 'formik'

const useGetErrors = (name: string): string | string[] => {
  const form = useFormikContext()
  const errors = get(form, `status[${name}]`) || get(form, `errors[${name}]`)
  const isTouched = get(form, `touched[${name}]`)

  // It's possible the server returns an empty object as an error. Return an empty string instead.
  if (Array.isArray(errors) && isEmpty(errors[0])) return ['']

  // base errors don't have a corresponding field that needs to be touched
  if (!errors || (!isTouched && name !== 'base')) return null

  return errors
}

export default useGetErrors
