import React from 'react'

import { IndividualIconProps } from '../IconIndividualProps'

const Phone = (props: IndividualIconProps): JSX.Element => {
  return (
    <svg viewBox="0 0 26 26" width={props.size} height={props.size}>
      <path
        d="M5.71 1.003h1.112c.382.001.561.118.686.475.215.616.422 1.235.632 1.853l.984 2.897c.094.276.19.552.28.83.08.244.024.451-.132.66-.47.631-.94 1.265-1.38 1.918-.173.259-.275.568-.4.859-.19.44-.025.845.15 1.238.334.749.796 1.418 1.283 2.072.692.929 1.478 1.774 2.336 2.544 1.174 1.053 2.453 1.947 3.956 2.473.366.128.69.027.984-.16.447-.286.882-.595 1.298-.924.349-.277.662-.598.994-.897.2-.181.426-.237.684-.127.84.357 1.683.713 2.525 1.066.855.359 1.707.721 2.567 1.067.309.124.474.317.507.659.053.545.13 1.088.209 1.63a1.17 1.17 0 01-.223.91c-.481.661-.998 1.284-1.638 1.794-.971.775-2.085 1.13-3.313 1.158-1.328.03-2.6-.24-3.841-.692-2.005-.73-3.842-1.782-5.565-3.037-.902-.657-1.71-1.43-2.476-2.243-.651-.69-1.303-1.38-1.917-2.101-.626-.737-1.258-1.478-1.796-2.279-.92-1.37-1.764-2.793-2.386-4.335-.42-1.042-.736-2.114-.816-3.245-.054-.749.055-1.476.261-2.195.334-1.162.898-2.186 1.794-2.997.323-.292.701-.526 1.068-.765.111-.072.268-.097.405-.102.389-.013.778-.004 1.168-.004z"
        stroke={props.color}
        fill="none"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default Phone
