import React, { useState } from 'react'
import styled from 'styled-components'

import Icon from 'marketing/components/Icon/Icon'
import Anchor from 'marketing/components/Anchor/Anchor'

interface Props {
  title?: string
  description?: string
  to?: string
  href?: string
  targetBlank?: boolean
}

const Title = styled.h5({
  margin: '0 0 12px 0',
})

const TableListItemStyled = styled
  .a<{ onMouseEnter: () => void; onMouseLeave: () => void }>((props) => ({
    alignItems: 'center',
    borderTop: '1px solid rgba(255, 255, 255, 0)',
    display: 'flex',
    justifyContent: 'space-between',
    padding: `${props.theme.spacing.large}px 0`,
    position: 'relative',

    ':hover': {
      background: 'white',
      borderTopColor: props.theme.currentColor,
      borderBottomColor: props.theme.currentColor,
      color: props.theme.colors.offBlack,
      textDecoration: 'none',

      '::before': {
        border: `1px solid ${props.theme.colors.middleGray}`,
        borderRadius: props.theme.borderRadius,
        bottom: -1,
        boxShadow: props.theme.boxShadow,
        content: "''",
        left: -props.theme.spacing.large,
        position: 'absolute',
        right: -props.theme.spacing.large,
        top: -1,
      },
    },

    // To overlap the border above it, which should be hidden under the hover effect
    ':first-of-type': {
      marginTop: -1,
    },

    '& + &': {
      borderTopColor: props.theme.colors.middleGray,
    },
  }))
  .withComponent(Anchor)

const RightArrow = styled.div((props) => ({
  border: `1px solid ${props.theme.colors.middleGray}`,
  borderRadius: 25,
  display: 'flex',
  flexShrink: 0,
  height: 50,
  justifyContent: 'center',
  width: 50,

  [`${TableListItemStyled}:hover &`]: {
    background: props.theme.currentColor,
    border: `1px solid ${props.theme.currentColor}`,
  },
}))

const TableListItem = (props: Props): JSX.Element => {
  const [isHovering, setIsHovering] = useState(false)

  return (
    <TableListItemStyled
      to={props.to}
      href={props.href}
      onMouseEnter={(): void => setIsHovering(true)}
      onMouseLeave={(): void => setIsHovering(false)}
      data-action="table-list-item"
      targetBlank={props.targetBlank}
    >
      <div>
        {props.title && <Title>{props.title}</Title>}
        {props.description && <h3>{props.description}</h3>}
      </div>
      <RightArrow>
        <Icon name="arrow-right" size={16} strokeWidth={2} color={isHovering ? 'white' : null} />
      </RightArrow>
    </TableListItemStyled>
  )
}

export default TableListItem
