export default {
  html: {
    'box-sizing': 'border-box',
  },
  '*, *::before, *::after': {
    'box-sizing': 'inherit',
  },

  'body.modal-open': {
    height: '100vh',
    'overflow-y': 'hidden',
  },
}
