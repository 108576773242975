import React from 'react'
import { Field } from 'formik'
import { isEmpty } from 'lodash'

import { RadioStyled, LabelStyled, LabelText, TextWrapper, Description } from './Radio.style'

import Tooltip from 'shared/components/Form/Tooltip/Tooltip'

export interface RadioProps {
  name?: string
  value: string | number | boolean
  title: React.ReactNode
  description?: React.ReactNode
  tooltip?: React.ReactNode
  afterChange?: (value: unknown) => void
}

/**
 * `<Radio>` components _must_ be inside a parent `<RadioGroup>`. The group includes the `name` of the options.
 */
const Radio = (props: RadioProps): JSX.Element => {
  return (
    <Field name={props.name}>
      {({ form }): JSX.Element => (
        <RadioStyled className="radio">
          <LabelStyled className={isEmpty(form?.status?.[props.name]) ? '' : 'radio--error'} data-radio={props.name}>
            <input
              id={`${props.name}-${props.value}`}
              type="radio"
              name={props.name}
              checked={form.values[props.name] === props.value}
              onChange={(): void => {
                form.setFieldValue(props.name, props.value)
                if (props.afterChange) props.afterChange(props.value)
              }}
            />
            <TextWrapper>
              <LabelText>{props.title}</LabelText>
              {props.description && <Description>{props.description}</Description>}
            </TextWrapper>
          </LabelStyled>

          {props.tooltip && <Tooltip>{props.tooltip}</Tooltip>}
        </RadioStyled>
      )}
    </Field>
  )
}

export default Radio
