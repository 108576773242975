import React from 'react'
import styled from 'styled-components'

interface Props {
  children?: React.ReactNode
}

const ModalBodyStyled = styled.div((props) => ({
  backgroundColor: props.theme.colors.offWhite,
  borderBottom: `1px solid ${props.theme.colors.middleGray}`,
  borderTop: `1px solid ${props.theme.colors.middleGray}`,
  padding: props.theme.spacing.medium,
}))

const ModalBody = ({ children, ...rest }: Props): JSX.Element => {
  return <ModalBodyStyled {...rest}>{children}</ModalBodyStyled>
}

export default ModalBody
