import React from 'react'

import { IndividualIconProps } from '../IconIndividualProps'

const Gas = (props: IndividualIconProps): JSX.Element => {
  return (
    <svg viewBox="0 0 24 30" width={props.size} height={props.size}>
      <g stroke={props.color} strokeWidth={props.strokeWidth} fill="none" fillRule="evenodd">
        <path d="M9.991 1H21a2 2 0 012 2v24a2 2 0 01-2 2H3a2 2 0 01-2-2V9.994h0L9.991 1z" />
        <path strokeLinecap="round" d="M1 1l4 4M18 6v10" />
      </g>
    </svg>
  )
}

export default Gas
