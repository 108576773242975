import React from 'react'

import { IndividualIconProps } from '../IconIndividualProps'

const ArrowRight = (props: IndividualIconProps): JSX.Element => {
  return (
    <svg viewBox="0 0 17 12" width={props.size} xmlns="http://www.w3.org/2000/svg">
      <g transform="translate(-1220 -1623)" fill="none" fillRule="evenodd">
        <g stroke={props.color} strokeLinecap="round" strokeWidth={props.strokeWidth}>
          <path d="M1229 1629h-8M1236 1629l-5 5M1236 1629l-5-5" />
        </g>
      </g>
    </svg>
  )
}

export default ArrowRight
