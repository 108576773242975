import { useEffect } from 'react'

const useDocTitle = (title: string, watchVariable = null): void => {
  useEffect((): void => {
    document.title = title || 'Astra' // eslint-disable-line fp/no-mutation
  }, [watchVariable])

  return undefined
}

export default useDocTitle
