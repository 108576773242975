import React from 'react'
import { RouteComponentProps } from '@reach/router'
import { ThemeProvider } from 'styled-components'

import theme from 'marketing/components/Global/theme'
import GrayPage from 'marketing/components/GrayPage/GrayPage'
import HeaderMinimal from 'marketing/components/Header/HeaderMinimal'
import useDocTitle from 'shared/lib/useDocTitle'
import Section from 'marketing/components/Section/Section'
import Anchor from 'marketing/components/Anchor/Anchor'
import { loginPath } from 'marketing/helpers/routes'

const InvitationInvalid: React.FC<RouteComponentProps> = (): JSX.Element => {
  useDocTitle('Invalid Invitation - Wunder')

  return (
    <ThemeProvider theme={{ ...theme, currentColor: theme.colors.blue }}>
      <GrayPage>
        <HeaderMinimal />
        <Section
          h1="Expired Invitation"
          text={
            <>
              It looks like this invitation has already been accepted. Please try{' '}
              <Anchor to={loginPath()} isAlwaysColored underline>
                logging in
              </Anchor>
              .
            </>
          }
        >
          <p>
            If you think you have reached this message in error, please contact support at{' '}
            <Anchor href="mailto:hello@wunderpower.com" targetBlank isAlwaysColored underline>
              hello@wunderpower.com
            </Anchor>
            .
          </p>
        </Section>
      </GrayPage>
    </ThemeProvider>
  )
}

export default InvitationInvalid
