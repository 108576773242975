import _ from 'lodash'
import { AnyAction } from 'redux'

import { AnyRootState } from './types'

// Reducer
const loadingReducer = (state = {}, action: AnyAction): Record<string, unknown> => {
  // *_REQUEST: true
  // *_SUCCESS || *_FAILURE || *_ERROR: false
  // Any actions that don't match this naming convention are ignored
  const matches = /(.*)_(REQUEST|SUCCESS|FAILURE|ERROR)/.exec(action.type)

  if (!matches) return state

  const [, requestName, requestState] = matches
  return {
    ...state,
    [requestName]: requestState === 'REQUEST',
  }
}

// Selectors
export const selectIsLoading = (state: AnyRootState, actions: string[]): boolean => {
  return _(actions).some((action) => _.get(state, `loading.${action}`))
}

// Lenient version returns true if an action is undefined. This can be useful if an action might not have been
// dispatched yet, but you know it will immediately after.
export const selectIsLoadingLenient = (state: AnyRootState, actions: string[]): boolean => {
  return _(actions).some((action): boolean => {
    const isLoading = _.get(state, `loading.${action}`)
    return isLoading == undefined || isLoading ? true : false
  })
}

export const selectIsAnyLoading = (state: AnyRootState): boolean => {
  return Object.keys(_.get(state, 'loading')).some((action): boolean => state['loading'][action])
}

export default loadingReducer
